
import DatePicker from 'react-date-picker';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import './DateSelector.css';

/**
 * DatePicker wrapper
 * https://www.npmjs.com/package/react-date-picker
 */
const DateSelector = (props) => {
    return (
        <div  className='d-flex'>
            <DatePicker {...props} format='dd.MM.yyyy' />
        </div>
    )
}

export default DateSelector;