
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useModalEffect from '../../../hooks/useModalEffect.js';

const PositionDetail = ({ id, user, onSave }) => {

    /**
     * Page state
     */

    const modalRef = useRef();
    const { t } = useTranslation();
    const availabilities = t('common.availabilities');
    const [ tmpUser, setTmpUser ] = useState({});

    /**
     * Page lifecycle
     */

    useModalEffect(modalRef, () => { setTmpUser(user); })
        
    /**
     * Event handlers
     */

    const handleChange = (attributes) => {
        setTmpUser({ ...tmpUser, ...attributes });
    }

    const handleAvailabilityChange = (e) => {
        const availability = availabilities.find(c => c.id === e.target.value);
        setTmpUser({ ...tmpUser, availability: availability ? availability.id : null });
    }

    return (

        <div id={id} className='modal fade' ref={modalRef}>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('mycv.title')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className='modal-body'>
                        <div>

                            <div className='form-group'>
                                <label htmlFor='positionTitle'>{t('mycv.position-title')}</label>
                                <input id='positionTitle' type='text' className='form-control' value={tmpUser.positionTitle ?? ''} placeholder={t('mycv.position-title-placeholder')} onChange={(e) => handleChange({ positionTitle: e.target.value })} />
                            </div>

                            <div className='form-group pt-3'>
                                <label htmlFor='position-description'>{t('mycv.position-description')}</label>
                                <textarea id='position-description' type='text' rows='4' className='form-control' value={tmpUser.positionDescription ?? ''} placeholder={t('mycv.position-description-placeholder')} onChange={(e) => handleChange({ positionDescription: e.target.value })} />
                            </div>

                            <div className='row pt-2'>

                                <div className='col form-group'>
                                    <label htmlFor='availability'>{t('mycv.availability')}</label>
                                    <select id='availability' className='form-select' value={tmpUser.availability ?? ''} onChange={handleAvailabilityChange}>
                                        <option value={null}>{t('glossary.none')}</option>
                                        {availabilities.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                    </select>
                                </div>

                        </div>

                        </div>
                    </div>

                    <div className='modal-footer'>
                        <div className='d-flex justify-content-end w-100'>
                            <div className='btn-group'>
                                <button className='btn btn-light' data-bs-dismiss='modal' onClick={() => onSave(tmpUser)}>{t('button.save')}</button>
                                <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PositionDetail;
