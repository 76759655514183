
import { useState } from 'react';
import { useAuthContext } from './useAuthContext.js';
import InvitationService from '../api/InvitationService.js';
import LangUtil from '../utils/LangUtil.js';
import ObjectUtil from '../utils/ObjectUtil.js';

const useInvitationController = (filter) => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();

    const [invitations, setInvitations] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    /**
     * Functionalities
     */

    const reload = async () => {

        try {
            setLoaded(false);
            const invitations = await InvitationService.getInvitations(auth.user, filter);
            invitations.forEach(i => translate(i));
            setInvitations(invitations);

            const totalCount = await InvitationService.getTotalCount(auth.user);
            setTotalCount(totalCount);
        }
        finally {
            setLoaded(true);
        }
    }

    const getInvitation = async (invitationId) => {

        // Retrieve the invitation
        const invitation = await InvitationService.getInvitation(invitationId, auth.lang);

        // Get all labels in current lang
        translate(invitation);

        return invitation;
    }

    const create = async (survey, user) => {
        return await InvitationService.create(survey, user);
    }

    const createInvitables = async (survey, filter) => {
        return await InvitationService.createInvitables(survey, auth.company, filter);
    }

    const save = async (invitation) => {
        const clone = ObjectUtil.clone(invitation); 
        untranslate(clone);
        await InvitationService.save(clone);
}

    const translate = (invitation) => {
        invitation.catalog && LangUtil.translateTree(invitation.catalog, auth.lang);
    }
    
    const untranslate = (invitation) => {
        invitation.catalog && LangUtil.untranslateTree(invitation.catalog)
    }

    const retranslate = () => {

        invitations.forEach(invitation => {
            untranslate(invitation);
            translate(invitation);
        });

        setInvitations([...invitations]);
    }

    return {
        create,
        createInvitables,
        invitations,
        totalCount,
        loaded,
        reload,
        getInvitation,
        save,
        translate,
        untranslate,
        retranslate
    }
}

export default useInvitationController;