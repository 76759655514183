
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InventoryLogic from '../../logic/InventoryLogic.js';

import up_img from '../../assets/images/up.svg'; 
import down_img from '../../assets/images/down.svg'; 

import './UserListSingleSelect.css';

const UserListSingleSelect = ({ users, selectedUser, onSelectionChange }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const [ascending, setAscending] = useState(true);
    const [sortedUsers, setSortedUsers] = useState([]);

    /**
     * Page lifecycle
     */

    useEffect(() => {

        const visibles = InventoryLogic.getVisibles(users);
        InventoryLogic.sortUsers(visibles, ascending ? 'score-asc' : 'score-desc');
        
        setSortedUsers(visibles);

    }, [users, ascending])

    /**
     * Event handlers
     */

    const handleSort = () => {
        setAscending(!ascending);
    }

    const handleUserSelected = (user) => {
        const userSelected = user === selectedUser ? null : user;
        onSelectionChange && onSelectionChange(userSelected);
    }

    return (
        <div className='userlist border rounded mt-2 p-2'>

            <div className='btn btn-secondary mb-3' onClick={handleSort}>
                {t('charts.sort.title')}
                <img src={ascending ? down_img: up_img } alt='sort' />
            </div>

            <table className='table table-sm table-hover'>
                <tbody>
                    {
                        sortedUsers.map(user => (

                            <tr key={user.id} onClick={() => handleUserSelected(user)}>

                                <td className={user === selectedUser ? '' : ' text-muted'}>
                                    {user.firstname} {user.lastname}
                                </td>

                                <td className={'text-end' + (user === selectedUser ? '' : ' text-muted')}>
                                    {user.matchingScore ?? 0}
                                </td>
                                    
                            </tr>
                        ))
                    }
                </tbody>
            </table>


        </div>
    )
}

export default UserListSingleSelect;