import axios from 'axios';
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../controllers/useAuthContext.js';
import AppLogic from '../logic/AppLogic.js';

let navigate = null;

const AxiosInterceptor = ({ children }) => {

    const { auth } = useAuthContext();
    const routeNavigator = useNavigate();

    useEffect(() => {

        // First time, initialize navigator used to redirection
        if (!navigate) navigate = routeNavigator;

    }, [auth, routeNavigator])

    return children;
}

// Axios - handling each request
AppLogic.traceRequests && axios.interceptors.request.use(
    (req) => {

        if (AppLogic.traceRequests) {
            console.log(`request - url=${req.url} method=${req.method.toUpperCase()} data=${req.data ? JSON.stringify(req.data) : ''}`);
        }

        return req;
    }
)

// Axios - handling each response/error
axios.interceptors.response.use(
    (res) => {

        if (AppLogic.traceRequests) {
            console.log(`response - status=${res.status} data=${res.data ? JSON.stringify(res.data) : ''}`);
        }

        return res;
    },

    (error) => {

        if (AppLogic.traceRequests) {
            console.log(`response - status=${error.response.status} data=${error.response.data ? JSON.stringify(error.response.data) : ''}`);
        }

        // Handler error 401 (unauthenticated)
        if (navigate && error.response.status === 401) {
            navigate('/login');
        }
        // Handler error 403 (unauthorized)
        if (navigate && error.response.status === 403) {
            navigate('/unauthorized');
        }
        else {
            return Promise.reject(error)
        }
    }
)

export default AxiosInterceptor;