
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import useCompanyController from '../controllers/useCompanyController.js';
import PositionTypeService from '../api/PositionTypeService.js';
import LangUtil from "../utils/LangUtil.js";

const usePositionTypeController = (filter) => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();

    const companyController = useCompanyController();
    const [positionTypes, setPositionTypes] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    /**
     * Functionalities
     */

    const reload = async () => {

        try {
            setLoaded(false);
            const positionTypes = await PositionTypeService.getPositionTypes(auth.company, filter);
            positionTypes.forEach(p => translate(p));
            setPositionTypes(positionTypes);

            const totalCount = await PositionTypeService.getTotalCount(auth.company);
            setTotalCount(totalCount);
        }
        finally {
            setLoaded(true);
        }
    }

    const deletable = async (positionType) => {
        return positionType.id != null && await PositionTypeService.isDeletable(positionType);
    }

    const create = async () => {
        const positionType = await PositionTypeService.create(auth.company);
        translate(positionType);
        return positionType;
    }

    const save = async (positionType) => {

        try {
            untranslate(positionType);
            await PositionTypeService.save(positionType);
        }
        finally {
            translate(positionType);
        }
        reload();
    }

    const remove = async (positionType) => {
        await PositionTypeService.remove(positionType);
        reload();
    }

    const translate = (positionType) => {
        LangUtil.translate(positionType, 'name', auth.lang);
        positionType.company && companyController.translate(positionType.company);
    }

    const untranslate = (positionType) => {
        LangUtil.untranslate(positionType, 'name');
        positionType.company && companyController.untranslate(positionType.company);
    }

    const retranslate = () => {

        positionTypes.forEach(positionType => {
            untranslate(positionType);
            translate(positionType);
        });

        setPositionTypes([...positionTypes]);
    }

    return {
        positionTypes,
        totalCount,
        loaded,
        reload,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate
    }
}

export default usePositionTypeController;