
import { useTranslation } from 'react-i18next';

const TabOptions = ({ motion, onChange }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();

    /**
     * Event handlers
     */

    const handleChange = (attribute) => {
        onChange({ motion: { ...motion, ...attribute } });
    }

    return (

        <div className='container'>

                <div className='row col-5'>
                    <label>{t('motions.name')}</label>
                    <input type='text' id='name' className='form-control' defaultValue={motion.name ?? ''} placeholder={t('motions.name-placeholder')} onChange={(e) => handleChange({ name: e.target.value })} />
                </div>

                <div className='row col-9 mt-4'>
                    <label>{t('motions.description')}</label>
                    <textarea className='form-control' rows='3' value={motion.description ?? ''} placeholder={t('motions.description-placeholder')} onChange={(e) => handleChange({ description: e.target.value })} />
                </div>

        </div>

    )
}

export default TabOptions;
