
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useCountryController from '../../../controllers/useCountryController.js';

const TabAddress = ({ user, onChange }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const countryController = useCountryController();

    /**
     * Page lifecycle
     */
    useEffect(() => {

        countryController.reload();

        // eslint-disable-next-line 
    }, [])

    return (

        <div className='container'>

            <div className='row'>

                <div className='col-8'>
                    <div className='form-group'>
                        <label htmlFor='street'>{t('users.street')}</label>
                        <input id='street' type='text' className='form-control' value={user.street ?? ''} placeholder={t('users.street-placeholder')} onChange={(e) => onChange({ street: e.target.value })} />
                    </div>
                </div>

                <div className='col-4'>
                    <div className='form-group'>
                        <label htmlFor='streetNumber'>{t('users.number')}</label>
                        <input id='streetNumber' type='string' className='form-control' value={user.streetNumber ?? ''} placeholder={t('users.number-placeholder')} onChange={(e) => onChange({ streetNumber: e.target.value })} />
                    </div>
                </div>

            </div>

            <div className='row pt-2'>

                <div className='col'>
                    <div className='form-group'>
                        <label htmlFor='complement'>{t('users.complement')}</label>
                        <input id='complement' type='text' className='form-control' value={user.streetComplement ?? ''} placeholder={t('users.complement-placeholder')} onChange={(e) => onChange({ streetComplement: e.target.value })} />
                    </div>
                </div>

            </div>

            <div className='row pt-2'>

                <div className='col-8 form-group'>
                    <label htmlFor='city'>{t('users.city')}</label>
                    <input id='city' type='text' className='form-control' value={user.city ?? ''} placeholder={t('users.city-placeholder')} onChange={(e) => onChange({ city: e.target.value })} />
                </div>

                <div className='col-4 form-group'>
                    <label htmlFor='cityCode'>{t('users.citycode')}</label>
                    <input id='cityCode' type='string' className='form-control' value={user.cityCode ?? ''} placeholder={t('users.citycode-placeholder')} onChange={(e) => onChange({ cityCode: e.target.value })} />
                </div>

            </div>

            <div className='row pt-2'>

                <div className='col-8'>
                    <div className='form-group'>
                        <label htmlFor='country'>{t('glossary.country')}</label>
                        <select id='country' className='form-select' value={user.country?.id ?? ''} onChange={(e) => onChange({ country: e.target.value })}>
                            <option value={null}>{t('glossary.none')}</option>
                            {countryController.countries?.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                        </select>
                    </div>
                </div>

            </div>
        </div >

    )
}

export default TabAddress;
