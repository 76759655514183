
import { useTreeContext } from './TreeContext.js';
import { useDragContext } from './DragContext.js';
import TreeUtil from '../../utils/TreeUtil.js';

const DraggableRow = ({ node, data, className, children, onDrop, ...props }) => {

    const treeContext = useTreeContext();
    const dragContext = useDragContext();

    const draggable = treeContext.editable && !treeContext.editMode;
    const nextNode = TreeUtil.findNext(data, node.id);
    const dropBefore = draggable && dragContext.dropMode === 'before' && dragContext.dropId === nextNode?.id;
    const dropAfter = draggable && dragContext.dropMode === 'after' && dragContext.dropId === node.id;

    /**
     * Called on source item when drag operation starts
     * Note: this is the first method called during a drag operation
     */
    const handleDragStart = (e) => {

        // Clear state
        treeContext.setSelectedId();
        dragContext.setDragId(node.id);
        dragContext.setDragText(node.label);
        dragContext.setDropId();
        dragContext.setDropMode();

        // Update html dragging rendering
        e.dataTransfer.setDragImage(dragContext.dragImageRef.current, 0, 0);
    }

    /**
     * Called on target item when another item has been dropped into target
     * Note: this method is called before onDragEnd
     *       it is the last one sent during a drag operation
     */
    const handleDrop = (e) => {

        // Default behavior = forbid dropping item into another one
        e.preventDefault();

        // Notify drop event
        onDrop && onDrop(dragContext.initialDragId, dragContext.dropId, dragContext.dropMode);

        // Clear state
        treeContext.setSelectedId();
        dragContext.setDragId();
        dragContext.setDragText();
        dragContext.setDropId();
        dragContext.setDropMode();
    }

    /**
     * Called on source item when drag operation ends
     * Note: this method is called after onDrop
     */
    const handleDragEnd = (e) => {

        // Clear state
        treeContext.setSelectedId();
        dragContext.setDragId();
        dragContext.setDragText();
        dragContext.setDropId();
        dragContext.setDropMode();
    }

    // 
    /**
     * Called on a target item when mouse enters its area
     * Note: strangely onDragEnter is called before onDragLeave 
     *       when the mouse is passing from one item to another one
     */
    const handleDragEnter = (e) => {
        dragContext.setDropId(node.id);
    }

    /**
     * Called on a target item when mouse is hovering its area
     */
    const handleDragOver = (e) => {

        // Default behavior = not allow dragging over another item
        e.preventDefault();

        // Adjust mousePos coordinates 
        // and make it relative to the containing table row
        let tr = e.target;
        while (tr && tr.tagName !== 'TR') tr = tr.parentNode;
        const rect = tr.getBoundingClientRect();
        const mousePos = e.pageY - rect.y;
        
        // Compute before/after trigger detection
        const triggerHeight = rect.height / 4;

        let dropMode = null;

        // Detect drop before current node
        if (mousePos < triggerHeight) {
            dropMode = 'before';
        }
        // Detect drop after current node
        else if (mousePos > rect.height - triggerHeight) {
            dropMode = 'after';
        }
        // Otherwise drop inside current node
        else {
            dropMode = 'inside';
        }

        // Update state
        dragContext.setDropMode(dropMode);

        // Update dnd state
        e.dataTransfer.dropEffect = 'move';
    }

    /**
     * Called on a target item when mouse leaves its area
     * Note: strangely onDragEnter is called before onDragLeave 
     *       when the mouse is passing from one item to another one
     */
    const handleDragLeave = (e) => {
        // Nothing to do
    }

    return (
        <tr {...props}

            className={'node-drop-line' + (dropBefore || dropAfter ? ' show' : '') + (className ? ' ' + className : '')}
            draggable={draggable}

            /* drag source events */
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}

            /* drop target events */
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}

            /* final event */
            onDrop={handleDrop}
        >

            {children}

        </tr>
    )
}

export default DraggableRow;