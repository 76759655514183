
import { useTranslation } from 'react-i18next';
import './ButtonBar.css';

const ButtonBar = ({ className, topic, onClick }) => {

    const ButtonBar = [];
    const { t } = useTranslation();

    for (let i=0; i<topic.levels; i++) {

        if (topic.response === i + 1) {
            ButtonBar.push(<button key={i} className='btn btn-secondary active'></button>);
        }
        else {
            ButtonBar.push(<button key={i} className='btn btn-light' onClick={() => onClick(i)}></button>);
        }
    }

    return (
        <div className={'buttonbar ' + className}>
            <span>{t('invitations.disagree')}</span>
            {ButtonBar}
            <span>{t('invitations.agree')}</span>
        </div>
    )
}

export default ButtonBar
