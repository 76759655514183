
import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useCurriculumController from '../../controllers/useCurriculumController.js';
import CurriculumView from '../../components/curriculum/CurriculumView.js';

const PublicCurriculum = () => {

    /**
     * Page state
     */

    const { id } = useParams();
    const [ queryParams ] = useSearchParams();

    const [user, setUser] = useState({});
    const [catalog, setCatalog] = useState({});
    const [formations, setFormations] = useState([]);
    const [experiences, setExperiences] = useState([]);

    const curriculumController = useCurriculumController();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {

            const lang = queryParams.get('lang') ?? 'en';
            const data = await curriculumController.getPublicCurriculum(id, lang);
            
            setUser(data.user);
            setCatalog(data.catalog);
            setFormations(data.formations);
            setExperiences(data.experiences);

        })()

        // eslint-disable-next-line 
    }, [id, queryParams]);

    return (

        <div className='container'>
            <div className='row p-4'>
                <CurriculumView user={user} catalog={catalog} formations={formations} experiences={experiences} />
            </div>
        </div >
    )
}

export default PublicCurriculum
