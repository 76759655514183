
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';
import FilterUtil from '../utils/FilterUtil.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/surveys`;

const SurveyService = () => {

    const getSurvey = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const getSurveys = async (company, filter) => {
        const surveys = (await axios.get(`${SERVER_URL}?company=${company.id}`)).data.payload;
        return FilterUtil.filter(surveys, filter);
    }    

    const getTotalCount = async (company) => {
        return (await axios.get(`${SERVER_URL}/count?company=${company.id}`)).data.payload;
    }

    const create = async (company, owner, lang) => {
        return (await axios.get(`${SERVER_URL}/create?company=${company.id}&owner=${owner.id}&lang=${lang}`)).data.payload;
    }

    const isDeletable = async (survey) => {
        return (await axios.get(`${SERVER_URL}/${survey.id}/deletable`)).data.payload;
    }

    const save = async (survey) => {
        return survey.id
            ? (await axios.put(`${SERVER_URL}/${survey.id}`, survey)).data.payload
            : (await axios.post(`${SERVER_URL}`, survey)).data.payload;
    }

    const sendInvitations = async (survey) => {
        return (await axios.post(`${SERVER_URL}/send/${survey.id}`, survey)).data.payload;
    }

    const remove = async (survey) => {
        await axios.delete(`${SERVER_URL}/${survey.id}`);
    }

    return {
        getSurveys,
        getSurvey,
        getTotalCount,
        create,
        isDeletable,
        save,
        sendInvitations,
        remove
    }
}

export default SurveyService();