
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import useModalEffect from '../../../hooks/useModalEffect.js';
import useFormationController from '../../../controllers/useFormationController.js';
import useDiplomaTypeController from '../../../controllers/useDiplomaTypeController.js';

const FormationDetail = ({ id, formation, onSave }) => {

    /**
     * Page state
     */

    const modalRef = useRef();
    const { t } = useTranslation();
    const [tmpFormation, setTmpFormation] = useState({});
    const formationController = useFormationController();
    const diplomaTypeController = useDiplomaTypeController();
    const [deletable, setDeletable] = useState(false);

    /**
     * Page lifecycle
     */

    useModalEffect(modalRef, async () => {
        const deletable = await formationController.deletable(formation);
        setDeletable(deletable);
        setTmpFormation(formation);
        diplomaTypeController.reload();
    })

    /**
     * Event handlers
     */

    const handleChange = (attributes) => {
        setTmpFormation({ ...tmpFormation, ...attributes });
    }

    const handleDiplomaTypeChange = (e) => {
        const diplomaType = diplomaTypeController.diplomaTypes.find(d => d.id === e.target.value);
        setTmpFormation({ ...tmpFormation, diplomaType });
    }

    return (
        <div id={id} className='modal fade' ref={modalRef}>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('glossary.formation')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className='modal-body'>

                        <div className='row pt-2'>
                            <div className='col form-group'>
                                <label>{t('formations.school')}</label>
                                <input type='text' className='form-control' value={tmpFormation.school ?? ''} placeholder={t('formations.school-placeholder')} onChange={(e) => handleChange({ school: e.target.value })} />
                            </div>
                        </div>

                        <div className='row pt-2'>
                            <div className='col form-group'>
                                <label htmlFor='diploma'>{t('formations.diploma')}</label>
                                <input id='diploma' type='text' className='form-control' value={tmpFormation.diploma ?? ''} placeholder={t('formations.diploma-placeholder')} onChange={(e) => handleChange({ diploma: e.target.value })} />
                            </div>
                        </div>

                        <div className='row pt-2'>

                            <div className='col form-group'>
                                <label>{t('formations.diplomaType')}</label>
                                <select className='form-select' value={tmpFormation.diplomaType?.id || "1"} onChange={handleDiplomaTypeChange}>
                                    <option value={null}>{t('glossary.none')}</option>
                                    {diplomaTypeController.diplomaTypes?.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                </select>
                            </div>

                            <div className='col-4 form-group'>
                                <label>{t('formations.year')}</label>
                                <input type='number' className='form-control' value={tmpFormation.year ?? ''} placeholder={t('formations.year-placeholder')} onChange={(e) => handleChange({ year: e.target.value })} />
                            </div>

                        </div>

                    </div>

                    <div className='modal-footer'>
                        <div className='d-flex justify-content-between w-100'>

                            <div>
                                <button type='button' className='btn btn-light' data-bs-dismiss='modal' data-bs-toggle='modal' data-bs-target='#delete' disabled={!deletable}>{t('button.delete')}</button>
                            </div>

                            <div className='btn-group'>
                                <button className='btn btn-light' data-bs-dismiss='modal' onClick={() => onSave(tmpFormation)}>{t('button.save')}</button>
                                <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default FormationDetail;
