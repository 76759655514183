
import { useState } from 'react';
import AssessmentService from '../api/AssessmentService.js';

export const AssessmentStatus = { CREATED: 0, STARTED: 1, COMPLETED: 2 };

const useAssessmentController = (user, filter) => {

    /**
     * Component state
     */

    const [assessments, setAssessments] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    /**
     * Functionalities
     */

    const reload = async () => {

        try {
            setLoaded(false);
            const assessments = await AssessmentService.getAssessments(user, filter);
            setAssessments(assessments);

            const totalCount = await AssessmentService.getTotalCount(user);
            setTotalCount(totalCount);
        }
        finally {
            setLoaded(true);
        }
    }

    const deletable = async (assessment) => {
        return assessment.id != null && await AssessmentService.isDeletable(assessment);
    }

    const create = async (user) => {
        return await AssessmentService.create(user);
    }

    const save = async (assessment) => {
        await AssessmentService.save(assessment);
        reload();
    }

    const remove = async (assessment) => {
        await AssessmentService.remove(assessment);
        reload();
    }

    const start = async (assessment) => {
        const link = document.createElement('a');
        assessment.id = await AssessmentService.save(assessment);
        link.href = await AssessmentService.start(assessment);
        link.click();
        reload();
    }

    const resume = async (assessment) => {
        const link = document.createElement('a');
        link.href = await AssessmentService.resume(assessment);
        link.click();
    }

    const report = async (assessment) => {
        const link = document.createElement('a');
        link.href = await AssessmentService.report(assessment);
        link.click();
    }

    return {
        assessments,
        totalCount,
        loaded,
        reload,
        deletable,
        create,
        save,
        remove,
        start,
        resume,
        report
    }
}

export default useAssessmentController;