
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useTooltips from '../../hooks/useTooltips.js';

import StringUtil from "../../utils/StringUtil.js";
import CatalogLogic from '../../logic/CatalogLogic.js';
import CatalogSelector from '../selector/CatalogSelector.js';

const DeclarativeViewSelectable = ({ companyCategory, surveyCategory, onChange }) => {

    const { t } = useTranslation();

    /**
     * Page state
     */

    const [levels, setLevels] = useState([]);
    const [sourceTree, setSourceTree] = useState({ children: [] });
    const [targetTree, setTargetTree] = useState({ children: [] });

    /**
     * Page lifecycle
    */

    useTooltips();

    useEffect(() => {

        (async () => {

            // Create a new company category tree
            const sourceTree = {
                id: 'root',
                label: t('surveys.availableskills'),
                state: { opened: true },
                children: companyCategory.children ?? []
            }

            // Create a new survey category tree
            const targetTree = {
                id: 'root',
                label: t('surveys.selectedskills'),
                state: { opened: true },
                children: surveyCategory.children ?? []
            }

            // Create the levels array (3 or 5 levels)
            const levels = [...Array((surveyCategory.levels ?? 3) + 1).keys()].slice(1);

            setSourceTree(sourceTree);
            setTargetTree(targetTree);
            setLevels(levels);

        })()

        // eslint-disable-next-line 
    }, [companyCategory, surveyCategory]);

    /**
     * Event handlers
     */

    const handleSourceChange = (sourceTree) => {
        setSourceTree(sourceTree);
    }

    const handleTargetChange = (targetTree) => {
        setTargetTree(targetTree);
        onChange({ ...surveyCategory, children: targetTree.children });
    }

    const handleLevelChange = (skill, level) => {

        // Change the skill's level
        CatalogLogic.setSkillLevel(targetTree, skill, level);

        // And propagates the category change to the owner
        onChange({ ...surveyCategory, children: targetTree.children });
    }

    return (

        <div className='container-fluid'>
            <div className='row'>

                {
                    !StringUtil.isEmpty(surveyCategory.description) &&
                    <div className='border rounded text-center mx-3 mb-3 p-3'>{surveyCategory.description}</div>
                }

                <CatalogSelector
                    source={sourceTree}
                    target={targetTree}
                    targetEditable='false'
                    onSourceChange={handleSourceChange}
                    onTargetChange={handleTargetChange}
                >
                    {
                        (skill) => {

                            const hasTooltip = !StringUtil.isEmpty(skill.description);
                            const skillLevel = CatalogLogic.getSkillLevel(targetTree, skill);
                            const showLevel  = CatalogLogic.hasLevel(skill);

                            return (
                                <tr>
                                    <td className={CatalogLogic.isDisabled(skill) ? 'text-muted' : ''}>
                                        <div>
                                            {
                                                hasTooltip &&
                                                <div data-bs-toggle='tooltip' data-bs-title={skill.description} data-bs-placement='right' data-bs-html='true'>{skill.label}</div>
                                            }
                                            {
                                                !hasTooltip &&
                                                skill.label
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className='btn-group'>
                                            {
                                                showLevel && levels.map((value) => {

                                                    const key = skill.id + '_' + value;

                                                    return (
                                                        <div key={key} className='btn-group'>
                                                            <input id={key} className='btn-check' type='radio' checked={skillLevel === value} onChange={() => { }} onClick={() => handleLevelChange(skill, value)} />
                                                            <label className='btn btn-sm btn-outline-primary' htmlFor={key}>{value}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                    }
                </CatalogSelector>

            </div>
        </div>
    )
}

export default DeclarativeViewSelectable;
