
import { useState, useContext, createContext } from "react";
import NumberUtil from '../../utils/NumberUtil.js';

const Context = createContext();

export const useTreeContext = () => {
    return useContext(Context);
}

const TreeContext = ({ editable, children }) => {

    const [ selectedId, setSelectedId ] = useState();
    const [ editMode, setEditMode ] = useState(false);
    const [ editText, setEditText ] = useState('');

    const state = {
        editable,
        treeId: editable ? 'd' + NumberUtil.uniqueId() : '',
        selectedId: editable && selectedId,
        editMode: editable && editMode,
        editText: editable && editText,
        setSelectedId,
        setEditMode,
        setEditText
    }

    return (
        <Context.Provider value={state}>
            {children}
        </Context.Provider>
    )
}

export default TreeContext;