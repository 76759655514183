
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import DiplomaTypeService from '../api/DiplomaTypeService.js';
import LangUtil from "../utils/LangUtil.js";

const useDiplomaTypeController = (filter) => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();
    
    const [diplomaTypes, setDiplomaTypes] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    /**
     * Functionalities
     */

    const reload = async () => {

        try {
            setLoaded(false);
            const diplomaTypes = await DiplomaTypeService.getDiplomaTypes(filter);
            diplomaTypes.forEach(d => translate(d));
            setDiplomaTypes(diplomaTypes);

            const totalCount = await DiplomaTypeService.getTotalCount();
            setTotalCount(totalCount);
        }
        finally {
            setLoaded(true);
        }
    }

    const deletable = async (diplomaType) => {
        return diplomaType.id != null && await DiplomaTypeService.isDeletable(diplomaType);
    }

    const create = async () => {
        const diplomaType = await DiplomaTypeService.create();
        translate(diplomaType);
        return diplomaType;
    }

    const save = async (diplomaType) => {

        try {
            untranslate(diplomaType);
            await DiplomaTypeService.save(diplomaType);
        }
        finally {
            translate(diplomaType);
        }

        reload();
    }

    const remove = async (diplomaType) => {
        await DiplomaTypeService.remove(diplomaType);
        reload();
    }

    const translate = (diplomaType) => {
        LangUtil.translate(diplomaType, 'name', auth.lang);
    }

    const untranslate = (diplomaType) => {
        LangUtil.untranslate(diplomaType, 'name');
    }

    const retranslate = () => {

        diplomaTypes.forEach(diplomaType => {
            untranslate(diplomaType);
            translate(diplomaType);
        });

        setDiplomaTypes([...diplomaTypes]);
    }

    return {
        diplomaTypes,
        totalCount,
        loaded,
        reload,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate
    }
}

export default useDiplomaTypeController;