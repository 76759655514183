
import { useTranslation } from 'react-i18next';
import ButtonBar from './ButtonBar.js';

const MindsetView = ({ mindset, disabled, onChange }) => {

    /**
     * Page state
     * The mindset contains { step, completed, topics, responses }
     */

    const { t } = useTranslation();
    const topic = mindset.topics[mindset.step];

    /**
     * Event handlers
     */

    const handleClick = (topic, response) => {
        topic.response = response + 1;
        onChange({ ...mindset });
    }

    const handleNext = () => {
        mindset.step += 1;
        onChange({ ...mindset });
    }

    return (
        <div className='container p-0 m-0'>
            <div className='d-flex justify-content-center align-items-center border rounded' style={{ height: '45vh' }}>

                {
                    (disabled || mindset.completed) &&
                    <h5 className='text-muted'>{t('invitations.mindsetcompleted')}</h5>
                }

                {
                    !disabled && !mindset.completed &&
                    <div className='d-flex flex-column text-center p-5'>

                        <h5 className='text-muted'>
                            {t('invitations.question')} {mindset.step + 1} / {mindset.topics?.length}
                        </h5>

                        <h5 className='text-center px-5'>
                            {topic.label}
                        </h5>

                        <ButtonBar className='py-4' topic={topic} onClick={(i) => handleClick(topic, i)} />

                        <div>
                            <button className='btn btn-primary my-3 px-4' disabled={!topic.response || topic.response < 1} onClick={handleNext}>
                                {t('button.next')}
                            </button>
                        </div>

                    </div>
                }

            </div>
        </div>
    )
}

export default MindsetView
