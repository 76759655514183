
import { useEffect } from 'react';

/**
 * Bootstrap event listener, that detect modal becoming visible.
 * 
 * example:
 * 
 *      const modalRef = useRef();
 * 
 *      useModalEffect(modalRef, (shown) => {
 *          console.log(`modal shown: ${shown}');
 *      })
 * 
 *      <div id={id} className='modal fade' ref={modalRef}>
 * 
 */

const useModalEffect = (modalRef, callback) => {

    if (!callback || typeof callback !== 'function') {
        console.warn('useModalEffect: invalid callback provided');
    }

    useEffect(() => {

        const mutationHandler = (mutations) => {

            // Detect change in attribute class.
            // If it contains show, the callback is called
            // Note: the class show is set by bootstrap when the modal is displayed
            mutations
                .filter(mutation => mutation.attributeName === 'class')
                .forEach(mutation => mutation.target.classList.contains('modal') && mutation.target.classList.contains('show') && callback() );
        }

        if (modalRef.current) {
            const observer = new MutationObserver(mutationHandler);
            observer.observe(modalRef.current, { attributeFilter: ['class'] });
            return () => observer.disconnect();
        }

    }, [modalRef, callback]);

}

/**
 * Same, but with bootstrap events (doesn't work very well)
 *
const useModalEffect = (modalRef, callback) => {

    const modal = modalRef.current;

    useEffect(() => {

        // Add the listener (bootstrap specific)
        modal?.addEventListener('shown.bs.modal', callback);

        // Remove the listener, when the component is removed from the dom
        return () => {
            modal?.removeEventListener('shown.bs.modal', callback);
        }

    }, [modalRef, callback]);
}
*/

export default useModalEffect;