
/**
 * Utility class with global and generic functions
 * Warning: this class is the exact same on backend and frontend
 */
const ObjectUtil = () => {

    /**
     * Return true if the object is a new object
     * That is, it hasn't yet any id
     */
    const isNew = (obj) => {
        return obj && !obj.hasOwnProperty('id');
    }

    /**
     * Convert a json object to a string
     */
    const fromString = (text) => {
        return text ? JSON.parse(text) : null;
    }

    /**
     * Convert a string to a json object
     */
     const toString = (obj) => {
        return obj ? JSON.stringify(obj) : '{}';
    }

    /**
     * Convert a string to a map object
     */
    const stringToMap = (text) => {
        if (!text) return new Map();
        return new Map(Object.entries(JSON.parse(text)));
    }

    /**
     * Convert a map to a string
     */
     const mapToString = (map) => {
        if (!map) return '{}';
        return JSON.stringify(Object.fromEntries(map.entries()))
    }

    /**
     * Return a deep copy of a json object
     */
    const clone = (object) => {
        return object ? JSON.parse(JSON.stringify(object)) : {};
    }

    return {
        isNew,
        fromString,
        toString,
        stringToMap,
        mapToString,
        clone
    }
}

export default ObjectUtil();