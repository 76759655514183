
import DateUtil from './DateUtil.js';
import StringUtil from './StringUtil.js';

const FilterUtil = () => {

    /**
     * Filter the items provided according to the filter specified.
     * 
     * The filter can contains two attributes:
     * 
     *      a) a search string
     *          used to include all items containing the string (whatever the attributes)
     * 
     *      b) an attributes array
     *          contains a list of attribute definitions
     * 
     * each attribute can contain these informations:
     * 
     *      name:       the name of the attribute to examine while searching / filtering / sorting. 
     *                  name is selected from the names generated by the mapper rules (see flatten)
     * 
     *      display:    only used by FilterDialog. This is the name displayed in the FilterDialog selector
     *      sort:       specify if the attribute should be sorted (1 = ascending, -1 = descending)
     *      in:         an array of values used to constrain allowed items
     * 
     * Note: 
     *      when sorting multiple attributes, the order of the attributes is taken
     *      for attribute object, the composite name should be used. for instance:
     * 
     *          if items are of the form : 
     *          { id: 'x', name: 'y', company: { id: 'a', name: 'b' }}
     *          to filter list of company.name, the attribute name should be companyName
     * 
     * Exemple of a simple filter object:
     * 
     *      const filterExample = {
     *          search: 'hello',
     *          attributes: [
     *              { name: 'companyId', display: null, sort: 1, in: [ '1', '2' ] },
     *              { name: 'username', display: 'Username', sort: -1 },
     *              { name: 'firstName', display: 'Firstname', sort: 'desc' },
     *              { name: 'lastName', display: 'Lastname', sort: 'asc', in: [ 'nour' ] }
     *              { name: 'companyName', display: 'Company', sort: 1, in: [ 'nour' ] }
     *          ]
     *      }
     * 
     */

    const filter = (items, filter) => {

        if (filter?.attributes) {
            
            // Filter by string
            if (filter.search) items = filterByString(items, filter);

            // Filter by attribute's value
            items = filterByAttribute(items, filter);

            // Sort the filtered items
            sortItems(items, filter);
        }

        return items;
    }

    const filterByString = (items, filter) => {

        // Select all items with at least one attribute's value containing the search text
        items = items.filter(item => {

            let found = false;
            const flatItem = flatten(item);

            for (const attr of filter.attributes) {
                if (flatItem.hasOwnProperty(attr.name)) {

                    const attrValue = flatItem[attr.name];

                    if (StringUtil.containsText(attrValue, filter.search)) {
                        found = true;
                        break;
                    }

                }
            }

            /*
            // Scan each attribute value and check if it contains the text
            for (let attrName of Object.keys(flatItem)) {

                const attrValue = flatItem[attrName];

                if (StringUtil.containsText(attrValue, filter.search)) {
                    found = true;
                    break;
                }
            }
            */ 

            return found;
        })

        return items;
    }

    const filterByAttribute = (items, filter) => {

        // Select the attributes with filtered values
        const attributes = filter.attributes?.filter(attr => attr.in);

        if (!attributes || attributes.length < 1) return items;

        items = items.filter(item => {

            const flatItem = flatten(item);

            // For each attribute, check if the attr value is allowed
            for (const attr of attributes) {

                if (!flatItem.hasOwnProperty(attr.name)) {
                    return false;
                }

                // Get the item attribute value
                const itemValue = flatItem[attr.name];

                // Check if the attribute values/value are allowed
                if (Array.isArray(itemValue)) {

                    // Calculate intersection
                    const intersect = itemValue.filter(item => attr.in.includes(item));

                    // If some items common, return true
                    if (!intersect || intersect?.length < 1) {
                        return false;
                    }
                }

                // Check if the attribute value is allowed
                else if (!attr.in.includes(itemValue)) {
                    return false;
                }
            }

            return true;
        })

        return items;
    }

    const sortItems = (items, filter) => {

        // Select the attributes to sort
        const attributes = filter.attributes?.filter(attr => attr.sort);
        
        if (!attributes || attributes.length < 1) return items;

        items.sort((a, b) => {

            // Retrieve the item to compare
            const flatItemA = flatten(a);
            const flatItemB = flatten(b);

            // Evaluate order between both items
            for (const attr of attributes) {

                // Extract attributes values
                const attrValueA = flatItemA[attr.name] ? flatItemA[attr.name].toLowerCase() : '';
                const attrValueB = flatItemB[attr.name] ? flatItemB[attr.name].toLowerCase() : '';

                if (attrValueA > attrValueB) {
                    return attr.sort; // a after b => 1
                }
                else if (attrValueA < attrValueB) {
                    return -attr.sort; // a before b => -1
                }
            }

            // No discrimimant attribute found
            return 0;
        })
    }

    /**
     * Return an object containing a single function flatten to allow item transformation
     * The flatten function convert an item into an object containing only attributes of type string.
     * 
     * For instance, the following object:
     * 
     *      {
     *          title: 'test',
     *          age: 23,
     *          company: {
     *              name: 'company',
     *              owner: false
     *          }
     *      }
     * 
     * will be flatten to an object string as follow:
     *      {
     *          title: 'test',
     *          age: '23',
     *          companyName: 'company',
     *          companyOwner: 'false'
     *      }
     */
    const flatten = (object) => {

        const flatObject = {};

        if (!object) return flatObject;

        // Scan all attributes of the object
        for (const attrName of Object.keys(object)) {

            const attrValue = object[attrName];

            // Convert attribute of type object
            if (typeof attrValue === 'object') {

                const flatChild = flatten(attrValue);

                for (const childAttrName of Object.keys(flatChild)) {
                    const flatChildAttrName = attrName + childAttrName[0].toUpperCase() + childAttrName.substring(1);
                    flatObject[flatChildAttrName] = flatChild[childAttrName];
                }
            }

            // Convert attribute of type array
            else if (Array.isArray(attrValue)) {

                flatObject[attrName] = [];

                for (const child of attrValue) {
                    const itemValue = toString(child);
                    flatObject[attrName].push(itemValue);
                }
            }

            // All other attribute types are simply converted to string
            else {
                flatObject[attrName] = toString(attrValue);
            }
        }

        return flatObject;
    }

    /**
     * Convert an object attributes into string.
     * If an attribute is an object or an array, they are also converted
     */
    const toString = (attrValue) => {

        let strValue = null;

        // Map null or function to empty string
        if (attrValue === null || typeof attrValue === 'function') {
            strValue = '';
        }

        // Boolean are converted to 'true' of 'false'
        else if (typeof attrValue === 'boolean') {
            strValue = attrValue ? 'true' : 'false';
        }

        // Date are converted to iso string
        else if (attrValue instanceof Date) {
            strValue = DateUtil.toIsoString(attrValue);
        }

        // Otherwise call the attrValue toString function
        else {
            strValue = attrValue?.toString();
        }

        return strValue ?? '';
    }

    return {
        filter,
        flatten
    }
}

export default FilterUtil();