
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useSurveyController from '../../../controllers/useSurveyController.js';

import DateUtil from '../../../utils/DateUtil.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import SearchBar from '../../../components/filter/SearchBar.js';
import SurveyLogic from '../../../logic/SurveyLogic.js';
import SearchCount from '../../../components/filter/SearchCount.js';
import SortableHeader from '../../../components/sortable/SortableHeader.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';
import chat_img from '../../../assets/images/chart.svg';

const Surveys = () => {

    const { t } = useTranslation();

    const defaultFilter = {
        attributes: [
            { name: 'creation', display: t('surveys.date') },
            { name: 'dueDate', display: t('surveys.duedate') },
            { name: 'name', display: t('surveys.name') },
            { name: 'ownerLastname', display: t('surveys.owner') },
            { name: 'status', display: t('surveys.status') },
        ]
    }

    /**
     * Page state
     */

    const { auth } = useAuthContext();
    const navigate = useNavigate();
    const [filter, setFilter] = useState(defaultFilter);
    
    const surveyController = useSurveyController(filter);

    /**
     * Page lifecycle
     */

    // eslint-disable-next-line 
    useEffect(() => { surveyController.reload() }, [filter, auth.company]);

    // eslint-disable-next-line 
    useEffect(() => { surveyController.retranslate() }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        navigate('/manager/surveys/new');
    }

    const handleDetail = (survey) => {
        navigate('/manager/surveys/' + survey.id);
    }

    const handleChart = (survey) => {
        navigate('/manager/surveys/' + survey.id + '/charts');
    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    return (

        <div className='container'>
            <div className='row p-4'>

                <LoadingIcon loading={!surveyController.loaded} />

                <div className='d-flex align-items-center'>
                    <h5 className='text-muted mb-1'>{t('glossary.surveys')}</h5>
                    <SearchCount className='text-muted mb-1 ms-2' count={surveyController.surveys.length} total={surveyController.totalCount} />
                    <SearchBar className='ms-4' items={surveyController.surveys} filter={filter} onSearch={handleFilter} />
                </div>

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>
                                <SortableHeader attrName='creation' filter={filter} onFilter={handleFilter}>
                                    {t('surveys.date')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='name' filter={filter} onFilter={handleFilter}>
                                    {t('surveys.name')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='ownerLastname' filter={filter} onFilter={handleFilter}>
                                    {t('surveys.owner')}
                                </SortableHeader>
                            </th>
                            <th className='text-center'>
                                {t('glossary.invitations')}
                            </th>
                            <th>
                                <SortableHeader attrName='status' filter={filter} onFilter={handleFilter}>
                                    {t('surveys.status')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='dueDate' filter={filter} onFilter={handleFilter}>
                                    {t('surveys.duedate')}
                                </SortableHeader>
                            </th>
                            <th className='text-center'>
                                <button className='btn btn-light' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            surveyController.surveys.map((survey) => (
                                <tr key={survey.id} className='align-middle'>
                                    <td>{DateUtil.toLocaleString(survey.creation)}</td>
                                    <td>{survey.name}</td>
                                    <td>{survey.owner?.firstname + ' ' + survey.owner?.lastname}</td>
                                    <td className='text-center'>{survey.invitationCount > 0 ? survey.invitationCount : '-'}</td>
                                    <td>{SurveyLogic.toStringStatus(survey.status)}</td>
                                    <td>{DateUtil.toLocaleString(survey.dueDate)}</td>
                                    <td className='text-center'>
                                        <div className='btn-group'>
                                            <button className='btn btn-light' onClick={() => handleDetail(survey)}><img src={edit_img} alt='edit' /></button>
                                            <button className='btn btn-light' onClick={() => handleChart(survey)}><img src={chat_img} alt='chart' /></button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default Surveys
