
const LoginLogic = () => {

    /**
     * Return true if both password are the same
     */
    const isMatching = (newPassword, confirmPassword) => {
        return newPassword === confirmPassword;
    }

    /**
     * Name validation (only letters)
     */
    const isNameValid = (text) => {
        return text && text.length > 1;

        /*
        const normalizedText = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        const regex = /^[a-zA-Z]{3,}$/;
        return regex.test(normalizedText);
        */
    }
    
    /**
     * Password validation with the following rules:
     * check regex: https://rubular.com/r/Q66wtCcTUJ
     * 
     *      (?= xxx) = positive lookahead = look after
     * 
     *      ^                               =>  start of the string.
     *      (?=.{8,})                       => 8 characters or more
     *      (?=.*[A-Za-z])                  => letters
     *      (?=.*\d)                        => digits
     *      (?=.*[@!#$%&?-_°§\[\]\(\)\*"])  => special characters
     *      $                               =>  end of the string.
     * 
     * test
     * 
     *      bBa389678aA_
     */

    const isPasswordValid = (password) => {
        const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@!#$%&?-_°§[()*"\]])(?=.{8,})/;
        return regex.test(password);
    }

    /**
     * Email validation with the following rules:
     * 
     *      ^                   =>  start of the string.
     *      [a-zA-Z0-9._-]+     =>  match one or more alphanumeric characters, dots, underscores, or hyphens for the username part.
     *      @                   =>  match the "@" symbol.
     *      [a-zA-Z0-9.-]+      =>  match one or more alphanumeric characters, dots, or hyphens for the domain name.
     *      \.                  =>  match a period (dot), which separates the domain name and top-level domain (TLD).
     *      [a-zA-Z]{2,4}       =>  match the TLD, consisting of 2 to 4 alphabetical characters.
     *      $                   =>  end of the string.
     * 
     *      eslint-disable-next-line
     */
    const isEmailValid = (email) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(email);
    }
    
    return {
        isMatching,
        isNameValid,
        isEmailValid,
        isPasswordValid
    }
}

export default LoginLogic();

