
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import FilterDialog from './FilterDialog.js';

import filter_img from '../../assets/images/filter.svg'; 

import './SearchBar.css'

const SearchBar = ({ items, filter, showFilter = true, onSearch, ...props }) => {

    showFilter = (typeof showFilter === 'string') ? showFilter === 'true' : showFilter;

    const { t } = useTranslation();
    const [text, setText] = useState('');
    const [expanded, setExpanded] = useState(false);

    const handleChange = (e) => {

        const searchText = e.target.value;
        setText(searchText);

        const newFilter = { ...filter };

        if (searchText.length > 0) {
            setExpanded(true);
            newFilter.search = searchText.trim();
        }
        else {
            delete newFilter.search;
        }

        onSearch && onSearch(newFilter);
    }

    const handleUnfocus = () => {
        if (text.length < 1) setExpanded(false);
    }

    // const handleFilter = (filter) => {
    //     onSearch && onSearch(filter);
    // }

    return (

        <div {...props} className={'d-flex flex-fill justify-content-end align-items-center' + (props.className ? ' ' + props.className : '')}>

            <input type='search' className='searchfield form-control' placeholder={t('glossary.search') + '...'} value={text ?? ''} style={{ width: (expanded ? '100%' : '8rem') }} onChange={handleChange} onBlur={handleUnfocus}/>

            {
                showFilter &&
                <button disabled type='button' className='btn btn-light' data-bs-toggle='modal' data-bs-target='#filter'><img src={filter_img} alt='filter' /></button>
            }
            {
                // showFilter &&
                // <FilterDialog id='filter' items={items} filter={filter} onFilter={handleFilter} />
            }

        </div>
    )
}

export default SearchBar