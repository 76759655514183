
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';
import FilterUtil from '../utils/FilterUtil.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/invitations`;

const InvitationService = () => {

    const getInvitations = async (user, filter) => {
        const invitations = (await axios.get(`${SERVER_URL}?user=${user.id}`)).data.payload;
        return FilterUtil.filter(invitations, filter);
    }

    const getTotalCount = async (user) => {
        return (await axios.get(`${SERVER_URL}?user=${user.id}`)).data.payload;
    }

    const create = async (survey, user) => {
        return (await axios.put(`${SERVER_URL}/create?survey=${survey.id}`, user)).data.payload;
    }

    const createInvitables = async (survey, company, filter) => {
        
        const invitables = survey.id 
            ? (await axios.get(`${SERVER_URL}/createall?survey=${survey.id}&company=${company.id}`)).data.payload
            : (await axios.get(`${SERVER_URL}/createall?company=${company.id}`)).data.payload;

        return FilterUtil.filter(invitables, filter);
    }

    const getInvitation = async (id, lang) => {
        return (await axios.get(`${SERVER_URL}/${id}?lang=${lang}`)).data.payload;
    }

    const save = async (invitation) => {
        return (await axios.put(`${SERVER_URL}/${invitation.id}`, { invitation })).data.payload;
    }

    return {
        create,
        createInvitables,
        getInvitation,
        getInvitations,
        getTotalCount,
        save
    }
}

export default InvitationService();