
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.js';
import { BrowserRouter as Router, } from 'react-router-dom';
import { AuthContext } from './controllers/useAuthContext.js';
import AxiosInterceptor from './utils/AxiosInterceptor.js';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './i18n.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <Router>
        <AuthContext>
            <AxiosInterceptor>
                <App />
            </AxiosInterceptor>
        </AuthContext>
    </Router>

)
