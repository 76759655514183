
import { v4 as uuid } from 'uuid';

const NumberUtil = () => {

    /**
     * Return a random number
     */
    const randomId = () => {
        return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    }

    /**
     * Return a unique identifier as a string
     */
    const uniqueId = () => {
        return uuid();
    }

    return {
        randomId,
        uniqueId,
    }
}

export default NumberUtil();