
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/catalogs`;

const CatalogService = () => {
    
    const getMasterCatalog = async () => {
        return (await axios.get(`${SERVER_URL}/master`)).data.payload;
    }

    const getGlobalCatalog = async () => {
        return (await axios.get(`${SERVER_URL}/global`)).data.payload;
    }

    const getUserCatalog = async (user) => {
        return (await axios.get(`${SERVER_URL}?user=${user.id}`)).data.payload;
    }
    
    const getCompanyCatalog = async (company) => {
        return (await axios.get(`${SERVER_URL}?company=${company.id}`)).data.payload;
    }
    
    const getCompanyInventoryCatalog = async (company) => {
        return (await axios.get(`${SERVER_URL}?companychart=${company.id}`)).data.payload;
    }

    const getSurveyCatalog = async (survey, company) => {
        return (await axios.get(`${SERVER_URL}?survey=${survey.id}&company=${company.id}`)).data.payload;
    }
    
    const getSurveyInventoryCatalog = async (survey) => {
        return (await axios.get(`${SERVER_URL}?surveychart=${survey.id}`)).data.payload;
    }
    
    const getMotionCatalog = async (motion, company) => {
        return (await axios.get(`${SERVER_URL}?motion=${motion.id}&company=${company.id}`)).data.payload;
    }

    const saveMasterCatalog = async (catalog) => {
        await axios.put(`${SERVER_URL}/master`, catalog);
    }    

    const saveGlobalCatalog = async (catalog) => {
        await axios.put(`${SERVER_URL}/global`, catalog);
    }

    const saveUserCatalog = async (user, catalog) => {
        await axios.put(`${SERVER_URL}?user=${user.id}`, catalog);
    }

    const saveCompanyCatalog = async (company, catalog) => {
        await axios.put(`${SERVER_URL}?company=${company.id}`, catalog);
    }    

    const saveSurveyCatalog = async (survey, catalog) => {
        await axios.put(`${SERVER_URL}?survey=${survey.id}`, catalog);
    }

    return {
        getMasterCatalog,
        getGlobalCatalog,
        getCompanyCatalog,
        getUserCatalog,
        getSurveyCatalog,
        getCompanyInventoryCatalog,
        getSurveyInventoryCatalog,
        getMotionCatalog,
        
        saveMasterCatalog,
        saveGlobalCatalog,
        saveCompanyCatalog,
        saveUserCatalog,
        saveSurveyCatalog
    }
}

export default CatalogService();