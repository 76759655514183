
import { useTranslation } from 'react-i18next';

const AxesView = ({ id, axes, categories = [], onChange }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();

    /**
     * Event handlers
     */

    const handleXAxisChange = (id) => {
        onChange && onChange({ ...axes, x: id });
    }

    const handleYAxisChange = (id) => {
        onChange && onChange({ ...axes, y: id });
    }

    const handleZAxisChange = (id) => {
        onChange && onChange({ ...axes, z: id });
    }

    return (

        <div id={id} className='modal fade'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('motions.dimensions')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className='modal-body'>

                        <div className='row pt-2 px-3'>

                            <div className='col form-group'>
                                <label htmlFor='xaxis'>{t('motions.xaxis')}</label>
                                <select id='xaxis' className='form-select' value={axes?.x ?? ''} onChange={(e) => handleXAxisChange(e.target.value)}>
                                    {categories.map((item) => <option key={item.id} value={item.id}>{item.label}</option>)}
                                </select>
                            </div>

                        </div>

                        <div className='row pt-2 px-3'>

                            <div className='col form-group'>
                                <label htmlFor='yaxis'>{t('motions.yaxis')}</label>
                                <select id='yaxis' className='form-select' value={axes?.y ?? ''} onChange={(e) => handleYAxisChange(e.target.value)}>
                                    {categories.map((item) => <option key={item.id} value={item.id}>{item.label}</option>)}
                                </select>
                            </div>

                        </div>

                        <div className='row pt-2 px-3'>

                            <div className='col form-group'>
                                <label htmlFor='zaxis'>{t('motions.zaxis')}</label>
                                <select id='zaxis' className='form-select' value={axes?.z ?? ''} onChange={(e) => handleZAxisChange(e.target.value)}>
                                    {categories.map((item) => <option key={item.id} value={item.id}>{item.label}</option>)}
                                </select>
                            </div>

                        </div>

                    </div>

                    <div className='modal-footer'>
                        <div className='d-flex justify-content-end w-100'>
                            <div className='btn-group'>
                                <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.close')}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AxesView;
