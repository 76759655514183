
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';
import FilterUtil from '../utils/FilterUtil.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/countries`;

const CountryService = () => {

    const getCountries = async (filter) => {
        const countries = (await axios.get(`${SERVER_URL}`)).data.payload;
        return FilterUtil.filter(countries, filter);
    }

    const getCountry = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const getTotalCount = async () => {
        return (await axios.get(`${SERVER_URL}/count`)).data.payload;
    }

    const create = async () => {
        return (await axios.get(`${SERVER_URL}/create`)).data.payload;
    }

    const isDeletable = async (country) => {
        return (await axios.get(`${SERVER_URL}/${country.id}/deletable`)).data.payload;
    }

    const save = async (country) => {

        return country.id
            ? (await axios.put(`${SERVER_URL}/${country.id}`, country)).data.payload
            : (await axios.post(`${SERVER_URL}`, country)).data.payload;
    }

    const remove = async (country) => {
        await axios.delete(`${SERVER_URL}/${country.id}`);
    }

    return {
        getCountries,
        getCountry,
        getTotalCount,
        create,
        isDeletable,
        save,
        remove
    }
}

export default CountryService();