
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useTooltips from '../../hooks/useTooltips.js';

import StringUtil from '../../utils/StringUtil.js';
import TreeTable from '../treetable/TreeTable.js';
import CatalogLogic from '../../logic/CatalogLogic.js';

const DeclarativeViewEditable = ({ category, disabled = false, onChange }) => {

    const { t } = useTranslation();

    /**
     * Page state
     */

    const [levels, setLevels] = useState([]);
    const [skilltree, setSkillTree] = useState(category ?? { children: [] });

    /**
     * Page lifecycle
     */

    useTooltips();

    useEffect(() => {

        (async () => {

            (async () => {

                // Get the catalog to display
                const skilltree = { id: 'root', label: t('glossary.skills'), state: { opened: true }, children: category.children }

                // Create the levels array (3 or 5 levels)
                const levels = [...Array((category.levels ?? 3) + 1).keys()].slice(1);

                setLevels(levels);
                setSkillTree(skilltree);

            })()

        })()

        // eslint-disable-next-line 
    }, [category]);

    /**
     * Event handlers
     */

    const handleLevelChange = (skill, level) => {

        // Change the skill's level
        CatalogLogic.setSkillLevel(category, skill, level);

        // And propagates the category change to the owner
        onChange({ ...category, children: skilltree.children });
    }

    return (

        <div className='container-fluid'>
            
            {
                !StringUtil.isEmpty(category.description) &&
                <div className='border rounded text-center mx-3 mb-3 p-3'>{category.description}</div>
            }

            <TreeTable className='table table-hover border' data={skilltree} onChange={(tree) => setSkillTree(tree)}>
                <tbody>
                    {

                        (skill) => {

                            const hasTooltip = !StringUtil.isEmpty(skill.description);
                            const skillLevel = CatalogLogic.getSkillLevel(category, skill);
                            const showLevel = CatalogLogic.isLeaf(skill) && CatalogLogic.hasLevel(skill);

                            return (
                                <tr>
                                    <td>
                                        {
                                            hasTooltip &&
                                            <div data-bs-toggle='tooltip' data-bs-title={skill.description} data-bs-placement='right' data-bs-html='true'>{skill.label}</div>
                                        }
                                        {
                                            !hasTooltip &&
                                            skill.label
                                        }
                                    </td>
                                    <td>
                                        <div className='btn-group'>
                                            {
                                                showLevel && levels.map((value) => {

                                                    const key = skill.id + '_' + value;

                                                    return (
                                                        <div key={key} className='btn-group'>
                                                            <input id={key} className='btn-check' type='radio' checked={skillLevel === value} disabled={disabled} onChange={() => { }} onClick={() => handleLevelChange(skill, value)} />
                                                            <label className='btn btn-sm btn-outline-primary' htmlFor={key}>{value}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </td>

                                </tr>
                            )
                        }
                    }
                </tbody>
            </TreeTable>

        </div>

    )
}

export default DeclarativeViewEditable
