
import { useTranslation } from 'react-i18next';
import InventoryLogic from '../../logic/InventoryLogic.js';

import './GenderChart.css';

const GenderChart = ({ users, onSelectionChange }) => {

    const { t } = useTranslation();
    
    // Retrieve the selected users classified by gender categories
    const [ na, male, female ] = InventoryLogic.getGenderData(users);

    /**
     * Event handlers
     */

    const handleSelectionChange = (users) => {
        onSelectionChange(users.map(user => user.id));
    }

    return (
        <div className='genderchart mb-3 p-3 border rounded'>

            <h5>{t('charts.genders')}</h5>

            <ul className='legend px-3'>
                <li><div className='bullet male'/>{t('charts.male').toLowerCase()}</li>
                <li><div className='bullet female'/>{t('charts.female').toLowerCase()}</li>
                <li><div className='bullet na'/>{t('charts.na').toLowerCase()}</li>
            </ul>

            <div className='chart px-3'>

                {
                    male.users.length > 0 &&
                    <div className='category male' style={{ width: male.percent*100+'%' }} onClick={() => { handleSelectionChange(male.users)} }>
                        {male.users.length }
                    </div>        
                }

                {
                    female.users.length > 0 &&
                    <div className='category female' style={{ width: female.percent*100+'%' }} onClick={() => { handleSelectionChange(female.users)} }>
                        {female.users.length }
                    </div>                    
                }

                {
                    na.users.length > 0 &&
                    <div className='category na' style={{ width: na.percent*100+'%' }} onClick={() => { handleSelectionChange(na.users)} }>
                        {na.users.length }
                    </div>               
                }
            
            </div>
        </div>

    )
}

export default GenderChart;