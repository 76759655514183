
import { useState } from 'react';
import useUserController from '../controllers/useUserController.js';
import useDiplomaTypeController from '../controllers/useDiplomaTypeController.js';
import FormationService from '../api/FormationService.js';

const useFormationController = (user, filter) => {

    /**
     * Component state
     */

    const userController = useUserController();
    const diplomaTypeController = useDiplomaTypeController();
    const [formations, setFormations] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    /**
     * Functionalities
     */

    const reload = async () => {

        try {
            setLoaded(false);
            const formations = await FormationService.getFormations(user, filter);
            formations.forEach(f => translate(f));
            setFormations(formations);

            const totalCount = await FormationService.getTotalCount(user);
            setTotalCount(totalCount);
        }
        finally {
            setLoaded(true);
        }
    }

    const deletable = async (formation) => {
        return formation.id != null && await FormationService.isDeletable(formation);
    }

    const create = async (user) => {
        const formation = await FormationService.create(user);
        translate(formation);
        return formation;
    }

    const save = async (formation) => {

        try {
            untranslate(formation);
            await FormationService.save(formation);
        }
        finally {
            translate(formation);
        }

        reload();
    }

    const remove = async (formation) => {
        await FormationService.remove(formation);
        reload();
    }

    const translate = (formation, lang) => {
        formation.user && userController.translate(formation.user);
        formation.diplomaType && diplomaTypeController.translate(formation.diplomaType);
    }

    const untranslate = (formation) => {
        formation.user && userController.untranslate(formation.user);
        formation.diplomaType && diplomaTypeController.untranslate(formation.diplomaType);
    }

    const retranslate = () => {

        formations.forEach(formation => {
            untranslate(formation);
            translate(formation);
        });

        setFormations([...formations]);
    }

    return {
        formations,
        totalCount,
        loaded,
        reload,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate
    }
}

export default useFormationController;