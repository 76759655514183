
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useDiplomaTypeController from '../../../controllers/useDiplomaTypeController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import SearchBar from '../../../components/filter/SearchBar.js';
import SearchCount from '../../../components/filter/SearchCount.js';
import SortableHeader from '../../../components/sortable/SortableHeader.js';
import DiplomaTypeDetail from './DiplomaTypeDetail.js';
import DiplomaTypeDelete from './DiplomaTypeDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const DiplomaTypes = () => {

    const { t } = useTranslation();

    const defaultFilter = {
        attributes: [
            { name: 'code', display: t('diplomatypes.code') },
            { name: 'name', display: t('diplomatypes.name') }
        ]
    }

    /**
     * Page state
     */

    const { auth } = useAuthContext();

    const [filter, setFilter] = useState(defaultFilter);
    const [diplomaType, setDiplomaType] = useState({});
    
    const diplomaTypeController = useDiplomaTypeController(true, filter);

    /**
     * Page lifecycle
     */

    // eslint-disable-next-line 
    useEffect(() => { diplomaTypeController.reload() }, [filter]);

    // eslint-disable-next-line 
    useEffect(() => { diplomaTypeController.retranslate() }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const diplomaType = await diplomaTypeController.create();
        setDiplomaType(diplomaType);
    }

    const handleDetail = (diplomaType) => {
        setDiplomaType(diplomaType);
    }

    const handleSave = async (diplomaType) => {
        await diplomaTypeController.save(diplomaType);
    }

    const handleDelete = async (diplomaType) => {
        await diplomaTypeController.remove(diplomaType);
    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!diplomaTypeController.loaded} />
            <DiplomaTypeDetail id='detail' diplomaType={diplomaType} onSave={handleSave} />
            <DiplomaTypeDelete id='delete' diplomaType={diplomaType} onDelete={handleDelete} />

            <div className='row p-4'>

                <div className='d-flex align-items-center'>
                    <h5 className='text-muted mb-1'>{t('glossary.diplomatypes')}</h5>
                    <SearchCount className='text-muted mb-1 ms-2' count={diplomaTypeController.diplomaTypes.length} total={diplomaTypeController.totalCount} />
                    <SearchBar className='ms-4' items={diplomaTypeController.diplomaTypes} filter={filter} onSearch={handleFilter} />
                </div>

                <hr />


                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>
                                <SortableHeader attrName='code' filter={filter} onFilter={handleFilter}>
                                    {t('diplomatypes.code')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='name' filter={filter} onFilter={handleFilter}>
                                    {t('diplomatypes.name')}
                                </SortableHeader>
                            </th>
                            <th className='text-center'>
                                <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            diplomaTypeController.diplomaTypes.map((diplomaType) => (
                                <tr key={diplomaType.id} className='align-middle'>
                                    <td>{diplomaType.code}</td>
                                    <td>{diplomaType.name}</td>
                                    <td className='text-center'><button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={() => handleDetail(diplomaType)}><img src={edit_img} alt='edit' /></button></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default DiplomaTypes
