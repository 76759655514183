
import parse from 'html-react-parser';
import { NavLink } from 'react-router-dom';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import { useTranslation } from 'react-i18next';

const Home = () => {

    /**
     * Page state
    */

    const { auth } = useAuthContext();
    const { t } = useTranslation();

    return (

        <div className='container'>
            <div className='row p-4'>

                <h5 className='text-muted'>{t('home.title', { user: auth.user?.name })}</h5>

                <hr />

                <div className='card mb-3'>
                    <div className='card-body'>

                        <h5 className='home.cardtitle'>{t('home.card1.title')}</h5>
                        <p>{parse(t('home.card1.content'))}</p>
                        <NavLink to='/user/invitations' className='link-primary'>{t('home.card1.link')}</NavLink>

                    </div>
                </div>

            </div>
        </div >
    )
}

export default Home
