
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import useModalEffect from '../../../hooks/useModalEffect.js';
import useCompanyController from '../../../controllers/useCompanyController.js';
import useCountryController from '../../../controllers/useCountryController.js';
import useUserTypeController from '../../../controllers/useUserTypeController.js';

const CompanyDetail = ({ id, company, onSave }) => {

    /**
     * Page state
     */

    const modalRef = useRef();
    const { t } = useTranslation();
    const [tmpCompany, setTmpCompany] = useState({});
    const [deletable, setDeletable] = useState(false);
    
    const companyController = useCompanyController();
    const countryController = useCountryController();
    const userTypeController = useUserTypeController();

    /**
     * Page lifecycle
     */

    useModalEffect(modalRef, async () => {
        countryController.reload();
        userTypeController.reload();
        const deletable = await companyController.deletable(company);
        setDeletable(deletable);
        setTmpCompany(company);
    })

    /**
     * Event handlers
     */

    const handleChange = (attributes) => {
        setTmpCompany({ ...tmpCompany, ...attributes });
    }

    const handleCountryChange = (e) => {
        const country = countryController.countries.find(c => c.id === e.target.value);
        setTmpCompany({ ...tmpCompany, country });
    }

    const handleUserTypeChange = (e) => {
        const userType = userTypeController.userTypes.find(t => t.id === e.target.value);
        setTmpCompany({ ...tmpCompany, userType });
    }

    const handleSave = (company) => {
        onSave && onSave(company);
    }

    return (
        <div id={id} className='modal fade' ref={modalRef}>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('glossary.company')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className='modal-body'>

                        <div className='form-group'>
                            <label htmlFor='name'>{t('companies.name')}</label>
                            <input id='name' type='text' className='form-control' value={tmpCompany.name ?? ''} placeholder={t('companies.name-placeholder')} onChange={(e) => handleChange({ name: e.target.value })} />
                        </div>

                        <div className='row pt-2'>

                            <div className='col form-group'>
                                <label htmlFor='director'>{t('companies.director')}</label>
                                <input id='director' type='text' className='form-control' value={tmpCompany.director ?? ''} placeholder={t('companies.director-placeholder')} onChange={(e) => handleChange({ director: e.target.value })} />
                            </div>

                            <div className='col form-group'>
                                <label htmlFor='phone'>{t('companies.phone')}</label>
                                <input id='phone' type='text' className='form-control' value={tmpCompany.phone ?? ''} placeholder={t('companies.phone-placeholder')} onChange={(e) => handleChange({ phone: e.target.value })} />
                            </div>

                        </div>

                        <div className='row pt-2'>

                            <div className='col-9 form-group'>
                                <label htmlFor='street'>{t('companies.street')}</label>
                                <input id='street' type='text' className='form-control' value={tmpCompany.street ?? ''} placeholder={t('companies.street-placeholder')} onChange={(e) => handleChange({ street: e.target.value })} />
                            </div>

                            <div className='col form-group'>
                                <label htmlFor='streetNumber'>{t('companies.number')}</label>
                                <input id='streetNumber' type='string' className='form-control' value={tmpCompany.streetNumber ?? ''} placeholder={t('companies.number-placeholder')} onChange={(e) => handleChange({ streetNumber: e.target.value })} />
                            </div>

                        </div>

                        <div className='row pt-2'>
                            <div className='col form-group'>
                                <label htmlFor='complement'>{t('companies.complement')}</label>
                                <input id='complement' type='text' className='form-control' value={tmpCompany.streetComplement ?? ''} placeholder={t('companies.complement-placeholder')} onChange={(e) => handleChange({ streetComplement: e.target.value })} />
                            </div>
                        </div>

                        <div className='row pt-2'>

                            <div className='col-9 form-group'>
                                <label htmlFor='city'>{t('companies.city')}</label>
                                <input id='city' type='text' className='form-control' value={tmpCompany.city ?? ''} placeholder={t('companies.city-placeholder')} onChange={(e) => handleChange({ city: e.target.value })} />
                            </div>

                            <div className='col form-group'>
                                <label htmlFor='cityCode'>{t('companies.citycode')}</label>
                                <input id='cityCode' type='string' className='form-control' value={tmpCompany.cityCode ?? ''} placeholder={t('companies.citycode-placeholder')} onChange={(e) => handleChange({ cityCode: e.target.value })} />
                            </div>

                        </div>

                        <div className='row pt-2'>

                            <div className='col form-group'>
                                <label htmlFor='country'>{t('glossary.country')}</label>
                                <select id='country' className='form-select' value={tmpCompany.country?.id ?? ''} onChange={handleCountryChange}>
                                    <option value={null}>{t('glossary.none')}</option>
                                    {countryController.countries?.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                </select>
                            </div>

                        </div>

                        <div className='row pt-2'>

                            <div className='col form-group'>
                                <label htmlFor='usertype'>{t('companies.default-usertype')}</label>
                                <select id='usertype' className='form-select' value={tmpCompany.userType?.id ?? ''} onChange={handleUserTypeChange}>
                                    {userTypeController.userTypes?.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                </select>
                            </div>

                        </div>

                        <div className='row p-4'>

                            <div className='form-group form-check'>
                                <label htmlFor='owner' className='form-check-label'>{t('companies.owner')}</label>
                                <input id='owner' className='form-check-input' type='checkbox' checked={tmpCompany.owner ?? false} onChange={(e) => handleChange({ owner: !tmpCompany.owner })} />
                            </div>

                            <div className='form-group form-check'>
                                <label htmlFor='disabled' className='form-check-label'>{t('companies.disabled')}</label>
                                <input id='disabled' className='form-check-input' type='checkbox' checked={tmpCompany.disabled || false} onChange={(e) => handleChange({ disabled: !tmpCompany.disabled })} />
                            </div>

                        </div>

                    </div>

                    <div className='modal-footer'>
                        <div className='d-flex justify-content-between w-100'>

                            <div>
                                <button type='button' className='btn btn-light' data-bs-dismiss='modal' data-bs-toggle='modal' data-bs-target='#delete' disabled={!deletable}>{t('button.delete')}</button>
                            </div>

                            <div className='btn-group'>
                                <button className='btn btn-light' data-bs-dismiss='modal' onClick={() => handleSave(tmpCompany)}>{t('button.save')}</button>
                                <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CompanyDetail;
