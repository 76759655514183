
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';
import FilterUtil from '../utils/FilterUtil.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/workplaces`;

const WorkplaceService = () => {

    const getWorkplaces = async (company, filter) => {
        
        const workplaces = company 
            ? (await axios.get(`${SERVER_URL}?company=${company.id}`)).data.payload
            : [];

        return FilterUtil.filter(workplaces, filter);
    }

    const getWorkplace = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const getTotalCount = async (company) => {
        return (await axios.get(`${SERVER_URL}/count?company=${company.id}`)).data.payload;
    }

    const create = async (company) => {
        return (await axios.get(`${SERVER_URL}/create?company=${company.id}`)).data.payload;
    }

    const isDeletable = async (workspace) => {
        return (await axios.get(`${SERVER_URL}/${workspace.id}/deletable`)).data.payload;
    }

    const save = async (workplace) => {

        return workplace.id
            ? (await axios.put(`${SERVER_URL}/${workplace.id}`, workplace)).data.payload
            : (await axios.post(`${SERVER_URL}`, workplace)).data.payload;
    }

    const remove = async (workplace) => {
        await axios.delete(`${SERVER_URL}/${workplace.id}`);
    }

    return {
        getWorkplaces,
        getWorkplace,
        getTotalCount,
        create,
        isDeletable,
        save,
        remove
    }
}

export default WorkplaceService();