
import { useState } from 'react';
import useUserController from '../controllers/useUserController.js';
import useSectorController from '../controllers/useSectorController.js';
import ExperienceService from '../api/ExperienceService.js';

const useExperienceController = (user, filter) => {

    /**
     * Component state
     */

    const userController = useUserController();
    const sectorController = useSectorController();
    const [experiences, setExperiences] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    /**
     * Functionalities
     */

    const reload = async () => {

        try {
            setLoaded(false);
            const experiences = await ExperienceService.getExperiences(user, filter);
            experiences.forEach(e => translate(e));
            setExperiences(experiences);

            const totalCount = await ExperienceService.getTotalCount(user);
            setTotalCount(totalCount);
        }
        finally {
            setLoaded(true);
        }
    }

    const deletable = async (experience) => {
        return experience.id != null && await ExperienceService.isDeletable(experience);
    }

    const create = async (user) => {
        const experience = await ExperienceService.create(user);
        translate(experience);
        return experience;
    }

    const save = async (experience) => {

        try {
            untranslate(experience);
            await ExperienceService.save(experience);
        }
        finally {
            translate(experience);
        }

        reload();
    }

    const remove = async (experience) => {
        await ExperienceService.remove(experience);
        reload();
    }

    const translate = (experience) => {
        experience.user && userController.translate(experience.user);
        experience.sector && sectorController.translate(experience.sector);
    }

    const untranslate = (experience) => {
        experience.user && userController.untranslate(experience.user);
        experience.sector && sectorController.untranslate(experience.sector);
    }

    const retranslate = () => {

        experiences.forEach(experience => {
            untranslate(experience);
            translate(experience);
        });

        setExperiences([...experiences]);
    }

    return {
        experiences,
        totalCount,
        loaded,
        reload,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate
    }
}

export default useExperienceController;