
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';
import FilterUtil from '../utils/FilterUtil.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/assessments`;

const AssessmentService = () => {

    const getAssessments = async (user, filter) => {
        const assessments = (await axios.get(`${SERVER_URL}?user=${user.id}`)).data.payload;
        return FilterUtil.filter(assessments, filter);
    }

    const getAssessment = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const getTotalCount = async (user) => {
        return (await axios.get(`${SERVER_URL}/count?user=${user.id}`)).data.payload;
    }

    const create = async (user) => {
        return (await axios.get(`${SERVER_URL}/create?user=${user.id}`)).data.payload;
    }

    const isDeletable = async (assessment) => {
        return (await axios.get(`${SERVER_URL}/${assessment.id}/deletable`)).data.payload;
    }

    const save = async (assessment) => {

        return assessment.id
            ? (await axios.put(`${SERVER_URL}/${assessment.id}`, assessment)).data.payload
            : (await axios.post(`${SERVER_URL}`, assessment)).data.payload;
    }

    const remove = async (assessment) => {
        await axios.delete(`${SERVER_URL}/${assessment.id}`);
    }

    const start = async (assessment) => {
        return (await axios.put(`${SERVER_URL}/${assessment.id}/start`)).data.payload;
    }
    
    const resume = async (assessment) => {
        return (await axios.put(`${SERVER_URL}/${assessment.id}/resume`)).data.payload;
    }
    
    const report = async (assessment) => {
        return (await axios.get(`${SERVER_URL}/${assessment.id}/report`)).data.payload;
    }    
    
    return {
        getAssessments,
        getAssessment,
        getTotalCount,
        create,
        isDeletable,
        save,
        remove,
        start,
        resume,
        report
    }
}

export default AssessmentService();