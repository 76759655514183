
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../controllers/useAuthContext.js';
import useWishController from '../../controllers/useWishController.js';
import TreeUtil from '../../utils/TreeUtil.js';

const WishesView = ({ wishes, onInit, onChange }) => {

    /**
     * Page state 
     */

    const { auth } = useAuthContext();
    const [wishTree, setWishTree] = useState({});
    const [initialized, setInitialized] = useState(false);

    const wishController = useWishController();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {

            // Build a single tree of wishes with category/wishes/subtypes
            const wishTree = { id: 'root', children: wishController.categories };
            
            // Mark all user wishes as checked
            const wishmap = new Map(wishes.map(wishId => { return [wishId, wishId] }));
            TreeUtil.toList(wishTree).forEach(wish => wish.checked = wishmap.has(wish.id));
            
            setWishTree(wishTree);

            // First time, notify owner that the wishes have been loaded
            if (!initialized) {
                setInitialized(true);
                onInit && onInit();
            }

        })()

        // eslint-disable-next-line
    }, [wishController.loaded, wishes]);

    // eslint-disable-next-line 
    useEffect(() => { wishController.reload() }, []);

    // eslint-disable-next-line 
    useEffect(() => { wishController.retranslate() }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleToogle = (wish) => {

        const wishlist = TreeUtil.toList(wishTree);
        const parent = wishlist.find(w => w.id === wish.parent);

        // A leaf wish (wish without children) is toggled
        if (wish.children.length === 0) {
            
            wish.checked = !wish.checked;
            
            // Check if some sibling wishes are selected
            parent.checked = parent.children.reduce((checked, item) => checked || item.checked, false);
        }

        // A leaf with subtypes is toggled
        else if (wish.parent != null) {
            
            wish.checked = !wish.checked;
            wish.children.forEach(w => w.checked = wish.checked);
        }

        // Retrieve selected wish ids
        const wishes = wishlist.filter(wish => wish.checked).map(wish => wish.id);
        onChange({ wishes });
    }

    return (

        <div>
            {
                wishTree.children?.map((category, i) => {

                    return (
                        <fieldset key={category.id} className={'form-group border rounded mx-3 px-3 pb-3 vh-50' + (i > 0 ? ' mt-2' : '')}>

                            <legend className={'float-none w-auto px-2 fs-6'}>{category.name}</legend>

                            {
                                category.children.map((wish) => {

                                    return (
                                        <div key={wish.id} className='m-1'>

                                            <div>
                                                <input id={wish.id} className='form-check-input' type='checkbox' checked={wish.checked ?? false} onChange={() => handleToogle(wish)} />
                                                <label htmlFor={wish.id} className='form-check-label ps-2'>{wish.name}</label>
                                            </div>

                                            {
                                                wish.children.length > 1 &&
                                                <div className='btn-group ps-4 pb-2'>
                                                    {
                                                        wish.children.map((subwish) => {

                                                            return (
                                                                <div key={subwish.id} className='btn-group'>
                                                                    <input id={subwish.id} className='btn-check' type='checkbox' checked={subwish.checked ?? false} onChange={() => handleToogle(subwish)} />
                                                                    <label htmlFor={subwish.id} className='btn btn-sm btn-outline-primary'>{subwish.name}</label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }

                                        </div >
                                    )
                                })
                            }

                        </fieldset>
                    )
                })
            }
        </div>
    )
}

export default WishesView;
