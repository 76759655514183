
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useUserController from '../../../controllers/useUserController.js';
import useCurriculumController from '../../../controllers/useCurriculumController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import PositionDetail from './PositionDetail.js';
import CurriculumView from '../../../components/curriculum/CurriculumView.js';

import edit_img from '../../../assets/images/pen.svg';

const Curriculum = () => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const { id } = useParams();

    const [user, setUser] = useState({});
    const [catalog, setCatalog] = useState({});
    const [formations, setFormations] = useState([]);
    const [experiences, setExperiences] = useState([]);
    const [editable, setEditable] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const userController = useUserController();
    const curriculumController = useCurriculumController();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {

            try {
                setLoaded(false);

                const data = await curriculumController.getPrivateCurriculum(id ?? auth.user.id, auth.lang);

                setUser(data.user);
                setCatalog(data.catalog);
                setFormations(data.formations);
                setExperiences(data.experiences);
                setEditable(id == null);
            }
            finally {
                setLoaded(true);
            }

        })()    

        // eslint-disable-next-line 
    }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleSave = async (user) => {
        await userController.save(user);
        setUser({ ...user });
    }

    return (

        <div className='container'>
            <div className='row p-4'>

                <LoadingIcon loading={!loaded} />
                <PositionDetail id='detail' user={user} onSave={handleSave} />

                <div className='d-flex align-items-center justify-content-between'>
                    <h5 className='text-muted'>{t('mycv.title')}</h5>
                    { editable && <button type='button' className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail'><img src={edit_img} alt='edit' /></button> }
                </div>

                <hr />

                <CurriculumView user={user} catalog={catalog} formations={formations} experiences={experiences} />

            </div>
        </div >
    )
}

export default Curriculum
