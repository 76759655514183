
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Cropper } from 'react-advanced-cropper';
import useModalEffect from '../../hooks/useModalEffect.js';
import ImageUtil from '../../utils/ImageUtil.js';

import avatar_img from '../../assets/images/avatar.svg';

import 'react-advanced-cropper/dist/style.css';

const ImageEditor = ({ id, picture, onChange }) => {

    /**
     * Page state
     */

    const modalRef = useRef();
    const cropperRef = useRef();
    const hiddenButton = useRef();

    const { t } = useTranslation();
    const [image, setImage] = useState(picture);

    /**
     * Page lifecycle
     */

    useModalEffect(modalRef, () => { setImage(picture) });

    /**
     * Event handlers
     */

    const handleReset = (e) => {
        setImage();
    }

    const handleFileSelect = (e) => {

        e.preventDefault();

        if (hiddenButton.current) {
            hiddenButton.current.value = null;
            hiddenButton.current.click();
        }
    }

    const handleFileUpload = (e) => {

        if (e.target.files?.length > 0) {
            ImageUtil.loadImage(e.target.files[0], (image) => setImage(image));
        }
    }

    const handleSave = () => {
        onChange(image ? cropperRef.current?.getCanvas()?.toDataURL() : null);
    }

    return (

        <div id={id} className='modal fade' ref={modalRef}>
            <div className='modal-dialog modal-sm2 modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('glossary.imageEditor')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className='modal-body'>

                        <div className='row'>
                            <div className='col'>
                                {
                                    image &&
                                    <Cropper className='avatar-croppable' src={image} ref={cropperRef} stencilProps={{ aspectRatio: 1 }} />
                                }
                                {
                                    !image &&
                                    <div><img className='avatar-croppable' src={avatar_img} alt='avatar'></img></div>
                                }
                            </div>
                        </div>

                    </div>

                    <div className='modal-footer'>
                        <div className='d-flex justify-content-between w-100'>

                            <input type='file' ref={hiddenButton} style={{ display: 'none' }} accept='image/*' onInput={handleFileUpload} />

                            <div className='btn-group'>
                                <button className='btn btn-light' onClick={handleReset}>{t('button.reset')}</button>
                                <button className='btn btn-light' onClick={handleFileSelect}>{t('button.import')}</button>
                            </div>

                            <div className='btn-group'>
                                <button className='btn btn-light' data-bs-dismiss='modal' onClick={() => handleSave()}>{t('button.apply')}</button>
                                <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ImageEditor;
