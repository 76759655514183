
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useExperienceController from '../../../controllers/useExperienceController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import DateUtil from '../../../utils/DateUtil.js';
import SearchBar from '../../../components/filter/SearchBar.js';
import SearchCount from '../../../components/filter/SearchCount.js';
import SortableHeader from '../../../components/sortable/SortableHeader.js';
import ExperienceDetail from './ExperienceDetail.js';
import ExperienceDelete from './ExperienceDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const Experiences = () => {

    const { t } = useTranslation();

    const defaultFilter = {
        attributes: [
            { name: 'start', display: 'Start' },
            { name: 'end', display: 'End' },
            { name: 'positionTitle', display: 'Position' },
            { name: 'company', display: 'Company' },
            { name: 'sector', display: 'Sector' }
        ]
    }

    /**
     * Page state
    */

    const { auth } = useAuthContext();
    const [filter, setFilter] = useState(defaultFilter);
    const experienceController = useExperienceController(auth.user, filter);
    const [experience, setExperience] = useState({});

    /**
     * Page lifecycle
     */

    // eslint-disable-next-line 
    useEffect(() => { experienceController.reload() }, [filter, auth.user]);

    // eslint-disable-next-line 
    useEffect(() => { experienceController.retranslate() }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const experience = await experienceController.create(auth.user);
        setExperience(experience);
    }

    const handleDetail = (experience) => {
        setExperience(experience);
    }

    const handleSave = async (experience) => {
        await experienceController.save(experience);
    }

    const handleDelete = async (experience) => {
        await experienceController.remove(experience);
    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!experienceController.loaded} />
            <ExperienceDetail id='detail' experience={experience} onSave={handleSave} />
            <ExperienceDelete id='delete' experience={experience} onDelete={handleDelete} />

            <div className='row p-4'>

                <div className='d-flex align-items-center'>
                    <h5 className='text-muted mb-1'>{t('profile.experiences')}</h5>
                    <SearchCount className='text-muted mb-1 ms-2' count={experienceController.experiences.length} total={experienceController.totalCounbt} />
                    <SearchBar className='ms-4' items={experienceController.experiences} filter={filter} onSearch={handleFilter} />
                </div>

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>
                                <SortableHeader attrName='start' filter={filter} onFilter={handleFilter}>
                                    {t('experiences.start')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='end' filter={filter} onFilter={handleFilter}>
                                    {t('experiences.end')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='positionTitle' filter={filter} onFilter={handleFilter}>
                                    {t('experiences.position')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='sector' filter={filter} onFilter={handleFilter}>
                                    {t('experiences.sector')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='company' filter={filter} onFilter={handleFilter}>
                                    {t('experiences.company')}
                                </SortableHeader>
                            </th>
                            <th className='text-center'>
                                <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            experienceController.experiences.map((experience) => (
                                <tr key={experience.id} className='align-middle'>
                                    <td>{DateUtil.toLocaleString(experience.start) ?? '-'}</td>
                                    <td>{DateUtil.toLocaleString(experience.end) ?? '-'}</td>
                                    <td>{experience.positionTitle ?? '-'}</td>
                                    <td>{experience.sector?.name ?? '-'}</td>
                                    <td>{experience.company ?? '-'}</td>
                                    <td className='text-center'><button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={() => handleDetail(experience)}><img src={edit_img} alt='edit' /></button></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default Experiences
