
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useCatalogController from '../../../controllers/useCatalogController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import CatalogSelector from '../../../components/selector/CatalogSelector.js';

const SkillsCatalog = () => {

    /**
     * Page state
     */

    const { auth } = useAuthContext();

    const { t } = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [masterTree, setMasterTree] = useState({});
    const [globalTree, setGlobalTree] = useState({});

    const catalogController = useCatalogController();

    /**
     * Page lifecycle
     */

    // initial data loading
    useEffect(() => {

        (async () => {

            try {
                setLoaded(false);

                const masterTree = await catalogController.getMasterCatalog(auth.lang);
                setMasterTree(masterTree);

                const globalTree = await catalogController.getGlobalCatalog(auth.lang);
                setGlobalTree(globalTree);
            }
            finally {
                setLoaded(true);
            }

        })()

        // eslint-disable-next-line 
    }, []);

    // language change
    useEffect(() => {

        catalogController.retranslate(masterTree);
        setMasterTree({ ...masterTree });

        catalogController.retranslate(globalTree);
        setGlobalTree({ ...globalTree });

        // eslint-disable-next-line 
    }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleSourceChange = (data) => {
        setMasterTree(data);
    }

    const handleTargetChange = (data) => {
        setGlobalTree(data);
    }

    const handleImport = async (filename) => {

        // Upload master file
        await catalogController.importMasterCatalog(filename);

        // Reload the master tree
        const masterTree = await catalogController.getMasterCatalog(auth.lang);
        setMasterTree(masterTree);
    }

    const handleExport = () => {
        catalogController.exportCatalog(globalTree);
    }

    const handleReset = async () => {
        const globalTree = await catalogController.getGlobalCatalog(auth.lang);
        setGlobalTree(globalTree);
    }

    const handleSave = async () => {
        await catalogController.saveGlobalCatalog(globalTree);
    }

    return (

        <div className='container-fluid'>
            <div className='row p-4'>

                <LoadingIcon loading={!loaded} />
                <h5 className='text-muted'>{t('glossary.catalogs')}</h5>

                <hr />

                <div className='row pt-4'>
                    <CatalogSelector
                        source={masterTree}
                        sourceTitle={t('catalog.master')}
                        target={globalTree}
                        targetTitle={t('catalog.global')}
                        onSourceChange={handleSourceChange}
                        onTargetChange={handleTargetChange}
                        onImport={handleImport}
                        onExport={handleExport}
                        onReset={handleReset}
                        onSave={handleSave}
                    />
                </div>

            </div>
        </div>
    )
}

export default SkillsCatalog
