
import { useRef, useEffect } from 'react';

import './Slider.css'

/**
 * https://nikitahl.com/style-range-input-css
 * https://codepen.io/micahjones/pen/gOWXRjY
 */
const Slider = ({ min, max, value, disabled = false, onChange }) => {

    const ref = useRef();

    useEffect(() => {

        const slider = ref.current;

        if (slider) {
            
            // Whenever the radio value change, update the slider track's background size
            const valPercent = (slider.value - slider.min) / (slider.max - slider.min) * 100;
            slider.style.setProperty('--valuePercent', `${valPercent}%`);
        }

    }, [ref, disabled, value]);

    return (

        <div className='slider'>
            <input 
                type="range" 
                ref={ref} 
                className={'slider-range' + (value ? '' : ' unselected') + (disabled ? ' disabled' : '')} 
                min={min} 
                max={max} 
                value={value} 
                disabled={disabled} 
                onChange={onChange} />
        </div>
    )
}

export default Slider