
import unsort_img from '../../assets/images/sort-none.svg'; 
import ascsort_img from '../../assets/images/sort-up.svg'; 
import descsort_img from '../../assets/images/sort-down.svg'; 

import './SortableHeader.css';

const SortableHeader = ({ attrName, filter, children, onFilter, ...props }) => {

    const attribute = filter.attributes.find(a => a.name === attrName);
    const enabled = attribute !== undefined;
    let sortImage = null;

    if (enabled) {

        switch(attribute?.sort) {
            case 1:
                sortImage = descsort_img;
                break;
            case -1:
                sortImage = ascsort_img;
                break;
            default:
                sortImage = unsort_img;
        }
    }

    const handleClick = (e) => {

        const newFilter = { ...filter };
        const newAttribute = newFilter.attributes?.find(a => a.name === attrName);

        if (!newAttribute.sort) {
            newAttribute.sort = 1;
        }
        else if (newAttribute.sort === 1) {
            newAttribute.sort = -1;
        }
        else {
            delete newAttribute.sort;
        }

        // Reset other sort attributes
        newFilter.attributes.forEach(a => {
            if (a.name !== attrName) delete a.sorted;
        })
            
        onFilter && onFilter(newFilter);
    }

    if (enabled) {
        return (
            <div {...props} className={'sortable-header' + (props.className ? ' ' + props.className : '')} onClick={handleClick}>
                { children }
                <div className='ps-1'><img src={sortImage } alt='sort' /></div>
            </div>
        )
    }

    return children;
}

export default SortableHeader;