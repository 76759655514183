
import { useTranslation } from 'react-i18next';
import TreeTable from '../treetable/TreeTable.js';
import InventoryLogic from '../../logic/InventoryLogic.js';

import './GapIndividualView.css';

const GapIndividualView = ({ skilltree, selectedUser, onTreeChange }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();

    /**
     * Event handlers
     */

    const handleChange = (skilltree) => {
        onTreeChange && onTreeChange(skilltree);
    }

    return (
        <TreeTable className='skilltree-mobility table table-sm' data={skilltree} onChange={handleChange}>
            <thead>
                <tr className='text-center'>
                    <th></th>
                    <th>{t('charts.expected')}</th>
                    <th>{t('charts.actual')}</th>
                    <th>{t('charts.gap')}</th>
                </tr>
            </thead>
            <tbody>
                {
                    (skill) => {

                        // If it is not a leaf
                        if (!selectedUser || (skill.children && skill.children.length) > 0) {
                            return (
                                <tr>
                                    <td>{skill.label ?? '-'}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )
                        }

                        else {

                            // Otherwise, it is a leaf, so compute levels + gap
                            const expectedLevel = skill.expectedLevel ?? 0;
                            const actualLevel   = InventoryLogic.getMatchingUserLevel(skill, selectedUser) ?? 0;
                            const gap           = actualLevel - expectedLevel;

                            return (
                                <tr>

                                    <td>{skill.label ?? '-'}</td>
                                    <td className='text-center'>{expectedLevel}</td>
                                    <td className='text-center'>{actualLevel}</td>
                                    <td className='text-center'>
                                        {gap < 0 && <span className='badge rounded-pill bg-danger'>{Math.abs(gap)}</span>}
                                        {gap === 0 && <span>-</span>}
                                        {gap > 0 && <span className='badge rounded-pill bg-success'>{Math.abs(gap)}</span>}
                                    </td>

                                </tr>
                            )
                        }
                    }
                }
            </tbody>
        </TreeTable>
    )
}

export default GapIndividualView;