
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import UserTypeService from '../api/UserTypeService.js';
import LangUtil from "../utils/LangUtil.js";

const useUserTypeController = (filter) => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();

    const [userTypes, setUserTypes] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    /**
     * Functionalities
     */

    const reload = async () => {

        try {
            setLoaded(false);
            const usertypes = await UserTypeService.getUserTypes(filter);
            usertypes.forEach(c => translate(c, auth.lang));
            setUserTypes(usertypes);

            const totalCount = await UserTypeService.getTotalCount();
            setTotalCount(totalCount);
        }
        finally {
            setLoaded(true);
        }
    }

    const deletable = async (usertype) => {
        return usertype.id && await UserTypeService.isDeletable(usertype);
    }

    const create = async () => {
        const userType = await UserTypeService.create();
        translate(userType, auth.lang);
        return userType;
    }

    const save = async (usertype) => {

        try {
            untranslate(usertype);
            await UserTypeService.save(usertype);
        }
        finally {
            translate(usertype, auth.lang);
        }

        reload();
    }

    const remove = async (usertype) => {
        await UserTypeService.remove(usertype);
        reload();
    }

    const translate = (userType) => {
        LangUtil.translate(userType, 'name', auth.lang);
        LangUtil.translate(userType, 'description', auth.lang);
    }

    const untranslate = (userType) => {
        LangUtil.untranslate(userType, 'name');
        LangUtil.untranslate(userType, 'description');
    }

    const retranslate = () => {

        userTypes.forEach(userType => {
            untranslate(userType);
            translate(userType);
        });

        setUserTypes([...userTypes]);
    }

    return {
        userTypes,
        totalCount,
        loaded,
        reload,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate
    }
}

export default useUserTypeController;