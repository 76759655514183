
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useModalEffect from '../../../hooks/useModalEffect.js';
import useCountryController from '../../../controllers/useCountryController.js';

const CountryDetail = ({ id, country, onSave }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const modalRef = useRef();
    const [tmpCountry, setTmpCountry] = useState({});
    const [deletable, setDeletable] = useState(false);
    
    const countryController = useCountryController();

    /**
     * Page lifecycle
     */

    useModalEffect(modalRef, async () => {
        const deletable = await countryController.deletable(country);
        setTmpCountry(country);
        setDeletable(deletable);
    })

    /**
     * Event handlers
     */

    const handleChange = (attributes) => {
        setTmpCountry({ ...tmpCountry, ...attributes });
    }

    return (

        <div id={id} className='modal fade' ref={modalRef}>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('glossary.country')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className='modal-body'>
                        <div>

                            <div className='form-group'>
                                <label htmlFor='code'>{t('countries.code')}</label>
                                <input id='code' type='text' className='form-control' value={tmpCountry.code ?? ''} placeholder={t('countries.code-placeholder')} onChange={(e) => handleChange({ code: e.target.value })} />
                            </div>

                            <div className='form-group'>
                                <label htmlFor='name'>{t('countries.name')}</label>
                                <input id='name' type='text' className='form-control' value={tmpCountry.name ?? ''} placeholder={t('countries.name-placeholder')} onChange={(e) => handleChange({ name: e.target.value })} />
                            </div>

                        </div>
                    </div>

                    <div className='modal-footer'>
                        <div className='d-flex justify-content-between w-100'>

                            <div>
                                <button type='button' className='btn btn-light' data-bs-dismiss='modal' data-bs-toggle='modal' data-bs-target='#delete' disabled={!deletable}>{t('button.delete')}</button>
                            </div>

                            <div className='btn-group'>
                                <button className='btn btn-light' data-bs-dismiss='modal' onClick={() => onSave(tmpCountry)}>{t('button.save')}</button>
                                <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CountryDetail;
