
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';
import FilterUtil from '../utils/FilterUtil.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/sectors`;

const SectorService = () => {

    const getSectors = async (filter) => {
        const sectors = (await axios.get(`${SERVER_URL}`)).data.payload;
        return FilterUtil.filter(sectors, filter);
    }

    const getSector = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const getTotalCount = async () => {
        return (await axios.get(`${SERVER_URL}/count`)).data.payload;
    }

    const create = async () => {
        return (await axios.get(`${SERVER_URL}/create`)).data.payload;
    }

    const isDeletable = async (sector) => {
        return (await axios.get(`${SERVER_URL}/${sector.id}/deletable`)).data.payload;
    }

    const save = async (sector) => {

        return sector.id
            ? (await axios.put(`${SERVER_URL}/${sector.id}`, sector)).data.payload
            : (await axios.post(`${SERVER_URL}`, sector)).data.payload;
    }

    const remove = async (sector) => {
        await axios.delete(`${SERVER_URL}/${sector.id}`);
    }

    return {
        getSectors,
        getSector,
        getTotalCount,
        create,
        isDeletable,
        save,
        remove
    }
}

export default SectorService();