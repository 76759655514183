
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useModalEffect from '../../../hooks/useModalEffect.js';
import useDiplomaTypeController from '../../../controllers/useDiplomaTypeController.js';

const DiplomaTypeDetail = ({ id, diplomaType, onSave }) => {

    /**
     * Page state
     */

    const modalRef = useRef();
    const { t } = useTranslation();
    const [tmpDiplomaType, setTmpDiplomaType] = useState({});
    const [deletable, setDeletable] = useState(false);
    
    const diplomaTypeController = useDiplomaTypeController();

    /**
     * Page lifecycle
     */

    useModalEffect(modalRef, async () => {
        const deletable = await diplomaTypeController.deletable(diplomaType);
        setTmpDiplomaType(diplomaType);
        setDeletable(deletable);
    })

    /**
     * Event handlers
     */

    const handleChange = (attributes) => {
        setTmpDiplomaType({ ...tmpDiplomaType, ...attributes });
    }

    return (
        <div id={id} className='modal fade' ref={modalRef}>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('glossary.diplomatype')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className='modal-body'>

                        <div className='col-6 form-group'>
                            <label htmlFor='name'>{t('diplomatypes.code')}</label>
                            <input id='name' type='text' className='form-control' value={tmpDiplomaType.code ?? ''} placeholder={t('diplomatypes.code-placeholder')} onChange={(e) => handleChange({ code: e.target.value })} />
                        </div>

                        <div className='col form-group'>
                            <label htmlFor='description'>{t('diplomatypes.name')}</label>
                            <input id='description' type='text' className='form-control' value={tmpDiplomaType.name ?? ''} placeholder={t('diplomatypes.name-placeholder')} onChange={(e) => handleChange({ name: e.target.value })} />
                        </div>

                    </div>

                    <div className='modal-footer'>
                        <div className='d-flex justify-content-between w-100'>

                            <div>
                                <button type='button' className='btn btn-light' data-bs-dismiss='modal' data-bs-toggle='modal' data-bs-target='#delete' disabled={!deletable}>{t('button.delete')}</button>
                            </div>

                            <div className='btn-group'>
                                <button className='btn btn-light' data-bs-dismiss='modal' onClick={() => onSave(tmpDiplomaType)}>{t('button.save')}</button>
                                <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default DiplomaTypeDetail;
