
import { useTranslation } from 'react-i18next';

const InvitationSent = ({ show, onClose }) => {

    const { t } = useTranslation();

    return (
        <div className={'modal fade ' + (show ? 'show' : '')} style={{ display: show ? 'block' : 'none' }}>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content modal-alert'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('invitations.send-title')}</h2>
                        <button type='button' className='btn-close' onClick={() => onClose()}></button>
                    </div>

                    <div className='modal-body'>
                        {t('invitations.send-message')}
                    </div>

                    <div className='modal-footer d-flex justify-content-center'>
                        <button className='btn btn-light d-block' onClick={() => onClose()}>{t('button.ok')}</button>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default InvitationSent;
