
import { useTranslation } from 'react-i18next';
import InventoryLogic from '../../logic/InventoryLogic.js';
import './InfoBox.css';

const InfoBox = ({ users }) => {

    const { t } = useTranslation();
    const visibleUsers = InventoryLogic.getVisibles(users);

    return (

        <div className='infobox border rounded p-4'>
            <h1>{visibleUsers.length}/{users.length}</h1>
            <span>{t('charts.candidates')}</span>
        </div>

    )
}

export default InfoBox;