
import DateUtil from "../utils/DateUtil.js";
import UserService from "../api/UserService.js";

const InventoryLogic = () => {

    const ageCategories = [
        { id: 'NA', name: 'N/A', low: 999, high: 999, users: [] },
        { id: '00', name: '<18', low: 0, high: 18, users: [] },
        { id: '18', name: '18-35', low: 18, high: 35, users: [] },
        { id: '35', name: '35-45', low: 35, high: 45, users: [] },
        { id: '45', name: '45-55', low: 45, high: 55, users: [] },
        { id: '55', name: '55-65', low: 55, high: 65, users: [] },
        { id: '65', name: '>65', low: 65, high: 200, users: [] },
    ]

    const genderCategories = [
        { id: 'NA', name: 'N/A', users: [] },
        { id: 'M', name: 'M', users: [] },
        { id: 'F', name: 'F', users: [] },
    ]

    const mindsetCategories = [
        { id: 'NA', name: 'N A', low: 999, high: 999, percent: 0, frequency: 0 },
        { id: '00', name: '0 20', low: 0.0, high: 0.2, percent: 0, frequency: 0 },
        { id: '20', name: '20 40', low: 0.2, high: 0.4, percent: 0, frequency: 0 },
        { id: '40', name: '40 60', low: 0.4, high: 0.6, percent: 0, frequency: 0 },
        { id: '60', name: '60 80', low: 0.6, high: 0.8, percent: 0, frequency: 0 },
        { id: '80', name: '80 100', low: 0.8, high: 1.0, percent: 0, frequency: 0 }
    ]

    const createDefaultFilters = () => {
        return {
            ageRange:     { name: 'ageRange', items: [] },
            workplace:    { name: 'workplace', items: [] },
            positionType: { name: 'positionType', items: [] },
            userType:     { name: 'userType', items: [] }
        }
    }

    const createFilters = (workplaces, positionTypes, userTypes) => {

        return {
            ageRange: {
                name: 'ageRange',
                items: ageCategories.map(category => { return { id: category.id, label: category.name, selected: true } })
            },
            workplace: {
                name: 'workplace',
                items: workplaces.map(workplace => { return { id: workplace.id, label: workplace.name, selected: true } })
            },
            positionType: {
                name: 'positionType',
                items: positionTypes.map(positionType => { return { id: positionType.id, label: positionType.name, selected: true } })
            },
            userType: {
                name: 'userType',
                items: userTypes.map(userType => { return { id: userType.id, label: userType.name, selected: true } })
            }
        }
    }

    /**
     * Refresh the visible/selected state of the users,
     * according to the filter passed.
     */
    const applyFilters = (users, filters = null) => {

        // By default all users are visible
        users.forEach(user => {
            user.visible = true;
            user.selected = true;
        })

        // Scan all items of all filters
        if (filters) {

            for (const filter of Object.values(filters)) {
                for (const item of filter.items) {
                    if (!item.selected) {

                        // If a filter item is unselected
                        users.forEach(user => {
                            // Mark all users with this filter item value as invisible
                            if (user[filter.name] && (user[filter.name].id === item.id || user[filter.name] === item.id)) {
                                user.visible = false;
                                user.selected = false;
                            }
                        })
                    }
                }
            }
        }
    }

    /**
     * Return a list of the users visible in the inventory
     */
    const getVisibles = (users) => {
        return users.filter(user => user.visible);
    }

    /**
     * Return a list of the users selected in the inventory
     */
    const getSelected = (users) => {
        return users.filter(user => user.visible && user.selected);
    }

    /**
     * Find all user-ids with an actual level >= than the skill level.
     */
    const getMatchingUserIds = (skill) => {
        return skill.actualLevels.filter(([userId, userLevel]) => userLevel >= skill.expectedLevel).map(([userId, userLevel]) => userId);
    }

    /**
     * Find the user level, within the skill actual levels, whose userId 
     * is passed in arguent.
     */
    const getMatchingUserLevel = (skill, userId) => {
        const actualEntry = skill.actualLevels.find(([uid, userLevel]) => uid === userId.id);
        return actualEntry && actualEntry.length > 1 ? actualEntry[1] : 0;
    }

    const toggleSelected = (user) => {
        user.selected = !user.selected;
    }

    const markAsVisible = (users) => {
        users.forEach(i => { i.visible = true });
    }

    const markAsSelected = (users, userIds = null) => {
        users.forEach(user => user.selected = user.visible && userIds ? userIds.includes(user.id) : true);
    }

    const resetSelection = (users) => {
        const isOneSelected = users.some(user => !user.selected);
        users.forEach(user => user.selected = user.visible && isOneSelected);
    }

    /**
     * Return all users of the current company with their age, score and mindset */
    const getUsers = async (company) => { 

        const users = await UserService.getUsers(company);
        initializeAgeRange(users);
        sortUsers(users, 'alpha-asc');

        return users;
    }

    /**
     * Get the data needed for the gender chart
     * Return a list of gender categories, with each category of the form { id, users, percent }
     */
    const getGenderData = (users) => {

        // Handle only selected users
        const selectedUsers = getSelected(users);

        // First, clear the category frequencies
        genderCategories.forEach(category => { category.users = []; });

        // Update the frequencies based on each selected user gender
        selectedUsers.forEach(user => {

            let category = null;

            switch (user.gender) {
                case 'M':
                    category = genderCategories[1];
                    break;
                case 'F':
                    category = genderCategories[2];
                    break;
                default:
                    category = genderCategories[0];
            }

            category.users.push(user);
        })

        return genderCategories.map(category => {
            return {
                id: category.id,
                display: category.name,
                users: category.users,
                percent: selectedUsers.length > 0 ? category.users.length / selectedUsers.length : 0
            }
        })
    }

    /**
     * Get the data needed for the age chart
     * Return a list of age categories, with each category of the form { id, users, percent }
     */

    const getAgeData = (users) => {

        // Handle only selected users
        const selectedUsers = getSelected(users);

        // First, clear the category frequencies
        ageCategories.forEach(category => { category.users = []; });

        // Update the frequencies based on each user age
        selectedUsers.forEach(user => {

            const age = user.age;
            let category = ageCategories.find(category => age >= category.low && age < category.high);

            // For missing age, take default one
            if (!category) category = ageCategories[0];

            // Update category frequency
            category.users.push(user);
        })

        return ageCategories.map(category => {
            return {
                id: category.id,
                display: category.name,
                users: category.users,
                percent: selectedUsers.length > 0 ? category.users.length / selectedUsers.length : 0,
            }
        })
    }

    /**
     * Get the data for the mindset chart
     * 
     * Return a list of mindset categories
     * 
     *      each category respect the form { id, percent, display }
     *      and id included in [ NA, 00, 20, 40, 60, 80 ].
     */
    const getMindsetData = (users) => {

        // First, clear frequencies
        mindsetCategories.forEach(category => {
            category.percent = 0;
            category.frequency = 0;
        });

        // Update the frequencies based on each user score
        const selectedUsers = getSelected(users);

        // Scan each user
        for (const user of selectedUsers) {

            const score = user.mindsetScore / 100;

            // Retrieve the mindset category containing the score
            let mindsetCategory = mindsetCategories.find(category => score >= category.low && score < category.high);

            // For missing score, take default one
            if (!mindsetCategory) mindsetCategory = mindsetCategories[0]

            // Update category frequency
            mindsetCategory.frequency += 1;
        }

        // Calculate the absolute class percentages
        for (const category of mindsetCategories) {
            category.percent = selectedUsers.length > 0 ? category.frequency / selectedUsers.length : 0;
        }

        return mindsetCategories.map(category => {
            return {
                id: category.id,
                display: category.name,
                users: category.users,
                percent: category.percent,
            }
        })
    }

    const initializeAgeRange = (users) => {

        for (const user of users) {

            // Get user's age and category
            const userAge = DateUtil.getAge(user.birthdate);
            const ageCategory = ageCategories.find(category => userAge >= category.low && userAge < category.high);

            // And initialize the user 
            user.age = userAge;
            user.ageRange = (ageCategory ?? ageCategories[0]).id;
        }
    }
    /**
     * Sort the users by the specified criteria (sortType)
     * Sort type can be one of: 
     * 
     *      alpha-asc
     *      alpha-desc
     *      score-asc
     *      score-desc
     *      mindset-asc
     *      mindset-desc
     */
    const sortUsers = (users, sortType) => {

        switch (sortType) {
            default:
            case 'alpha-asc':
                users.sort((a, b) => {
                    const fullnameA = a.firstname + a.lastname;
                    const fullnameB = b.firstname + b.lastname;
                    if (fullnameA < fullnameB) return -1;
                    else if (fullnameA > fullnameB) return 1;
                    return 0;
                })
                break;
            case 'alpha-desc':
                users.sort((a, b) => {
                    const fullnameA = a.firstname + a.lastname;
                    const fullnameB = b.firstname + b.lastname;
                    if (fullnameA > fullnameB) return -1;
                    else if (fullnameA < fullnameB) return 1;
                    return 0;
                })
                break;
            case 'score-asc':
                users.sort((a, b) => b.matchingScore - a.matchingScore);
                break;
            case 'score-desc':
                users.sort((a, b) => a.matchingScore - b.matchingScore);
                break;
            case 'mindset-asc':
                users.sort((a, b) => a.mindsetScore - b.mindsetScore);
                break;
            case 'mindset-desc':
                users.sort((a, b) => b.mindsetScore - a.mindsetScore);
                break;
        }
    }

    return {

        ageCategories,
        genderCategories,
        mindsetCategories,

        createFilters,
        createDefaultFilters,
        applyFilters,

        toggleSelected,
        markAsSelected,
        markAsVisible,
        resetSelection,

        getUsers,
        getVisibles,
        getSelected,
        getMatchingUserIds,
        getMatchingUserLevel,

        getAgeData,
        getGenderData,
        getMindsetData,

        sortUsers,
        initializeAgeRange
    }
}

export default InventoryLogic();