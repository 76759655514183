
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';
import FilterUtil from '../utils/FilterUtil.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/postypes`;

const PositionTypeService = () => {

    const getPositionTypes = async (company, filter) => {

        const positionTypes = company 
            ? (await axios.get(`${SERVER_URL}?company=${company.id}`)).data.payload
            : [];

        return FilterUtil.filter(positionTypes, filter);
    }

    const getPositionType = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const getTotalCount = async (company) => {
        return (await axios.get(`${SERVER_URL}/count?company=${company.id}`)).data.payload;
    }

    const create = async (company) => {
        return (await axios.get(`${SERVER_URL}/create?company=${company.id}`)).data.payload;
    }

    const isDeletable = async (positionType) => {
        return (await axios.get(`${SERVER_URL}/${positionType.id}/deletable`)).data.payload;
    }

    const save = async (positionType) => {

        return positionType.id
            ? (await axios.put(`${SERVER_URL}/${positionType.id}`, positionType)).data.payload
            : (await axios.post(`${SERVER_URL}`, positionType)).data.payload;
    }

    const remove = async (positionType) => {
        await axios.delete(`${SERVER_URL}/${positionType.id}`);
    }

    return {
        getPositionTypes,
        getPositionType,
        getTotalCount,
        create,
        isDeletable,
        save,
        remove
    }
}

export default PositionTypeService();