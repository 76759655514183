
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useSurveyController from '../../../controllers/useSurveyController.js';
import useCatalogController from '../../../controllers/useCatalogController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import SurveyLogic from '../../../logic/SurveyLogic.js';
import InventoryLogic from '../../../logic/InventoryLogic.js';

import FiltersCharts from '../../../components/charts/FiltersCharts.js';
import TabGapAnalysis from './TabGapAnalysis.js';
import TabMobilityDev from './TabMobilityDev.js';

import edit_img from '../../../assets/images/pen.svg';

const SurveyChart = () => {

    /**
     * Page state
     */

    const { auth } = useAuthContext();

    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    const [survey, setSurvey] = useState({});
    const [users, setUsers] = useState([]);
    const [skilltree, setSkillTree] = useState({});
    const [loaded, setLoaded] = useState(false);

    const surveyController = useSurveyController();
    const catalogController = useCatalogController();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {

            try {
                setLoaded(false);

                // Get the survey (or create a new one)
                const survey = await surveyController.getSurvey(id);

                // Get the survey catalog and the users who completed the survey
                const { users, catalog } = await catalogController.getSurveyInventoryCatalog(survey);

                // Initialize the users
                SurveyLogic.markAsVisible(users);
                SurveyLogic.markAsSelected(users);

                setSurvey(survey);
                setUsers(users);                
                setSkillTree(catalog);                
            }
            finally {
                setLoaded(true);
            }

        })()

        // eslint-disable-next-line 
    }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleFilterChange = async (filters) => {
        InventoryLogic.applyFilters(users, filters);
        setUsers([ ...users ]);
    }

    const handleFilterReset = () => {
        InventoryLogic.applyFilters(users, null);
        setUsers([ ...users ]);
    }

    const handleTreeChange = (skilltree) => {
        setSkillTree(skilltree);
    }   

    const handleSelectionChange = (users) => {
        setUsers(users);
    }

    const handleClose = () => {
        navigate('/manager/surveys');
    }

    const handleDetail = (survey) => {
        navigate('/manager/surveys/' + survey.id);
    }

    return (

        <div className='container-fluid'>

            <LoadingIcon loading={!loaded} />

            <div className='row p-4'>

                <div className='d-flex align-items-center justify-content-between'>
                    <h5 className='text-muted'>{t('charts.title')} - {survey.name}</h5>
                    <button className='btn btn-light' onClick={() => handleDetail(survey)}><img src={edit_img} alt='detail' /></button>
                </div>

                <hr />

                <ul className='nav nav-tabs'>
                    <li><button className='nav-link active' data-bs-toggle='tab' data-bs-target='#tab0' type='button'>{t('charts.gap-analysis')}</button></li>
                    <li><button className='nav-link' data-bs-toggle='tab' data-bs-target='#tab1' type='button'>{t('charts.mobility-development')}</button></li>
                </ul>

                <div className='tab-content py-4'>

                    <div>
                        <FiltersCharts onChange={handleFilterChange} onReset={handleFilterReset}/>
                    </div>
                    
                    <div id='tab0' className='tab-pane fade show active'>
                        <TabGapAnalysis skilltree={skilltree} users={users} onTreeChange={handleTreeChange} onSelectionChange={handleSelectionChange}  />
                    </div>                    

                    <div id='tab1' className='tab-pane fade'>
                        <TabMobilityDev skilltree={skilltree} users={users} onTreeChange={handleTreeChange} />
                    </div> 

                </div>

            </div>

            <div className='p-4'>

                <hr />
                
                <div className='d-flex justify-content-end w-100'>
                    <button className='btn btn-light' onClick={handleClose}>{t('button.close')}</button>
                </div>

            </div>

        </div>
    )
}

export default SurveyChart;