
/**
 * Utility class with global and generic date functions
 * 
 * Note: this class is the exact same on backend and frontend
 */

const DateUtil = () => {

    const SECONDS_IN_DAY = 24 * 60 * 60;
    const MISSING_LEAP_YEAR_DAY = SECONDS_IN_DAY * 1000;
    const MAGIC_NUMBER_OF_DAYS = (25567 + 2);

    /**
     * Convert an excel date (a number from epoch) to a javascript date
     */
    const fromExcelDate = (excelDate) => {
        
        if (excelDate) {
            return new Date( (excelDate - MAGIC_NUMBER_OF_DAYS) * MISSING_LEAP_YEAR_DAY );
        }

        return null;
    }

    /**
     * Convert a string into a date
     */
    const fromString = (date, format = 'dd.MM.yyyy') => {

        // Normalize the date
        const normalizedDate   = date.replace(/[^a-zA-Z0-9]/g, '-');
        const dateItems       = normalizedDate.split('-');

        // Normalize the format
        const normalizedFormat = format.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-');
        const formatItems     = normalizedFormat.split('-');
      
        var monthIndex    = formatItems.indexOf("MM");
        var dayIndex      = formatItems.indexOf("dd");
        var yearIndex     = formatItems.indexOf("yyyy");
        var hourIndex     = formatItems.indexOf("hh");
        var minutesIndex  = formatItems.indexOf("mm");
        var secondsIndex  = formatItems.indexOf("ss");
      
        var today = new Date();
      
        var year  = yearIndex > -1  ? dateItems[yearIndex]    : today.getFullYear();
        var month = monthIndex > -1 ? dateItems[monthIndex]-1 : today.getMonth()-1;
        var day   = dayIndex> -1    ? dateItems[dayIndex]     : today.getDate();
      
        var hour    = hourIndex > -1      ? dateItems[hourIndex]    : today.getHours();
        var minute  = minutesIndex > -1   ? dateItems[minutesIndex] : today.getMinutes();
        var second  = secondsIndex > -1   ? dateItems[secondsIndex] : today.getSeconds();
      
        return new Date(year, month, day, hour, minute, second);        
    }

    /**
     * Convert a date into local string
     */
    const toLocaleString = (date) => {
        return date ? new Date(date).toLocaleDateString() : '-';
    }

    /**
     * Convert a date into formatted as a string 'yyyy-MM-dd'
     */
    const toIsoString = (date) => {

        if (!date) return '';
        
        const padTo2Digits = (num) => {
            return num.toString().padStart(2, '0');
        }
        
        const newDate = new Date(date);

        return [
            newDate.getFullYear(),
            padTo2Digits(newDate.getMonth() + 1),
            padTo2Digits(newDate.getDate()),
        ].join('-');
    }

    const getAge = (date) => {
        
        let age;

        if(!date){
            age = 'N/A';
        }
        else {
            age = 0;
            let today = new Date();
            let birthDate = new Date(date);
    
            age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
        }

        return age;
    }

    const isValid = (text) => {
        return !!new Date(text).getDate();
    }

    return {
        toLocaleString,
        toIsoString,
        fromString,
        fromExcelDate,
        isValid,
        getAge
    }
}

export default DateUtil();