
import config from '../../package.json';

const AppLogic = () => {

    const REACT_APP_AXIOS_REQUEST = process.env.REACT_APP_AXIOS_REQUEST ?? false;

    const version = config.version;
    const serverUrl = config.serverUrl;
    const traceRequests = REACT_APP_AXIOS_REQUEST;

    return {
        version,
        serverUrl,
        traceRequests
    }
}

export default AppLogic();

