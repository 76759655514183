
import { useTranslation } from 'react-i18next';
import CatalogLogic from '../../logic/CatalogLogic.js';

import expected_img from '../../assets/images/expected-skill.svg';
import actual_img from '../../assets/images/actual-skill.svg';
import expected_actual_img from '../../assets/images/expected-actual-skill.svg';

import './Views.css';

const LanguageView = ({ category, showExpectedValues }) => {

    /**
     * Page state
     */
    const { t } = useTranslation();
    const labels = t('common.language-view-labels').slice(1);

    return category && category.children?.map(skill => (

        <div key={skill.id} className='container mb-3'>

            <table className='table table-hover'>
                <thead>
                    <tr className='table-header'>
                        <th className='table-text-normal text-start'>{skill.label}</th>
                        {
                            showExpectedValues && 
                            <th>{t('mycv.differential-level')}</th>
                        }
                        {
                            labels.map((label, i) => <th key={i} className='table-header'>{label}</th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        skill.children?.map((skill, i) => {

                            const isLeaf        = CatalogLogic.isLeaf(skill);
                            const actualLevel   = CatalogLogic.getSkillLevel(category, skill);
                            const expectedLevel = CatalogLogic.getSkillExpectedLevel(category, skill);
                            const gap           = actualLevel - expectedLevel;

                            return (
                                <tr key={i} className='table-row'>
                                    <td className='text-start ps-4'>{skill.label}</td>
                                    {
                                        showExpectedValues && isLeaf && 
                                        <td className='text-center'>
                                            {
                                                !skill.level && 
                                                <span className='badge rounded-circle bg-warning'>!</span>
                                            }
                                            {
                                                skill.level && gap < 0 && 
                                                <span className='badge rounded-circle bg-danger'>{Math.abs(gap)}</span>
                                            }
                                            {
                                                skill.level && gap === 0 && <span>-</span>
                                            }
                                            {
                                                skill.level && gap > 0 && 
                                                <span className='badge rounded-circle bg-success'>{Math.abs(gap)}</span>
                                            }
                                        </td>
                                    }
                                    {
                                        labels.map((l, j) => (
                                            <td key={j}>
                                                {
                                                    showExpectedValues && expectedLevel - 1 === j && expectedLevel !== actualLevel && 
                                                    <img src={expected_img} className='skill-icon' alt='expected' />
                                                }
                                                {
                                                    showExpectedValues && actualLevel - 1 === j && expectedLevel!== actualLevel && 
                                                    <img src={actual_img} className='skill-icon' alt='actual' />
                                                }
                                                {
                                                    showExpectedValues && actualLevel - 1 === j && expectedLevel === actualLevel && 
                                                    <img src={expected_actual_img} className='skill-icon' alt='expected + actual' />
                                                }
                                                {
                                                    !showExpectedValues && actualLevel - 1 === j && 
                                                    <img src={actual_img} className='skill-icon' alt='actual' />
                                                }
                                            </td>
                                        ))
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

        </div>
    ))
}

export default LanguageView;
