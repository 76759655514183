
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useSectorController from '../../../controllers/useSectorController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import SearchBar from '../../../components/filter/SearchBar.js';
import SearchCount from '../../../components/filter/SearchCount.js';
import SortableHeader from '../../../components/sortable/SortableHeader.js';
import SectorDetail from './SectorDetail.js';
import SectorDelete from './SectorDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const Sectors = () => {

    const { t } = useTranslation();

    const defaultFilter = {
        attributes: [
            { name: 'code', display: t('sectors.code') },
            { name: 'name', display: t('sectors.name') }
        ]
    }

    /**
     * Page state
     */

    const { auth } = useAuthContext();

    const [filter, setFilter] = useState(defaultFilter);
    const [sector, setSector] = useState({});
    
    const sectorController = useSectorController(filter);

    /**
     * Page lifecycle
     */

    // eslint-disable-next-line 
    useEffect(() => { sectorController.reload() }, [filter]);

    // eslint-disable-next-line 
    useEffect(() => { sectorController.retranslate() }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const sector = await sectorController.create();
        setSector(sector);
    }

    const handleDetail = (sector) => {
        setSector(sector);
    }

    const handleSave = async (sector) => {
        await sectorController.save(sector);
    }

    const handleDelete = async (sector) => {
        await sectorController.remove(sector);
    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!sectorController.loaded} />
            <SectorDetail id='detail' sector={sector} onSave={handleSave} />
            <SectorDelete id='delete' sector={sector} onDelete={handleDelete} />

            <div className='row p-4'>

                <div className='d-flex align-items-center'>
                    <h5 className='text-muted mb-1'>{t('glossary.sectors')}</h5>
                    <SearchCount className='text-muted mb-1 ms-2' count={sectorController.sectors.length} total={sectorController.totalCount} />
                    <SearchBar className='ms-4' items={sectorController.sectors} filter={filter} onSearch={handleFilter} />
                </div>

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>
                                <SortableHeader attrName='code' filter={filter} onFilter={handleFilter}>
                                    {t('sectors.code')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='name' filter={filter} onFilter={handleFilter}>
                                    {t('sectors.name')}
                                </SortableHeader>
                            </th>
                            <th className='text-center'>
                                <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sectorController.sectors.map((sector) => (
                                <tr key={sector.id} className='align-middle'>
                                    <td>{sector.code}</td>
                                    <td>{sector.name}</td>
                                    <td className='text-center'><button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={() => handleDetail(sector)}><img src={edit_img} alt='edit' /></button></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>

        </div>
    )
}

export default Sectors
