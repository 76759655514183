
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthenticationController from '../../controllers/useAuthenticationController.js';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import LoginLogic from '../../logic/LoginLogic.js';

import './Login.css';

const Forgot = () => {

    const { t, i18n } = useTranslation();
    const [ queryParams ] = useSearchParams();
    const navigate = useNavigate();

    const controller = useAuthenticationController();
    
    /**
     * Page state
     */

    const [step, setStep] = useState('start');
    const [email, setEmail] = useState('');
    const [invalid, setInvalid] = useState(true);

    /**
     * Page lifecycle
     */

    useEffect(() => {

        const lang = queryParams.get('lang') ?? 'en';
        i18n.changeLanguage(lang);

        // eslint-disable-next-line 
    }, [queryParams, navigate]);

    /**
     * Event handlers
     */

    const handleChange = (e) => {

        const email = e.target.value;
        const invalid = !LoginLogic.isEmailValid(email);

        setEmail(email);
        setInvalid(invalid);
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        e.stopPropagation();

        if (step === 'start') {

            try {
                await controller.resetPassword(email);
                setStep('done');
            }
            catch (e) {
                console.error(e);
                navigate('/login', { replace: true });
            }
        }
        else if (step === 'done') {
            navigate('/login', { replace: true });
        }
    }

    return (
        <div className='container d-flex justify-content-center my-5'>
            <form className='login-form' onSubmit={handleSubmit} noValidate>

                <div className='text-center mb-5'>
                    <h3>{t('forgot.title')}</h3>
                </div>

                {
                    step === 'start' &&
                    <fieldset>

                        <div className='form-group mb-3'>
                            <input type='email' className='form-control' placeholder={t('login.username') + ' *'} value={email} autoComplete='off' autoFocus onChange={handleChange} required />
                        </div>

                        <div className='form-group pt-4 mb-5 d-flex justify-content-center'>
                            <button type='submit' className='btn btn-primary btn-login' disabled={invalid}>{t('button.send')}</button>
                        </div>

                        <div className='form-group'>
                            <Link to='/login' className='link'>&larr; {t('button.back')}</Link>
                        </div>

                    </fieldset>
                }
                {
                    step === 'done' &&
                    <fieldset>

                        <div className='form-group mb-3'>
                            {t('forgot.message')}

                        </div>
                        <div className='form-group pt-4 mb-5 d-flex justify-content-center'>
                            <button type='submit' className='btn btn-primary btn-login'>{t('button.login')}</button>
                        </div>

                    </fieldset>
                }

            </form >
        </div >
    )

}

export default Forgot;
