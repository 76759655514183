
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

import en from './assets/translations/en.json';
import fr from './assets/translations/fr.json';
import de from './assets/translations/en.json';

// Supported languages
const supportedLanguages = ['en', 'fr', 'de'];

i18n
    // .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        debug: false,
        lng: 'en',
        fallbackLng: 'en',
        supportedLngs: supportedLanguages,
        returnObjects: true,
        resources: {
            en: { translation: en },
            fr: { translation: fr },
            de: { translation: de }
        },
    })

// i18n helper
i18n.supportedLanguages = supportedLanguages;