
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import { atom, useAtom } from 'jotai';
import useCountryController from './useCountryController.js';
import CompanyService from '../api/CompanyService.js';

// Global state (jotai atom)
const companiesAtom = atom([]);

const useCompanyController = (filter) => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();
    const countryController = useCountryController();
    const [companies, setCompanies] = useAtom(companiesAtom);
    const [loaded, setLoaded] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    /**
     * Functionalities
     */

    const reload = async () => {

        try {
            setLoaded(false);

            const companies = await CompanyService.getCompanies(filter);
            companies.forEach(company => translate(company));
            setCompanies(companies);

            const total = await CompanyService.getTotalCount();
            setTotalCount(total);
        }
        finally {
            setLoaded(true);
        }
    }

    const deletable = async (company) => {
        return company.id != null && await CompanyService.isDeletable(company);
    }

    const create = async () => {

        const company = await CompanyService.create();
        translate(company);
        return company;
    }
    
    const getCompany = async (companyId) => {
        const company = await CompanyService.getCompany(companyId);
        translate(company);
        return company;
    }

    const save = async (company) => {

        try {
            untranslate(company);
            await CompanyService.save(company);
        }
        finally {
            translate(company);
        }

        reload();
    }

    const remove = async (company) => {
        await CompanyService.remove(company);
        reload();
    }

    const translate = (company) => {
        company.country && countryController.translate(company.country, auth.lang);
    }

    const untranslate = (company) => {
        company.country && countryController.untranslate(company.country);
    }

    const retranslate = () => {

        companies.forEach(company => {
            untranslate(company);
            translate(company);
        });

        setCompanies([...companies]);
    }

    return {
        companies,
        totalCount,
        loaded,
        reload,
        deletable,
        create,
        getCompany,
        save,
        remove,
        translate,
        untranslate,
        retranslate,
    }
}

export default useCompanyController;