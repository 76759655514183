
import { useState, createContext, useContext } from 'react';

// Global state
const Context = createContext({});

export const AuthContext = ({ children }) => {

    const [auth, setAuth] = useState({}); // { authenticated, token, user, lang, company }

    return <Context.Provider value={{auth, setAuth}}>
        {children}
    </Context.Provider>
}

export const useAuthContext = () => {
    return useContext(Context);
}
