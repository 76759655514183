
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMotionController from '../../../controllers/useMotionController.js';

import WishesView from '../../../components/radar/WishesView.js';
import AxesView from '../../../components/radar/AxesView.js';
import Radar from '../../../components/radar/Radar.js';
import MatchingTable from '../../../components/radar/MatchingTable.js';
import MotionView from '../../../components/radar/MotionView.js';

import table_img from '../../../assets/images/list.svg';
import radar_img from '../../../assets/images/map.svg';
import filter_on_img from '../../../assets/images/filter-on.svg';
import filter_off_img from '../../../assets/images/filter-off.svg';
import reset_img from '../../../assets/images/sync.svg';
import axes_img from '../../../assets/images/axes.svg';

import './TabMap.css';

const TabMap = ({ motion, wishes, axes, onChange }) => {

    /**
     * Page state
     */

    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [matchings, setMatchings] = useState([]);
    const [hlabel, setHLabel] = useState('');
    const [vlabel, setVLabel] = useState('');

    const [wishesInitialized, setWishesInitialized] = useState(false);
    const [resetRadarCallback, setResetRadarCallback] = useState();

    const [showFilter, setShowFilter] = useState(true);
    const [showRadar, setShowRadar] = useState(true);
    const [showTable, setShowTable] = useState(false);
    const [showProfile, setShowProfile] = useState(false);

    const motionController = useMotionController();

    /**
     * Page lifecycle
     */

    // First time, reset radar scale + position (a few ms after flex layout is completed)
    useEffect(() => { wishesInitialized && resetRadarCallback && setTimeout(() => resetRadarCallback(), 200) }, [wishesInitialized, resetRadarCallback]);

    // Each time the axes change, reevaluate the axes labels
    useEffect(() => {

        const xaxis = motion.categories?.find(w => w.id === axes.x);
        const yaxis = motion.categories?.find(w => w.id === axes.y);

        setHLabel(xaxis?.label ?? 'n/a');
        setVLabel(yaxis?.label ?? 'n/a');

        // eslint-disable-next-line
    }, [axes])

    // Each time the wishes change, reevaluate the matchings
    useEffect(() => {

        (async () => {

            try {
                if (motion.id) {

                    const matchings = await motionController.getMatchingsForMotion(motion, wishes);
                    setMatchings(matchings);
                }
            }
            catch (e) {
                console.log(e);
            }

        })();

        // eslint-disable-next-line
    }, [wishes]);

    /**
     * Event handlers
     */

    const handleWishesInit = () => {
        setWishesInitialized(true);
    }

    const handleRadarInit = (callback) => {
        setResetRadarCallback(() => callback); // useState cannot store function directly
    }

    const handleReset = () => {
        resetRadarCallback && resetRadarCallback(); // radar reset scale + position
    }

    const handleChange = (e) => {
        onChange(e);
    }

    const handleShowProfile = (user) => {
        setUser(user);
        setShowProfile(true);
    }

    const handleHideProfile = () => {
        setShowProfile(false);
    }

    const handleToogle = () => {
        setShowRadar(!showRadar);
        setShowTable(!showTable);
    }

    const handleFilter = () => {
        setShowFilter(!showFilter);
    }

    const handleCurriculum = (user) => {
        setShowProfile(false);
        navigate(`/manager/motions/${motion.id}/cv/${user.id}`);
    }

    return (

        <div id='tabmap' className='d-flex'>

                <AxesView id='axes' axes={axes} categories={motion.categories} onChange={(a) => handleChange({ axes: a })} />
                <MotionView show={showProfile} user={user} onCurriculum={handleCurriculum} onClose={handleHideProfile} /> 

                <div id='wishes' className={'border rounded p-2 me-3 collapse collapse-horizontal show'}>
                    <WishesView wishes={wishes} onInit={handleWishesInit} onChange={handleChange} />
                </div>

                <div className='border rounded p-2 flex-fill'>

                    <div id='radar-toolbar' className='btn-group'>
                        <button className='btn btn-light' onClick={handleToogle}><img src={showRadar ? table_img : radar_img} alt='radar' /></button>
                        <button className='btn btn-light' data-bs-toggle='collapse' data-bs-target='#wishes' onClick={handleFilter}><img src={showFilter ? filter_off_img : filter_on_img} alt='filter' /></button>
                        <button className='btn btn-light' disabled={!showRadar} onClick={handleReset}><img src={reset_img} alt='reset' /></button>
                        <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#axes'><img src={axes_img} alt='axes' /></button>
                    </div>

                    { showRadar && <Radar entity={motion} axes={axes} matchings={matchings} hlabel={hlabel} vlabel={vlabel} onInit={handleRadarInit} onSelect={handleShowProfile} /> }
                    { showTable && <MatchingTable motion={motion} axes={axes} matchings={matchings} /> }

                </div>

        </div>
    )
}

export default TabMap;
