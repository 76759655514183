
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import SectorService from '../api/SectorService.js';
import LangUtil from "../utils/LangUtil.js";

const useSectorController = (filter) => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();

    const [sectors, setSectors] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    /**
     * Functionalities
     */

    const reload = async () => {

        try {
            setLoaded(false);
            const sectors = await SectorService.getSectors(filter);
            sectors.forEach(s => translate(s));
            setSectors(sectors);

            const totalCount = await SectorService.getTotalCount();
            setTotalCount(totalCount);
        }
        finally {
            setLoaded(true);
        }
    }

    const deletable = async (sector) => {
        return sector.id != null && await SectorService.isDeletable(sector);
    }

    const create = async () => {
        const sector = await SectorService.create();
        translate(sector);
        return sector;
    }

    const save = async (sector) => {

        try {
            untranslate(sector);
            await SectorService.save(sector);
        }
        finally {
            translate(sector);
        }

        reload();
    }

    const remove = async (sector) => {
        await SectorService.remove(sector);
        reload();
    }

    const translate = (sector) => {
        LangUtil.translate(sector, 'name', auth.lang);
    }

    const untranslate = (sector) => {
        LangUtil.untranslate(sector, 'name');
    }

    const retranslate = () => {

        sectors.forEach(sector => {
            untranslate(sector);
            translate(sector);
        });

        setSectors([...sectors]);
    }

    return {
        sectors,
        totalCount,
        loaded,
        reload,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate
    }
}

export default useSectorController;