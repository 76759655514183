
import { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../controllers/useAuthContext.js';
import useTooltips from '../../hooks/useTooltips.js';

import StringUtil from "../../utils/StringUtil.js";
import TreeTable from '../treetable/TreeTable.js';
import CatalogSelectorLogic from '../../logic/CatalogSelectorLogic.js';
import CatalogLogic from "../../logic/CatalogLogic.js";

import './CatalogSelector.css';

const CatalogSelector = ({ source, target, sourceTitle, targetTitle, targetEditable = true, onSourceChange, onTargetChange, onImport, onExport, onReset, onSave, children }) => {

    targetEditable = (typeof targetEditable === 'string') ? targetEditable === 'true' : targetEditable;

    /**
     * Page state
     */

    const importRef = useRef();
    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const [dirty, setDirty] = useState(false);
    const [sourceTree, setSourceTree] = useState({});
    const [targetTree, setTargetTree] = useState({});

    /**
     * Page lifecycle§
     */

    useTooltips();

    useEffect(() => {

        // Synchronize all node states
        CatalogSelectorLogic.syncStates(source, target);

        setSourceTree({ ...source, state: { opened: true } });
        setTargetTree({ ...target, state: { opened: true } });

    }, [source, target]);

    /**
     * Event handlers
     */

    const handleToggle = (e) => {
        setDirty(true);
        CatalogSelectorLogic.toggleSourceNode(sourceTree, targetTree, e.target.id);
        onSourceChange && onSourceChange(sourceTree);
        onTargetChange && onTargetChange(targetTree);
    }

    const handleNew = (node) => {
        node.label = t('glossary.new');
        node.label_ = { lang: auth.lang };
        node.state = { ...node.state, origin: 'target' };
    }

    const handleDelete = (newTargetTree, node) => {
        CatalogSelectorLogic.deleteTargetNode(sourceTree, newTargetTree, node.id);
        onSourceChange && onSourceChange(sourceTree);
    }

    const handleSourceChange = (data) => {
        setDirty(true);
        onSourceChange && onSourceChange(data);
    }

    const handleTargetChange = (data) => {
        setDirty(true);
        onTargetChange && onTargetChange(data);
    }

    const handleImport1 = () => {
        importRef.current?.click();
    }
    
    const handleImport2 = (e) => {
        e.target.files?.length > 0 && onImport(e.target.files[0]);
    }

    const handleExport = () => {
        onExport();
    }

    const handleReset = () => {
        setDirty(false);
        onReset();
    }

    const handleSave = () => {
        setDirty(false);
        onSave();
    }

    return (

        <div className='row'>

            <div className='col'>

                <h6 className='d-flex justify-content-between align-items-center'>
                    {
                        sourceTitle && <strong>{sourceTitle}</strong>
                    }
                    {
                        onImport &&
                        <div>
                            <input type='file' ref={importRef} className='btn btn-light' accept='application/json' style={{ display: 'none' }} onChange={handleImport2} />
                            <button className='btn btn-light' onClick={handleImport1}>{t('button.import')}</button>
                        </div>
                    }
                </h6>

                <TreeTable className='table table-hover border' data={sourceTree} editable='false' onChange={handleSourceChange} >
                    <tbody>
                        {
                            (skill) => {

                                const hasTooltip = !StringUtil.isEmpty(skill.description);

                                return (
                                    <tr>
                                        <td>
                                            {
                                                hasTooltip &&
                                                <div data-bs-toggle='tooltip' data-bs-title={skill.description} data-bs-placement='right' data-bs-html='true'>{skill.label}</div>
                                            }
                                            {
                                                !hasTooltip &&
                                                skill.label
                                            }
                                        </td>
                                        <td>
                                            {
                                                CatalogLogic.isNotRoot(skill) &&
                                                <input
                                                    id={skill.id}
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    checked={skill.state.selected === true || skill.state.selected === 'indeterminate'}
                                                    ref={domElmt => { if (domElmt) domElmt.indeterminate = skill.state.selected === 'indeterminate' }}
                                                    onChange={handleToggle}
                                                />
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                        }
                    </tbody>
                </TreeTable>

            </div>

            <div className='col'>

                <h6 className='d-flex justify-content-between align-items-center'>
                    {
                        targetTitle && <strong>{targetTitle}</strong>
                    }
                    <div className='btn-group'>
                        {onExport && <button className='btn btn-light' onClick={handleExport} disabled={dirty}>{t('button.export')}</button>}
                        {onReset && <button className='btn btn-light' onClick={handleReset} disabled={!dirty}>{t('button.reset')}</button>}
                        {onSave && <button className='btn btn-light' onClick={handleSave} disabled={!dirty}>{t('button.save')}</button>}
                    </div>
                </h6>

                <TreeTable className='table table-hover border' data={targetTree} editable={targetEditable} onChange={handleTargetChange} onNew={handleNew} onDelete={handleDelete} >
                    <tbody>
                        {
                            (skill) => {

                                const hasTooltip = !StringUtil.isEmpty(skill.description);
                                const colorClass = skill.state.origin === 'target' ? 'node-origin-target' : 'node-origin-source';

                                // If children is present, it should be a function of the form: (skill) => { ... }
                                if (children) {
                                    return children(skill);
                                }
                                else {

                                    // Otherwise, display current skill
                                    return (
                                        <tr>
                                            <td className={colorClass}>
                                                {
                                                    hasTooltip &&
                                                    <div data-bs-toggle='tooltip' data-bs-title={skill.description} data-bs-placement='right' data-bs-html='true'>{skill.label}</div>
                                                }
                                                {
                                                    !hasTooltip &&
                                                    skill.label
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                            }
                        }
                    </tbody>
                </TreeTable>

            </div>

        </div>
    )
}

export default CatalogSelector;
