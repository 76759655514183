
import { useTranslation } from 'react-i18next';
import DateUtil from '../../utils/DateUtil.js';

const ExperiencesView = ({ experiences }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();

    return (

        <table className='table table-hover table-sm'>
            <thead>
            <tr className='table-header text-start'>
                    <th>{t('experiences.start')}</th>
                    <th>{t('experiences.end')}</th>
                    <th>{t('experiences.position')}</th>
                    <th>{t('experiences.sector')}</th>
                    <th>{t('experiences.company')}</th>
                </tr>
            </thead>
            <tbody>
                {
                    experiences.map((experience) => (
                        <tr key={experience.id} className='table-row text-start'>
                            <td>{DateUtil.toLocaleString(experience.start) ?? '-'}</td>
                            <td>{DateUtil.toLocaleString(experience.end) ?? '-'}</td>
                            <td>{experience.positionTitle ?? '-'}</td>
                            <td>{experience.sector?.name ?? '-'}</td>
                            <td>{experience.company ?? '-'}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}

export default ExperiencesView
