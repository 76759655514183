
import i18next from 'i18next';

const InvitationLogic = () => {

    const markAsVisible = (invitations) => {
        invitations.forEach(i => { i.visible = true });
    }

    const markAsHidden = (invitations) => {
        invitations.forEach(i => { i.visible = false });
    }

    const markAsDeleted = (invitationsToDelete) => {
        invitationsToDelete.forEach(i => { i.visible = false; i.deleted = true; i.selected = false; });
    }

    const toggleSelected = (invitation) => {
        invitation.selected = !invitation.selected;
    }

    const markAsSelected = (invitations) => {
        for (const invitation of invitations) invitation.selected = true;
    }

    const markAsUnselected = (invitations) => {
        for (const invitation of invitations) invitation.selected = false;
    }

    const isAllSelected = (invitations) => {
        return invitations.length > 0 && !invitations.some(i => !i.selected);
    }

    const isOneSelected = (invitations) => {
        return invitations.length > 0 && invitations.some(i => i.selected);
    }

    const getSelected = (invitations) => {
        return invitations.filter(i => i.selected);
    }

    const getVisibles = (invitations) => {
        return invitations ? invitations.filter(i => i.visible && !i.deleted) : [];
    }

    const filterNewInvitations = (survey, invitations) => {

        // Build a map of existing invitations userIds
        const userIdMap = survey.invitations
            ? new Map(survey.invitations.map(i => { return [i.user.id, i.user.id] }))
            : new Map();

        // And keep only the invitations not already in the survey
        return invitations.filter(invitation => !userIdMap.has(invitation.user.id));
    }

    /**
     * Create all steps from a user invitation
     */
    const createSteps = (invitation) => {

        const firststep = { id: 'x', type: 'start', label: i18next.t('invitations.start') };
        const mindstep  = { id: 'y', type: 'mindset', label: i18next.t('surveys.mindset') };
        const laststep  = { id: 'z', type: 'end', label: i18next.t('invitations.end') };

        return invitation.survey.mindset
            ? [firststep, ...invitation.catalog.children ?? [], mindstep, laststep]
            : [firststep, ...invitation.catalog.children ?? [], laststep];
    }

    return {
        createSteps,
        filterNewInvitations,
        toggleSelected,
        markAsSelected,
        markAsUnselected,
        markAsHidden,
        markAsVisible,
        markAsDeleted,
        isAllSelected,
        isOneSelected,
        getVisibles,
        getSelected,
    }
}

export default InvitationLogic();