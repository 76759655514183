
import { useTranslation } from 'react-i18next';
import TreeTable from '../treetable/TreeTable.js';
import InventoryLogic from '../../logic/InventoryLogic.js';

import './GapAnalysisView.css';

const GapAnalysisView = ({ skilltree, users, onTreeChange, onSelectionChange }) => {

    const { t } = useTranslation();
    const selectedUsers = InventoryLogic.getSelected(users);

    /**
     * Event handlers
     */

    const handleSelectionChange = (users) => {
        onSelectionChange(users.map(user => user.id));
    }

    return (
        <TreeTable className='skilltree-analysis table table-sm' data={skilltree} onChange={(skilltree) => onTreeChange(skilltree)}>
            <thead>
                <tr>
                    <th></th>
                    <th className='text-center'>
                        <div className='legends'>
                            <span>{t('charts.opportunity')}</span><span className='ps-3'>{t('charts.risk')}</span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    (skill) => {

                        // Extract userId with actualLevel > expectedLevel
                        const userIds = InventoryLogic.getMatchingUserIds(skill);
                        const matchingUsers   = selectedUsers.filter(user => userIds.includes(user.id));
                        const unmatchingUsers = selectedUsers.filter(user => !userIds.includes(user.id));
                        
                        const widthLeft  = matchingUsers.length / selectedUsers.length * 100.0; 
                        const widthRight = unmatchingUsers.length / selectedUsers.length * 100.0;
                        const isBarchartVisible = selectedUsers.length > 0 && (!skill.children || skill.children.length < 1);

                        return (
                            <tr>
                                <td>
                                    {skill.label ?? '-'}
                                </td>
                                <td>
                                    <div className='barchart'>

                                        {
                                            isBarchartVisible && widthLeft > 0 &&
                                            <div className='left' style={{ width: widthLeft + '%' }} onClick={() => handleSelectionChange(matchingUsers)}> 
                                                { matchingUsers.length > 0 ? widthLeft.toFixed() + '%' : '' }
                                            </div>
                                        }

                                        {
                                            isBarchartVisible && widthRight > 0 &&
                                            <div className='right' style={{ width: widthRight + '%' }} onClick={() => handleSelectionChange(unmatchingUsers)}>
                                                { unmatchingUsers.length > 0 ? widthRight.toFixed() + '%' : '' }
                                            </div>
                                        }
                                    
                                    </div>

                                </td>
                            </tr>
                        )
                    }
                }
            </tbody>
        </TreeTable>
    )
}

export default GapAnalysisView;