
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useCatalogController from '../../../controllers/useCatalogController.js';

import InventoryLogic from '../../../logic/InventoryLogic.js';
import FiltersCharts from '../../../components/charts/FiltersCharts.js';
import InventoryView from './InventoryView.js';
import InfoBox from '../../../components/charts/InfoBox.js';
import UserListMultiSelect from '../../../components/charts/UserListMultiSelect.js';
import GenderChart from '../../../components/charts/GenderChart.js';
import AgeChart from '../../../components/charts/AgeChart.js';

const Inventory = () => {

    /**
     * Page state
     */

    const { auth } = useAuthContext();

    const { t } = useTranslation();
    const [ users, setUsers ] = useState([]);
    const [ skilltree, setSkillTree ] = useState({});
    
    const catalogController = useCatalogController();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {

            // Get the company catalog and the users registered within the company
            const { users, catalog } = await catalogController.getCompanyInventoryCatalog(auth.company);
            
            InventoryLogic.markAsVisible(users);
            InventoryLogic.markAsSelected(users);
            
            setUsers(users);
            setSkillTree(catalog);

        })()

    // eslint-disable-next-line 
    }, [auth.company, auth.lang])

    /**
     * Event handlers
     */

    const handleFilters = async (filters) => {
        InventoryLogic.applyFilters(users, filters);
        setUsers([ ...users ]);
    }

    const handleFiltersReset = () => {
        InventoryLogic.applyFilters(users, null);
        setUsers([ ...users ]);
    }

    const handleReset = () => {
        InventoryLogic.resetSelection(users);
        setUsers([ ...users ]);
    } 

    const handleToggle = (user) => {
        InventoryLogic.toggleSelected(user);
        setUsers([ ...users ]);
    }

    const handleTreeChange = (skilltree) => {
        setSkillTree(skilltree);
    }

    const handleSelectionChange = (userIds) => {
        InventoryLogic.markAsSelected(users, userIds);
        setUsers([ ...users ]);
    } 

    return (
        <div className='container-fluid'>
            <div className='row p-4'>

                <h5 className='text-muted'>{t('inventory.title')}</h5>

                <hr />

                <div className='row pt-4'>

                    <div>
                        <FiltersCharts onChange={handleFilters} onReset={handleFiltersReset}/>
                    </div>

                    <div className='d-flex flex-wrap2'>

                        <div>
                            <InfoBox users={users} />
                            <UserListMultiSelect users={users} onReset={handleReset} onToggle={handleToggle} />
                        </div>
                        
                        <div className='flex-grow-1 mx-3 p-3 border rounded'>
                            <InventoryView users={users} skilltree={skilltree} onTreeChange={handleTreeChange} onSelectionChange={handleSelectionChange}/>
                        </div>

                        <div>
                            <GenderChart users={users} onSelectionChange={handleSelectionChange} />
                            <AgeChart users={users} onSelectionChange={handleSelectionChange} />
                        </div>
                    </div> 

                </div>

            </div>
        </div>
    )
}

export default Inventory;