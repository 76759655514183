
import { useEffect, useRef, useState } from 'react';
import usePreventDefaultMouseWheel from '../../hooks/usePreventDefaultMouseWheel.js';
import RadarPicture from './RadarPicture.js';

import './Radar.css';

const Radar = ({ entity, axes = {}, matchings = [], hlabel, vlabel, onInit, onSelect }) => {

    /**
     * Page state 
     */

    const svgRef = useRef();
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);
    const [draggingOffset, setDraggingOffset] = useState();
    const [initialized, setInitialized] = useState(false);

    const metrics = {
        svgSize: 1000,
        svgCenter: 1000 / 2,
        svgRadius: 1000 / 2,
        scaleIncrement: 0.001,
        fontSize: 10 / scale,
        iconSize: 35 / scale,
        circleThickness: 6 / scale,
    }

    /**
     * Page lifecycle
     */

    const resetScaleAndPosition = () => {

        // Get current metrics
        const width  = svgRef.current.clientWidth;
        const height = svgRef.current.clientHeight;

        // Calculate the default scale (full radar shown)
        const scale = Math.min(width, height) / metrics.svgSize;
        setScale(scale);
        
        // Center the radar within the view
        const position = { x: (width - metrics.svgSize * scale) / 2, y: (height - metrics.svgSize * scale) / 2 };
        setPosition(position);
    }

    // First time, notify owner that the radar has been loaded
    useEffect(() => { 

        if (svgRef.current && !initialized) {
            setInitialized(true);
            onInit && onInit(resetScaleAndPosition);
        }

        // eslint-disable-next-line
    }, [svgRef.current]);

    // Prevent default page scrolling while wheeling
    usePreventDefaultMouseWheel(svgRef);

    /**
     * Event handlers
     */

    const handleMouseDown = (e) => {
        const draggingOffset = { x: e.nativeEvent.offsetX - position.x, y: e.nativeEvent.offsetY - position.y };
        setDraggingOffset(draggingOffset);
        setDragging(true);
    }

    const handleMouseMove = (e) => {

        if (dragging) {
            position.x = e.nativeEvent.offsetX - draggingOffset.x;
            position.y = e.nativeEvent.offsetY - draggingOffset.y;
            setPosition({ ...position });
        }
    }

    const handleMouseUp = (e) => {
        setDragging(false);
    }

    const handleMouseWheel = (e) => {

        // Change scale according the current increment
        const incscale = - e.deltaY * metrics.scaleIncrement * (e.ctrlKey ? 4 : 1);
        const newscale = Math.max(scale + incscale, 0.5);
        setScale(newscale);

        // Adjust position to visually keep the same part displayed
        if (scale > 0.5) {

            const dscale = (scale + incscale) / scale;
            position.x = e.nativeEvent.offsetX - (e.nativeEvent.offsetX - position.x) * dscale;
            position.y = e.nativeEvent.offsetY - (e.nativeEvent.offsetY - position.y) * dscale;

            setPosition({ ...position });
        }
    }

    const handleUserClick = (e, user) => {
        if (e.detail === 2) onSelect(user);
    }

    return (
        <svg ref={svgRef} className='radar' onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onMouseLeave={handleMouseUp} onTouchStart={handleMouseDown} onTouchMove={handleMouseMove} onTouchEnd={handleMouseUp} onTouchCancel={handleMouseUp} onWheel={handleMouseWheel}>

            {/* components used in axes */}
            <defs>

                <svg id='arrowright' viewBox='0 0 50 50'>
                    <path className='arrows' d='M1 1 L49 25 L1 49 z' />
                </svg>

                <svg id='arrowup' viewBox='0 0 50 50'>
                    <path className='arrows' d='M1 49 L25 1 L49 49 z' fill='black' />
                </svg>

            </defs>

            <g transform={`translate(${position.x}, ${position.y}) scale(${scale})`}>

                {/* background circles */}
                <g className='background-circles'>
                    <circle cx={metrics.svgCenter} cy={metrics.svgCenter} r={metrics.svgRadius / 3} />
                    <circle cx={metrics.svgCenter} cy={metrics.svgCenter} r={metrics.svgRadius / 1.5} />
                    <circle cx={metrics.svgCenter} cy={metrics.svgCenter} r={metrics.svgRadius / 1.05} />
                </g>

                {/* horizontal axis */}
                <g className='axis'>
                    <line x1='0' y1={metrics.svgCenter} x2={metrics.svgSize} y2={metrics.svgCenter} />
                    <use href='#arrowright' x={metrics.svgSize - 10} y={metrics.svgCenter - 5} width='10' height='10' />
                    <text x={metrics.svgSize - 50} y={metrics.svgCenter - 5} textAnchor='end' stroke='none'>{hlabel}</text>
                </g>

                {/* vertical axis */}
                <g className='axis'>
                    <line x1={metrics.svgCenter} y1='0' x2={metrics.svgCenter} y2={metrics.svgSize} />
                    <use href='#arrowup' x={metrics.svgCenter - 5} y='0' width='10' height='10' />
                    <text stroke='none' textAnchor='end' transform={`translate(${metrics.svgCenter - 5}, 50) rotate(270)`}>{vlabel}</text>
                </g>

                {/* central icon */}
                <g transform={`translate(${metrics.svgCenter}, ${metrics.svgCenter}) scale(1.2)`}>
                    <circle cx='0' cy='0' r={metrics.iconSize} fill='white' stroke='black' strokeWidth={1 / scale} />
                    {
                        entity.picture &&
                        <g>
                            <clipPath id='clip'><circle cx='0' cy='0' r={metrics.iconSize} /></clipPath>
                            <image x={-metrics.iconSize} y={-metrics.iconSize} width={metrics.iconSize * 2} height={metrics.iconSize * 2} href={entity.picture} clipPath='url(#clip)' />
                        </g>
                    }
                    {
                        !entity.picture &&
                        <text x='0' y='0' textAnchor='middle' dominantBaseline='middle' fontSize={metrics.fontSize}>{entity.label ?? ''}</text>
                    }
                </g>

                {
                    // Show each profile in the map (the profiles with picture have precedence)
                    matchings.sort((a, b) => (a.picture ? 1 : 0) - (b.picture ? 1 : 0)).map(profile => {

                        // Get distances for the 3 axes
                        const distx = profile.distances.get(axes.x) * metrics.svgRadius;
                        const disty = profile.distances.get(axes.y) * metrics.svgRadius;
                        const distz = (profile.distances.get(axes.z) + 1) / 2;

                        // Calculate the map coordinates
                        const pos = { x: metrics.svgCenter + distx, y: metrics.svgCenter - disty, z: distz };

                        // Display profile
                        return <RadarPicture key={profile.id} entity={profile} position={pos} metrics={metrics} onClick={handleUserClick} />
                    })
                }

            </g>

        </svg>
    )
}

export default Radar;
