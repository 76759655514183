
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';
import FilterUtil from '../utils/FilterUtil.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/users`;

const UserService = () => {

    const getUsers = async (company, filter) => {

        const users = company 
            ? (await axios.get(`${SERVER_URL}?company=${company.id}`)).data.payload
            : (await axios.get(`${SERVER_URL}`)).data.payload;

        return FilterUtil.filter(users, filter);
    }

    const getUser = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const getTotalCount = async (company) => {

        return company 
            ? (await axios.get(`${SERVER_URL}/count?company=${company.id}`)).data.payload
            : (await axios.get(`${SERVER_URL}/count`)).data.payload;
    }

    const create = async (company) => {
        return (await axios.get(`${SERVER_URL}/create?company=${company.id}`)).data.payload;
    }

    const isDeletable = async (user) => {
        return (await axios.get(`${SERVER_URL}/${user.id}/deletable`)).data.payload;
    }

    const save = async (user) => {
        
        return user.id
            ? (await axios.put(`${SERVER_URL}/${user.id}`, user)).data.payload
            : (await axios.post(`${SERVER_URL}`, user)).data.payload;
    }

    const remove = async (user) => {
        await axios.delete(`${SERVER_URL}/${user.id}`);
    }

    return {
        getUsers,
        getUser,
        getTotalCount,
        create,
        isDeletable,
        save,
        remove
    }
}

export default UserService();