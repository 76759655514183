
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import CountryService from '../api/CountryService.js';
import LangUtil from "../utils/LangUtil.js";

const useCountryController = (filter) => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();

    const [countries, setCountries] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    /**
     * Functionalities
     */

    const reload = async () => {

        try {
            setLoaded(false);
            const countries = await CountryService.getCountries(filter);
            countries.forEach(country => translate(country));
            setCountries(countries);

            const totalCount = await CountryService.getTotalCount();
            setTotalCount(totalCount);
        }
        finally {
            setLoaded(true);
        }
    }

    const deletable = async (country) => {
        return country.id != null && await CountryService.isDeletable(country);
    }

    const create = async () => {
        const country = await CountryService.create();
        translate(country);
        return country;
    }

    const save = async (country) => {

        try {
            untranslate(country);
            await CountryService.save(country);
        }
        finally {
            translate(country);
        }

        reload();
    }

    const remove = async (country) => {
        await CountryService.remove(country);
        reload();
    }

    const translate = (country) => {
        LangUtil.translate(country, 'name', auth.lang);
    }

    const untranslate = (country) => {
        LangUtil.untranslate(country, 'name');
    }

    const retranslate = () => {

        countries.forEach(country => { 
            untranslate(country); 
            translate(country);  
        });

        setCountries([...countries]);
    }

    return {
        countries,
        totalCount,
        loaded,
        reload,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate
    }
}

export default useCountryController;