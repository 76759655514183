
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useWorkplaceController from '../../../controllers/useWorkplaceController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import SearchBar from '../../../components/filter/SearchBar.js';
import SearchCount from '../../../components/filter/SearchCount.js';
import SortableHeader from '../../../components/sortable/SortableHeader.js';
import WorkplaceDetail from './WorkplaceDetail.js';
import WorkplaceDelete from './WorkplaceDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const Workplaces = () => {

    const { t } = useTranslation();

    const defaultFilter = {
        attributes: [
            { name: 'code', display: t('workplaces.code') },
            { name: 'name', display: t('workplaces.name') },
            { name: 'countryName', display: t('glossary.country') },
        ]
    }

    /**
     * Page state
     */

    const { auth } = useAuthContext();
    const [filter, setFilter] = useState(defaultFilter);
    const [workplace, setWorkplace] = useState({});
    
    const workplaceController = useWorkplaceController(filter);

    /**
     * Page lifecycle
     */

    // eslint-disable-next-line 
    useEffect(() => { workplaceController.reload() }, [filter, auth.company]);

    // eslint-disable-next-line 
    useEffect(() => { workplaceController.retranslate() }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const workplace = await workplaceController.create();
        setWorkplace(workplace);
    }

    const handleDetail = (workplace) => {
        setWorkplace(workplace);
    }

    const handleSave = async (workplace) => {
        await workplaceController.save(workplace);
    }

    const handleDelete = async (workplace) => {
        await workplaceController.remove(workplace);
    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!workplaceController.loaded} />
            <WorkplaceDetail id='detail' workplace={workplace} onSave={handleSave} />
            <WorkplaceDelete id='delete' workplace={workplace} onDelete={handleDelete} />

            <div className='row p-4'>

                <div className='d-flex align-items-center'>
                    <h5 className='text-muted mb-1'>{t('glossary.workplaces')}</h5>
                    <SearchCount className='text-muted mb-1 ms-2' count={workplaceController.workplaces.length} total={workplaceController.totalCount} />
                    <SearchBar className='ms-4' items={workplaceController.workplaces} filter={filter} onSearch={handleFilter} />
                </div>

                <hr />


                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>
                                <SortableHeader attrName='code' filter={filter} onFilter={handleFilter}>
                                    {t('workplaces.code')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='name' filter={filter} onFilter={handleFilter}>
                                    {t('workplaces.name')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='countryName' filter={filter} onFilter={handleFilter}>
                                    {t('glossary.country')}
                                </SortableHeader>
                            </th>
                            <th className='text-center'>
                                <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            workplaceController.workplaces.map((workplace) => (
                                <tr key={workplace.id} className='align-middle'>
                                    <td>{workplace.code}</td>
                                    <td>{workplace.name}</td>
                                    <td>{workplace.country ? workplace.country.name : '-'}</td>
                                    <td className='text-center'><button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={() => handleDetail(workplace)}><img src={edit_img} alt='edit' /></button></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default Workplaces
