
import { useEffect, useState, useRef } from 'react';
import loading_img from '../../assets/images/loading.svg';

import './LoadingIcon.css';

const LoadingIcon = ({ loading = true }) => {

    const timer = useRef();
    const [visible, setVisible] = useState(false);

    useEffect(() => { 

        if (loading) {
            timer.current = setTimeout(() => setVisible(true), 2000);
        }
        else if (timer.current) {
            setVisible(false);
            clearTimeout(timer.current);
        }

    }, [loading])

    return visible && <div className='loading'><img src={loading_img} alt='loading' /></div>
}

export default LoadingIcon;