
import { useTranslation } from 'react-i18next';
import Slider from '../slider/Slider.js';
import CatalogLogic from '../../logic/CatalogLogic.js';

import './Views.css';

const NormalizedViewSelectable = ({ category, onChange }) => {

    /**
     * Page state
     */
    const { t } = useTranslation();
    const labels = t('common.normalized-view-labels');
    const padding = 100.0 / labels.length / 2;

    /**
     * Event handlers
     */

    const handleLevelChange = (skill, level) => {

        // Change the skill's level
        CatalogLogic.setSkillLevel(category, skill, level);

        // And propagates the category change to the owner
        onChange({ ...category });
    }

    const handleToggle = (skill) => {

        // Toggle selection state
        CatalogLogic.toogleSelection(category, skill);

        // And propagates the category change to the owner
        onChange({ ...category });
    }

    const handleHeaderClick = (skill, i) => {

        if (skill.state?.selected) {

            // Change all children level
            CatalogLogic.setChildrenLevel(category, skill, i);

            // And propagates the category change to the owner
            onChange({ ...category });
        }
    }

    // Note: the table has 9 columns
    //   - 1 col for the label (first col)
    //   - 4 columns doubled for each slider values
    // => slider has 8 column span and header has 2 column span
    return category && category.children?.map(skill => {

        const disabled = skill.state.selected ? false : true;

        return (
            <div key={skill.id} className='container mb-3'>
                <table className='table'>
                    <thead>
                        <tr className='table-header'>
                            <th className='text-start'>
                                <input id={skill.id} type='checkbox' className='form-check-input me-2' checked={!disabled} onChange={() => handleToggle(skill)} />
                                <label onClick={() => handleToggle(skill)}>{skill.label}</label>
                            </th>
                            <th>
                                <div className={'table-slider-cell' + (disabled ? ' text-disabled' : '')}>
                                    { labels.map((label, i) => (<div key={i} onClick={() => handleHeaderClick(skill, i+1)}>{label}</div>)) }
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            skill.children?.map((skill, i) => {

                                const level = CatalogLogic.getSkillLevel(category, skill);
                                
                                return (
                                    <tr key={i} className='table-row'>
                                        <td className='text-start ps-4'>
                                            {skill.label}
                                        </td>
                                        <td>
                                            <div className={'table-slider-cell' + (disabled ? ' text-disabled' : '')} style={{ paddingLeft: `${padding}%`, paddingRight: `${padding}%` }}>
                                                <Slider min='1' max='4' value={level} disabled={disabled} onChange={(e) => handleLevelChange(skill, e.target.value)} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>
        )
    })
}

export default NormalizedViewSelectable;
