
import { useTranslation } from 'react-i18next';
import DateSelector from '../../../components/editor/DateSelector.js';

const TabWork = ({ user, companies, positionTypes, workplaces, showCompany, onChange }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();

    /**
     * Event handlers
     */

    const handleAttributeChange = (attributes) => {
        onChange({ ...user, ...attributes });
    }

    const handleCompanyChange = (e) => {
        const company = companies.find(c => c.id === e.target.value);
        handleAttributeChange({ company });
    }

    const handlePositionTypeChange = (e) => {
        const positionType = positionTypes.find(c => c.id === e.target.value);
        handleAttributeChange({ positionType });
    }

    const handleWorkplaceChange = (e) => {
        const workplace = workplaces.find(c => c.id === e.target.value);
        handleAttributeChange({ workplace });
    }

    return (

        <div className='container'>

            {
                showCompany &&
                <div className='row'>
                    <div className='col form-group'>
                        <label htmlFor='company'>{t('glossary.company')}</label>
                        <select id='company' className='form-select' value={user.company?.id ?? ''} onChange={handleCompanyChange}>
                            <option value={null}>{t('glossary.none')}</option>
                            {companies?.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                        </select>
                    </div>
                </div>
            }

            <div className='row pt-2'>
                <div className='col form-group'>
                    <label htmlFor='positionTitle'>{t('users.postitle')}</label>
                    <input id='positionTitle' type='text' className='form-control' value={user.positionTitle ?? ''} placeholder={t('users.postitle-placeholder')} onChange={(e) => handleAttributeChange({ positionTitle: e.target.value })} />
                </div>
            </div>

            <div className='row pt-2'>

                <div className='col form-group'>
                    <label htmlFor='entrydate'>{t('users.entrydate')}</label>
                    <DateSelector id='entrydate' className='form-control' value={user.entrydate} onChange={(date) => handleAttributeChange({ entrydate: date })} /> 
                </div>

                <div className='col form-group'>
                    <label htmlFor='activity'>{t('users.activityrate')}</label>
                    <input id='activity' type='number' className='form-control' value={user.activityRate * 100.0 || ''} placeholder={t('users.activityrate-placeholder')} max='100' step='10' onChange={(e) => handleAttributeChange({ activityRate: e.target.value/100.0 })} />
                </div>

            </div>

            <div className='row pt-2'>

                <div className='col form-group'>
                    <label htmlFor='type'>{t('glossary.postype')}</label>
                    <select id='position' className='form-select' value={user.positionType?.id ?? '1'} onChange={handlePositionTypeChange}>
                        <option value={null}>{t('glossary.none')}</option>
                        {positionTypes.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                    </select>
                </div>

            </div>

            <div className='row pt-2'>
                <div className='col form-group'>
                    <label htmlFor='team'>{t('users.team')}</label>
                    <input id='team' type='text' className='form-control' value={user.team ?? ''} placeholder={t('users.team-placeholder')} onChange={(e) => handleAttributeChange({ team: e.target.value })} />
                </div>
            </div>

            <div className='row pt-2'>
                <div className='col form-group'>
                    <label htmlFor='manager'>{t('users.manager')}</label>
                    <input id='manager' type='text' className='form-control' value={user.emailManager ?? ''} placeholder={t('users.manager-placeholder')} onChange={(e) => handleAttributeChange({ emailManager: e.target.value })} />
                </div>
            </div>

            <div className='row pt-2'>
                <div className='col form-group'>
                    <label htmlFor='type'>{t('glossary.workplace')}</label>
                    <select id='workplace' className='form-select' value={user.workplace?.id ?? "1"} onChange={handleWorkplaceChange}>
                        <option value={null}>{t('glossary.none')}</option>
                        {workplaces.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                    </select>
                </div>
            </div>

        </div>

    )
}

export default TabWork;
