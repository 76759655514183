
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useInvitationController from '../../../controllers/useInvitationController.js';

import parse from 'html-react-parser';
import Stepper from './Stepper.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import CatalogLogic from '../../../logic/CatalogLogic.js';
import InvitationLogic from '../../../logic/InvitationLogic.js';

import DeclarativeView from '../../../components/catalog/DeclarativeViewEditable.js';
import NormalizedView from '../../../components/catalog/NormalizedViewEditable.js';
import LanguageView from '../../../components/catalog/LanguageViewEditable.js';
import MindsetView from './MindsetView.js';

import next_img from '../../../assets/images/next.svg';
import previous_img from '../../../assets/images/previous.svg';

const SurveyDetail = () => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const { id } = useParams();
    const navigate = useNavigate();

    const [invitation, setInvitation] = useState({});
    const [catalog, setCatalog] = useState({});
    const [mindset, setMindset] = useState({});
    const [steps, setSteps] = useState([]);
    const [current, setCurrent] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [done, setDone] = useState(false);

    const invitationController = useInvitationController();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {

            try {
                setLoaded(false);

                const invitation = await invitationController.getInvitation(id);
                const steps = InvitationLogic.createSteps(invitation);
                const done = invitation.status === 'COMPLETED';

                setInvitation(invitation);
                setCatalog(invitation.catalog);
                setMindset(invitation.mindset);
                setSteps(steps);
                setDone(done);

                setCurrent(done ? 0 : invitation.step);
                setCompleted(done ? steps.length : invitation.step);

            }
            finally {
                setLoaded(true);
            }

        })()

        // eslint-disable-next-line
    }, [id, auth.lang]);

    const updateAndSave = (completedStep) => {

        // Gather invitation data
        invitation.step = completedStep;
        invitation.catalog = catalog;
        invitation.mindset = mindset;

        // Save the invitation
        invitationController.save(invitation);
    }

    /**
     * Event handlers
     */

    const handleCategoryChange = (category) => {
        CatalogLogic.mergeCategory(catalog, category);
        setCatalog({ ...catalog });
    }

    const handleMindsetChange = (mindset) => {
        mindset.completed = mindset.step >= mindset.topics.length;
        setMindset(mindset);
    }

    const handleNext = async () => {

        if (current < steps.length - 1) {

            // Compute step constrains
            const nextStep = current + 1;
            const completedStep = Math.max(nextStep, completed);

            setCurrent(nextStep);
            setCompleted(completedStep);
            updateAndSave(completedStep);
        }
    }

    const handlePrev = async () => {
        if (current > 0) setCurrent(current - 1);
    }

    const handleSubmit = () => {
        updateAndSave(completed);
        navigate('/user/invitations');
    }

    const handleCancel = () => {
        navigate('/user/invitations');
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!loaded} />

            <div className='row p-4'>

                <h5 className='text-muted'>{invitation.survey?.name}</h5>

                <hr />

                <div className='d-flex justify-content-between align-items-stretch'>

                    <button type='button' className='btn btn-light' disabled={current < 1} onClick={handlePrev}>
                        <img src={previous_img} alt='back' />
                    </button>

                    <Stepper steps={steps} currentStep={current} completedStep={completed} />

                    <button type='button' className='btn btn-light' disabled={current > steps.length - 2} onClick={handleNext}>
                        <img src={next_img} alt='next' />
                    </button>

                </div>

                <div className='container'>
                    <div className='row'>
                        {
                            steps.map((step, i) => (

                                <div key={step.id} className={'stepper-panel p-4 fade' + (current === i ? ' show active' : '')}>

                                    {
                                        step.type === 'start' &&
                                        parse(t('invitations.startmsg'))
                                    }
                                    {
                                        step.type === 'declarative' &&
                                        <DeclarativeView category={step} disabled={done} onChange={handleCategoryChange} />
                                    }
                                    {
                                        step.type === 'normalized' &&
                                        <NormalizedView category={step} disabled={done} onChange={handleCategoryChange} />
                                    }
                                    {
                                        step.type === 'language' &&
                                        <LanguageView category={step} disabled={done} onChange={handleCategoryChange} />
                                    }
                                    {
                                        step.type === 'mindset' &&
                                        <MindsetView mindset={mindset} disabled={done} onChange={handleMindsetChange} />
                                    }
                                    {
                                        step.type === 'end' &&
                                        parse(t('invitations.endmsg'))
                                    }

                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className='row pt-3'>
                    <hr />
                    <div className='d-flex justify-content-between w-100'>
                        <div>
                            <button type='button' className='btn btn-light' disabled={current < 1} onClick={handlePrev}>{t('button.previous')}</button>
                            <button type='button' className='btn btn-light' disabled={current > steps.length - 2} onClick={handleNext}>{t('button.next')}</button>
                        </div>
                        <div className='btn-group'>
                            <button className='btn btn-light' disabled={done || current < steps.length - 1} onClick={handleSubmit}>{t('button.submit')}</button>
                            <button className='btn btn-light' onClick={handleCancel}>{t('button.quit')}</button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default SurveyDetail;
