
import { useEffect } from 'react';
import { Tooltip } from 'bootstrap';

/**
 * Bootstrap tooltip activation
 * 
 * Inside react, a click on a menu item doesn't close the other opened menus.
 * So we are listening to clicks on all nav-item and dropdown-item 
 * and close all dropdowns currently opened when a click is detected.
 */

const useTooltips = () => {

    useEffect(() => {
        document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(element => new Tooltip(element));
    })

}

export default useTooltips;