
import { useTranslation } from 'react-i18next';

const FormationsView = ({ formations }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();

    return (

        <table className='table table-sm'>
            <thead>
                <tr className='table-header text-start'>
                    <th>{t('formations.year')}</th>
                    <th>{t('formations.school')}</th>
                    <th>{t('formations.diploma')}</th>
                    <th>{t('formations.diplomaType')}</th>
                </tr>
            </thead>
            <tbody>
                {
                    formations.map((formation) => (
                        <tr key={formation.id} className='table-row text-start'>
                            <td>{formation.year ?? '-'}</td>
                            <td>{formation.school ?? '-'}</td>
                            <td>{formation.diploma ?? '-'}</td>
                            <td>{formation.diplomaType?.name ?? '-'}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}

export default FormationsView
