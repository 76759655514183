
import { useTranslation } from 'react-i18next';
import Slider from '../slider/Slider.js';
import CatalogLogic from '../../logic/CatalogLogic.js';

import './Views.css';

/**
 * Note: this view is a copy of the LanguageViewEditable
 * with a checkbox in front of each category
 */
const LanguageViewSelectable = ({ category, onChange }) => {

    /**
     * Page state
     */
    const { t } = useTranslation();
    const labels = t('common.language-view-labels');

    /**
     * Event handlers
     */

    const handleLevelChange = (skill, level) => {

        // Change the skill's level
        CatalogLogic.setSkillLevel(category, skill, level);

        // And propagates the category change to the owner
        onChange({ ...category });
    }

    const handleToggle = (skill) => {

        // Toggle selection state
        CatalogLogic.toogleSelection(category, skill);

        // And propagates the category change to the owner
        onChange({ ...category });
    }

    const handleHeaderClick = (skill, i) => {

        if (skill.state?.selected) {

            // Change all children level
            CatalogLogic.setChildrenLevel(category, skill, i);

            // And propagates the category change to the owner
            onChange({ ...category });
        }
    }

    // Note: the table has 15 columns
    //   - 1 col for the label (first col)
    //   - 7 columns doubled for each slider values
    // => slider has 14 column span and header has 2 column span
    return category && category.children?.map(skill => {

        const disabled = skill.state.selected ? false : true;

        return (
            <div key={skill.id} className='container mb-3'>

                <table className='table'>
                    <thead>
                        <tr className={'table-header' + (disabled ? ' text-muted' : '')}>
                            <th className='text-start'>
                                <input id={skill.id} type='checkbox' className='form-check-input me-2' checked={disabled ? false : true} onChange={() => handleToggle(skill)} />
                                <label onClick={() => handleToggle(skill)}>{skill.label}</label>
                            </th>
                            {
                                labels.map((label, i) => (
                                    <th key={i} colSpan='2' width='11%'>
                                        <label onClick={() => handleHeaderClick(skill, i+1)}>{label}</label>
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            skill.children?.map((skill, i) => {

                                const skillLevel = CatalogLogic.getSkillLevel(category, skill);

                                return (
                                    <tr key={i} className={'table-row' + (disabled ? ' text-muted' : '')}>
                                        <td className='text-start ps-4'>{skill.label}</td>
                                        <td colSpan='14'>
                                            <div style={{ paddingLeft: '6%', paddingRight: '6%' }}>
                                                <Slider id={skill.id} min='1' max='6' value={disabled ? 0 : skillLevel ?? 0} disabled={disabled} onChange={(e) => handleLevelChange(skill, e.target.value)} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>
        )
    })
}

export default LanguageViewSelectable;
