
import { useTranslation } from 'react-i18next';
import InventoryLogic from '../../logic/InventoryLogic.js';
import ruler_img from '../../assets/images/ruler.svg';

import './AgeChart.css';

const AgeChart = ({ users, onSelectionChange }) => {

    const { t } = useTranslation();
    
    // Retrieve the selected users classified by age categories
    const data = InventoryLogic.getAgeData(users);

    /**
     * Event handlers
     */

    const handleSelectionChange = (users) => {
        onSelectionChange(users.map(user => user.id));
    }

    return (
        <div className='agechart mt-3 p-3 border rounded chart-bcvp'>

            <h5>{t('charts.ages-ranges')}</h5>
            
            <div className='ruler pt-3'>
                <img src={ruler_img} alt='ruler' />
            </div>
            
            <ul>
                {
                    data.reverse().map(category => {

                        const widthInPercent = category.percent * 100;

                        return (
                            <li key={category.id}>

                                <span className='legend'>
                                    {category.display}
                                </span>
                                
                                <div className='value'>
                                    {
                                        widthInPercent > 0 &&
                                        <span className='' style={{ width: widthInPercent + '%' }} onClick={() => { handleSelectionChange(category.users)} }>
                                            { category.users.length }
                                        </span>
                                    }
                                </div> 

                            </li>
                        )
                    })
                }
            </ul>

        </div>            
    )
}

export default AgeChart;