
import React from 'react';
import TreeContext, { useTreeContext } from './TreeContext.js';
import DragContext, { useDragContext, } from './DragContext.js';
import ReactUtil from '../../utils/ReactUtil.js';
import NumberUtil from '../../utils/NumberUtil.js';
import TreeUtil from '../../utils/TreeUtil.js';
import DeleteDialog from './DeleteDialog.js';
import PropertyDialog from './PropertyDialog.js';
import TableRows from './TableRows.js';

import './TreeTable.css';

/**
 * TreeTable: a component that renders a tree of nodes
 * 
 * with these handler functions:
 * 
 * onNew:       pass a new created node and give the opportunity to customize it
 * onEdit:      return the provided node's label
 * onSetLabel:  to set the propvided node's label with provided text
 * onDelete:    to delete a node from the tree
 * 
 */
const TreeTable = ({ data, editable = false, onChange, onNew, onDelete, ...props }) => {

    editable = (typeof editable === 'string') ? editable === 'true' : editable;

    return data && (
        <TreeContext editable={editable}>
            <DragContext>
                <TreeTableInternal data={data} tableProps={props} onChange={onChange} onNew={onNew} onDelete={onDelete}>
                    {props.children}
                </TreeTableInternal>
            </DragContext>
        </TreeContext>
    )
}

const TreeTableInternal = ({ data, children, tableProps, onChange, onNew, onDelete }) => {

    const treeContext = useTreeContext();
    const dragContext = useDragContext();

    const className = 'treetable' + (tableProps.className ? ' ' + tableProps.className : '');

    // Retrieve table components passed by user
    const thead = ReactUtil.toArray(children).filter(c => c.type === 'thead')[0];
    const tbody = ReactUtil.toArray(children).filter(c => c.type === 'tbody')[0];
    const tfoot = ReactUtil.toArray(children).filter(c => c.type === 'tfoot')[0];

    const handleToggle = (nodeId) => {

        // Get current node
        const node = TreeUtil.findById(data, nodeId);

        // Switch its opened state
        node.state = { ...node.state, opened: !node.state.opened };

        onChange && onChange({ ...data });
    }

    const handleNew = () => {

        // Get current node
        const parent = TreeUtil.findById(data, treeContext.selectedId);
    
        // Create a new node and pass it the the client
        const node = { id: NumberUtil.uniqueId(), label: 'New', state: {} }
        
        // Pass it to the client (if listening), to give possibility to customize the node
        onNew && onNew(node);
    
        // Insert the child into the node
        TreeUtil.addInside(data, parent, node);
        parent.state.opened = true;
    
        onChange && onChange({ ...data });
    }

    const handleInPlaceEdit = () => {

        // Get current node
        const node = TreeUtil.findById(data, treeContext.selectedId);

        // Switch to edit mode
        treeContext.setEditMode(true);
        treeContext.setEditText(node.label);
    }

    const handleEditPlaceSave = () => {

        // Get current node
        const node = TreeUtil.findById(data, treeContext.selectedId);

        // Save new node's label and quit edit mode
        node.label = treeContext.editText;
        treeContext.setEditMode(false);
        treeContext.setSelectedId();

        onChange && onChange({ ...data });
    }

    const handleSave = (node) => {

        // Get current node
        const originalNode = TreeUtil.findById(data, node.id);

        // Update the original node with the modified attributes
        for(const field of Object.keys(node)) {
            originalNode[field] = node[field];
        }

        onChange && onChange({ ...data });
    }

    const handleDelete = (e) => {

        // Get current node
        const node = TreeUtil.findById(data, treeContext.selectedId);

        // Delete node (from the outside or directly on the tree)
        onDelete ? onDelete(data, node) : TreeUtil.remove(data, node.id);
        treeContext.setSelectedId();

        onChange && onChange({ ...data });
    }

    const handleDrop = (dragId, dropId, dropMode) => {

        // Prevent dropping onto itself
        if (dragId !== dropId) {

            const parent = TreeUtil.findParent(data, dropId);

            // Prevent dropping before root
            if (parent !== null || dropMode !== 'before') {

                // const data = ObjectUtil.clone(data);
                const dragNode = TreeUtil.findById(data, dragId);
                const dropNode = TreeUtil.findById(data, dropId);

                // Update the tree
                TreeUtil.remove(data, dragId);

                if (dropMode === 'before') {
                    TreeUtil.addBefore(data, dropNode, dragNode);
                }
                else if (dropMode === 'after') {
                    TreeUtil.addAfter(data, dropNode, dragNode);
                }
                else {
                    TreeUtil.addInside(data, dropNode, dragNode);
                }

                // Notify data change
                onChange && onChange({ ...data });
            }
        }
    }

    return (
        <>
            <table {...tableProps} className={className}>

                {thead}

                <tbody {...tbody?.props}>
                    {<TableRows node={data} data={data} level={0} tbody={tbody} onToggle={handleToggle} onNew={handleNew} onEdit={handleInPlaceEdit} onSave={handleEditPlaceSave} onDrop={handleDrop} />}
                </tbody>

                {tfoot}

            </table>

            <div ref={dragContext.dragImageRef} className='dragging-image'>
                {dragContext.dragText}
            </div>

            {treeContext.editable && <PropertyDialog id={'edit-' + treeContext.treeId} data={data} onSave={handleSave} />}
            {treeContext.editable && <DeleteDialog id={'delete-' + treeContext.treeId} data={data} onDelete={handleDelete} />}

        </>
    )
}

export default TreeTable;
