
import ObjectUtil from '../utils/ObjectUtil.js';
import TreeUtil from '../utils/TreeUtil.js';
import CatalogLogic from './CatalogLogic.js';

const CatalogSelectorLogic = () => {

    /**
     * Synchronize all node states in both source and target tree.
     * 
     * Each source nodes have their attribute 'selected' modified as follow:
     * 
     *      selected = true            the node is present in the target
     *      selected = false           the node is not present in the target
     *      selected = 'indeterminate' some of the children, but not all, are present in the target
     * 
     * Each target nodes have an their attribute 'origin' modified as follow:
     * 
     *      target          if the node has been created in the target
     *      source          if the node comes from the source and is present in the target
     */
    const syncStates = (source, target) => {

        // Convert trees to list
        const sourceList = TreeUtil.toList(source);
        const targetList = TreeUtil.toList(target);

        // Then, clear all source and target node states
        sourceList.forEach(node => { node.state = node.state ? { ...node.state, selected: false } : { selected: false }; });
        targetList.forEach(node => { node.state = node.state ? { ...node.state, origin: 'target' } : { origin: 'target' }; });

        // Then, detect all source nodes present in target 
        targetList.forEach(targetNode => {

            // Retrieve the source node associated to the target node
            const sourceNode = sourceList.find(node => node.id === targetNode.id);

            // If found, the source node is present in target
            if (sourceNode) {
                sourceNode.state.selected = true;
                targetNode.state.origin = 'source';
            }
        })

        // Now detect all source nodes in indeterminate states
        sourceList.reverse().forEach(sourceNode => {

            // Only handle sourceNodes with children
            if (sourceNode.children) {

                // Find if all children are checked
                let allChildrenSelected = true;
                sourceNode.children.forEach(node => allChildrenSelected = allChildrenSelected && node.state.selected === true);

                // If all children are checked, the parent is also checked
                if (allChildrenSelected) {
                    sourceNode.state.selected = true;
                }
                else {

                    // Find if all children are unchecked
                    let allChildrenUnSelected = true;
                    sourceNode.children.forEach(node => allChildrenUnSelected = allChildrenUnSelected && node.state.selected === false);

                    // If all children are unchecked, the parent is also unchecked
                    if (allChildrenUnSelected) {
                        sourceNode.state.selected = false;
                    }
                    else {
                        // Otherwise, it is in and indeterminate state
                        sourceNode.state.selected = 'indeterminate';
                    }

                    // If no children checked and the node is not selected,
                    // we must still check that the node is not present anymore in target
                    if (allChildrenUnSelected && sourceNode.state.selected === false) {
                        const targetNode = targetList.find(node => node.id === sourceNode.id);
                        if (targetNode) sourceNode.state.selected = 'indeterminate';
                    }
                }
            }
        })
    }
    
    /**
     * Toggle the selected state of a node in source
     * and update both source and target trees.
     */
    const toggleSourceNode = (source, target, sourceId) => {

        // Retrieve the source skill
        const sourceNode = TreeUtil.findById(source, sourceId);

        // Switch its selected state
        sourceNode.state.selected = !sourceNode.state.selected;

        // And insert/remove it according to its state
        if (sourceNode.state.selected) {
            addTargetNode(source, target, sourceNode);
        }
        else {
            removeTargetNode(source, target, sourceNode);
        }

        // Synchronize source and target states
        syncStates(source, target);
    }

    /**
     * Delete a node from the target.
     */
    const deleteTargetNode = (source, target, targetId) => {

        const targetNode = TreeUtil.findById(target, targetId);

        if (targetNode.state.origin === 'target') {
            TreeUtil.remove(target, targetNode.id);
        }
        else {
            removeTargetNode(source, target, targetNode);
        }

        // Synchronize source and target states
        syncStates(source, target);
    }

    /**
     * Add a source node to the target
     * 
     * note: 
     *   before adding the node the target, all its missing parents are also added.
     *   if the node  has some children, they are also added into the target
     */
    const addTargetNode = (source, target, sourceNode) => {

        const createNodeInTarget = (sourceNode) => {

            // Clone the source node
            const newNode = ObjectUtil.clone(sourceNode);
            delete newNode.children;
            delete newNode.state.selected;

            // Retrieve the parent in target
            const parent = TreeUtil.findParent(source, sourceNode.id);
            const parentId = parent == null || CatalogLogic.isRoot(parent) ? target.id : parent.id;
            const targetParent = TreeUtil.findById(target, parentId);

            // Add the new node to its parent
            targetParent.children = targetParent.children ? [...targetParent.children, newNode] : [newNode];
        }

        // Find all parents not present in target
        const missingParents = [];
        let parent = TreeUtil.findParent(source, sourceNode.id);
        while (parent !== null && CatalogLogic.isNotRoot(parent)) {

            // Check if present in target
            const targetNode = TreeUtil.findParent(target, parent.id);

            // Parent found, so stop looking up
            if (targetNode !== null) break;

            // Otherwise add the parent to the missing list
            missingParents.unshift(parent);

            // And continue looking up to the root
            parent = TreeUtil.findParent(source, parent.id);
        }

        // Now, ensure that the node's parents are created and present in target
        missingParents.forEach(node => createNodeInTarget({ ...node, state: { opened: true }}));

        // Create the node in the target
        createNodeInTarget({ ...sourceNode, state: { opened: false }});

        // Finally, also create node's children in the target
        if (sourceNode.children) {
            sourceNode.children.forEach(child => addTargetNode(source, target, child));
        }
    }

    /**
     * Remove a source node from the target
     * 
     * note: 
     *   if the corresponding node in target has also children coming from source, their are removed
     *   if the node's parent are useless (without children) their are also removed from target
     */
    const removeTargetNode = (source, target, sourceNode) => {

        // Check if present in target
        const targetNode = TreeUtil.findById(target, sourceNode.id);

        // If present in target, try to remove it
        if (targetNode) {

            // Select all children with origin = 'source'
            const sourceOriginNodes = TreeUtil.filter(targetNode, node => {
                let allSourceOrigin = node.state.origin === 'source';
                node.children?.forEach(node => allSourceOrigin = allSourceOrigin && node.state.origin === 'source');
                return node.id !== targetNode.id && allSourceOrigin;
            })

            // Remove all source nodes contained in targetNode
            sourceOriginNodes.forEach(n => TreeUtil.remove(target, n.id));

            // Then, if targetNode has no more children, remove it
            if (!targetNode.children) {

                TreeUtil.remove(target, targetNode.id);

                // Remove all useless source parent up to the root
                let parent = TreeUtil.findParent(source, sourceNode.id);
                while (parent !== null && CatalogLogic.isNotRoot(parent)) {

                    // Check if present in target
                    const targetNode = TreeUtil.findById(target, parent.id);

                    // If parent found, and it has no more children, remove it
                    if (targetNode.children == null) {
                        TreeUtil.remove(target, targetNode.id);
                    }

                    // And continue looking up to the root
                    parent = TreeUtil.findParent(source, parent.id);
                }
            }
        }
    }

    return {
        syncStates,
        toggleSourceNode,
        deleteTargetNode,
    }
}

export default CatalogSelectorLogic();

