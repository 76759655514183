
import { useRef, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuthContext } from '../../controllers/useAuthContext.js';
import { useTranslation } from 'react-i18next';

import useDropdownFix from '../../hooks/useDropdownFix.js';
import useCompanyController from '../../controllers/useCompanyController.js';
import useImportExportController from '../../controllers/useImportExportController.js';
import useAuthenticationController from '../../controllers/useAuthenticationController.js';

import ExportationAlert from '../alert/ExportationAlert.js';
import ImportationAlert from '../alert/ImportationAlert.js';

import logoImage from '../../assets/images/logo.png';

const NavBar = () => {

    /**
     * Page state
     */

    const hiddenImportRef = useRef();
    const { auth } = useAuthContext();
    const { t, i18n } = useTranslation();
    const [exportation, setExportation] = useState({ show: false, error: false });
    const [importation, setImportation] = useState({ show: false, error: false });

    const companyController = useCompanyController();
    const importExportController = useImportExportController();
    const authenticationController = useAuthenticationController();
    const showPsychometricTests = true; // modifiable by docker-build.sh, during deployment

    /**
     * Component lifecycle
     */

    // Fix incorrect dropdown close when nav-item clicked
    useDropdownFix();

    // eslint-disable-next-line 
    useEffect(() => { companyController.reload() }, [auth]);

    /**
     * Event handlers
     */

    const handleChangeCompany = (company) => {
        authenticationController.changeCompany(company);
    }

    const handleChangeLanguage = (lang) => {
        authenticationController.changeLanguage(lang);
    }

    const handleImport = (e) => {
        e.preventDefault();
        hiddenImportRef.current?.click();
    }

    const handleExport = async (e) => {

        e.preventDefault();

        try {
            await importExportController.exportDatabase();
            setTimeout(() => setExportation({ show: true }), 800);
        }
        catch(e) {
            setExportation({ error: true });
        }
    }

    const handleHiddenImport = async (e) => {

        e.preventDefault();

        if (e.target.files.length > 0) {

            try {
                await importExportController.importDatabase(e.target.files[0]);
                e.target.value = null;
                setImportation({ show: true });
            }
            catch(e) {
                setImportation({ error: true });
            }
        }
    }

    // Hide navbar if not logged
    if (!auth.authenticated) return;

    return (
        <header>

            {
                <nav className='navbar navbar-light bg-light navbar-expand-md sticky-top ps-3 pe-3'>

                    <input type='file' ref={hiddenImportRef} accept='application/json' style={{ display: 'none' }} onInput={handleHiddenImport} />
                    <ExportationAlert show={exportation.show} error={exportation.error} onClose={() => setExportation({ show: false })} />
                    <ImportationAlert show={importation.show} error={importation.error} onClose={() => setImportation({ show: false })} />

                    <NavLink to='#' className='navbar-brand text-dark'><img className='logo' src={logoImage} alt='new' /></NavLink>
                    <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbar'><span className='navbar-toggler-icon'></span></button>

                    <div id='navbar' className='navbar-collapse collapse'>

                        <ul className='navbar-nav me-auto'>

                            {
                                authenticationController.hasRole('USER') &&
                                <li className='nav-item'>
                                    <NavLink to='/home' className='nav-link'>{t('glossary.home')}</NavLink>
                                </li>
                            }
                            {
                                authenticationController.hasRole('ADMIN') &&
                                <li className='nav-item dropdown'>
                                    <NavLink to='/admin' className='nav-link dropdown-toggle' data-bs-toggle='dropdown'>{t('glossary.admin')}</NavLink>
                                    <ul className='dropdown-menu'>
                                        <li><NavLink to='/admin/users' className='dropdown-item'>{t('glossary.users')}</NavLink></li>
                                        <li><NavLink to='/admin/countries' className='dropdown-item'>{t('glossary.countries')}</NavLink></li>
                                        <li><NavLink to='/admin/companies' className='dropdown-item'>{t('glossary.companies')}</NavLink></li>
                                        <li><NavLink to='/admin/catalog' className='dropdown-item'>{t('glossary.catalog')}</NavLink></li>
                                        <li><hr className='dropdown-divider' /></li>
                                        <li><NavLink to='/admin/userroles' className='dropdown-item'>{t('glossary.userroles')}</NavLink></li>
                                        <li><NavLink to='/admin/usertypes' className='dropdown-item'>{t('glossary.usertypes')}</NavLink></li>
                                        <li><NavLink to='/admin/sectors' className='dropdown-item'>{t('glossary.sectors')}</NavLink></li>
                                        <li><NavLink to='/admin/diplomatypes' className='dropdown-item'>{t('glossary.diplomatypes')}</NavLink></li>
                                        <li><NavLink to='/admin/wishes' className='dropdown-item'>{t('glossary.wishes')}</NavLink></li>
                                        <li><hr className='dropdown-divider' /></li>
                                        <li><NavLink to='/#' className='dropdown-item' onClick={handleImport}>{t('button.import')}</NavLink></li>
                                        <li><NavLink to='/#' className='dropdown-item' onClick={handleExport}>{t('button.export')}</NavLink></li>
                                    </ul>
                                </li>
                            }
                            {
                                authenticationController.hasRole('MANAGER') &&
                                <li className='nav-item dropdown'>
                                    <NavLink to='/manager' className='nav-link dropdown-toggle' data-bs-toggle='dropdown'>{t('glossary.manager')}</NavLink>
                                    <ul className='dropdown-menu'>
                                        <li><NavLink to='/manager/company' className='dropdown-item'>{t('glossary.company')}</NavLink></li>
                                        <li><NavLink to='/manager/inventory' className='dropdown-item'>{t('glossary.inventory')}</NavLink></li>
                                        <li><NavLink to='/manager/catalog' className='dropdown-item'>{t('glossary.catalog')}</NavLink></li>
                                        <li><hr className='dropdown-divider' /></li>
                                        <li><NavLink to='/manager/users' className='dropdown-item'>{t('glossary.users')}</NavLink></li>
                                        <li><NavLink to='/manager/surveys' className='dropdown-item'>{t('glossary.surveys')}</NavLink></li>
                                        <li><NavLink to='/manager/motions' className='dropdown-item'>{t('glossary.motions')}</NavLink></li>
                                        <li><hr className='dropdown-divider' /></li>
                                        <li><NavLink to='/manager/workplaces' className='dropdown-item'>{t('glossary.workplaces')}</NavLink></li>
                                        <li><NavLink to='/manager/postypes' className='dropdown-item'>{t('glossary.postypes')}</NavLink></li>
                                    </ul>
                                </li>
                            }
                            {
                                authenticationController.hasRole('USER') &&
                                <li className='nav-item dropdown'>
                                    <NavLink to='/user' className='nav-link dropdown-toggle' data-bs-toggle='dropdown'>{t('profile.profile')}</NavLink>
                                    <ul className='dropdown-menu'>
                                        <li><NavLink to='/user/account' className='dropdown-item'>{t('profile.account')}</NavLink></li>
                                        <li><hr className='dropdown-divider' /></li>
                                        <li><NavLink to='/user/catalog' className='dropdown-item'>{t('profile.skills')}</NavLink></li>
                                        <li><NavLink to='/user/experiences' className='dropdown-item'>{t('profile.experiences')}</NavLink></li>
                                        <li><NavLink to='/user/formations' className='dropdown-item'>{t('profile.formations')}</NavLink></li>
                                        
                                        { 
                                            showPsychometricTests &&
                                            <li><NavLink to='/user/assessments' className='dropdown-item'>{t('profile.assessments')}</NavLink></li> 
                                        }
                                        
                                        <li><hr className='dropdown-divider' /></li>
                                        <li><NavLink to='/user/cv' className='dropdown-item'>{t('mycv.title')}</NavLink></li>
                                        <li><NavLink to='/user/invitations' className='dropdown-item'>{t('profile.surveys')}</NavLink></li>
                                        <li><NavLink to='/user/opportunities' className='dropdown-item'>{t('profile.opportunities')}</NavLink></li>
                                        <li><hr className='dropdown-divider' /></li>
                                        <li><NavLink to='/user/logout' className='dropdown-item'>{t('button.logout')}</NavLink></li>
                                    </ul>
                                </li>
                            }

                        </ul>

                        <ul className='navbar-nav' style={{ fontSize: '0.9rem'}}>

                            {
                                companyController.loaded && authenticationController.hasRole('ADMIN') &&
                                <li className='nav-item dropdown'>
                                    <NavLink to='switchcompany' className='nav-link dropdown-toggle' data-bs-toggle='dropdown'>
                                        <span className={(auth.company.owner ? 'text-primary' : 'text-secondary')}>{auth.company.name}</span>
                                    </NavLink>
                                    <ul className='dropdown-menu dropdown-menu-end'>
                                        {
                                            companyController.companies.map(company => (
                                                <li className='dropdown-item' key={company.id}>
                                                    <NavLink className='nav-link' to='#' onClick={() => handleChangeCompany(company)}>
                                                        {company.name + (company.owner ? ' *' : '')}
                                                    </NavLink>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </li>
                            }
                            {
                                !authenticationController.hasRole('ADMIN') &&
                                <li className='nav-item d-flex'>
                                    <span className={'nav-link' + (auth.company.owner ? ' text-primary' : ' text-secondary')}>{auth.company.name}</span>
                                </li>
                            }

                            <li className='nav-item d-flex'>
                                <span className={'nav-link text-muted'}>&nbsp; | &nbsp;</span>
                                <span className={'nav-link text-muted'}>{auth.user.name}</span>
                                <span className={'nav-link text-muted'}>&nbsp; | &nbsp;</span>
                            </li>

                            <li className='nav-item d-flex'>
                                {
                                    i18n.supportedLanguages.map(lang => (
                                        lang === auth.lang
                                            ? <NavLink key={lang} to='#' className='nav-link disabled'><small><strong>{lang.toUpperCase()}</strong></small></NavLink>
                                            : <NavLink key={lang} to='#' className='nav-link' onClick={() => handleChangeLanguage(lang)}><small>{lang.toUpperCase()}</small></NavLink>
                                    ))
                                }
                            </li>

                            <li>
                                <div className='badge rounded-pill bg-dark mx-3'></div>
                            </li>

                        </ul>

                    </div>
                </nav>
            }

        </header>
    )
}

export default NavBar;