
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useWishController from '../../../controllers/useWishController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import SearchBar from '../../../components/filter/SearchBar.js';
import SearchCount from '../../../components/filter/SearchCount.js';
import SortableHeader from '../../../components/sortable/SortableHeader.js';
import WishDetail from './WishDetail.js';
import WishDelete from './WishDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const Wishes = () => {

    const { t } = useTranslation();

    const defaultFilter = {
        attributes: [
            { name: 'code', display: t('countries.code') },
            { name: 'name', display: t('countries.name') }
        ]
    }

    /**
     * Page state
     */

    const { auth } = useAuthContext();

    const [filter, setFilter] = useState(defaultFilter);
    const [wish, setWish] = useState({});
    const wishController = useWishController(filter);

    /**
     * Page lifecycle
     */

    // eslint-disable-next-line 
    useEffect(() => { wishController.reload() }, [filter]);

    // eslint-disable-next-line 
    useEffect(() => { wishController.retranslate() }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const wish = await wishController.create();
        setWish(wish);
    }

    const handleDetail = (wish) => {
        setWish(wish);
    }

    const handleSave = async (wish) => {
        await wishController.save(wish);
    }

    const handleDelete = async (wish) => {
        await wishController.remove(wish);
    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!wishController.loaded} />
            <WishDetail id='detail' wish={wish} categories={wishController.categories} onSave={handleSave} />
            <WishDelete id='delete' wish={wish} onDelete={handleDelete} />

            <div className='row p-4'>

                <div className='d-flex align-items-center'>
                    <h5 className='text-muted mb-1'>{t('glossary.wishes')}</h5>
                    <SearchCount className='text-muted mb-1 ms-2' count={wishController.wishes.length} total={wishController.totalCount} />
                    <SearchBar className='ms-4' items={wishController.wishes} filter={filter} onSearch={handleFilter} />
                </div>

                <hr />


                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>
                                <SortableHeader attrName='name' filter={filter} onFilter={handleFilter}>
                                    {t('wishes.name')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='category' filter={filter} onFilter={handleFilter}>
                                    {t('wishes.category')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='order' filter={filter} onFilter={handleFilter}>
                                    {t('wishes.order')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='type' filter={filter} onFilter={handleFilter}>
                                    {t('wishes.type')}
                                </SortableHeader>
                            </th>
                            <th className='text-center'>
                                <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            wishController.wishes.map((wish, i) => {

                                const wishCategory = wishController.wishes.find(w => w.id === wish.parent);

                                return (
                                    <tr key={i} className='align-middle'>
                                        <td>{wish.name}</td>
                                        <td>{wishCategory?.name ?? '-'}</td>
                                        <td>{wish.order}</td>
                                        <td>{wish.type?.name ?? '-'}</td>
                                        <td className='text-center'><button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={() => handleDetail(wish)}><img src={edit_img} alt='edit' /></button></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default Wishes
