
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useMotionController from '../../../controllers/useMotionController.js';

import DateUtil from '../../../utils/DateUtil.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import SearchBar from '../../../components/filter/SearchBar.js';
import SearchCount from '../../../components/filter/SearchCount.js';
import SortableHeader from '../../../components/sortable/SortableHeader.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const Motions = () => {

    const { t } = useTranslation();

    const defaultFilter = {
        attributes: [
            { name: 'creation', display: t('motions.date') },
            { name: 'name', display: t('motions.name') },
            { name: 'ownerLastname', display: t('motions.owner') }
        ]
    }

    /**
     * Page state
     */

    const { auth } = useAuthContext();
    const navigate = useNavigate();
    const [filter, setFilter] = useState(defaultFilter);
    
    const motionController = useMotionController(filter);

    /**
     * Page lifecycle
     */

    // eslint-disable-next-line 
    useEffect(() => { motionController.reload() }, [filter, auth.company]);

    // eslint-disable-next-line 
    useEffect(() => { motionController.retranslate() }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        navigate('/manager/motions/new');
    }

    const handleDetail = (motion) => {
        navigate('/manager/motions/' + motion.id);
    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    return (

        <div className='container'>
            <div className='row p-4'>

                <LoadingIcon loading={!motionController.loaded} />

                <div className='d-flex align-items-center'>
                    <h5 className='text-muted mb-1'>{t('glossary.motions')}</h5>
                    <SearchCount className='text-muted mb-1 ms-2' count={motionController.motions.length} total={motionController.totalCount} />
                    <SearchBar className='ms-4' items={motionController.motions} filter={filter} onSearch={handleFilter} />
                </div>

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>
                                <SortableHeader attrName='creation' filter={filter} onFilter={handleFilter}>
                                    {t('motions.date')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='name' filter={filter} onFilter={handleFilter}>
                                    {t('motions.name')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='ownerLastname' filter={filter} onFilter={handleFilter}>
                                    {t('motions.owner')}
                                </SortableHeader>
                            </th>
                            <th className='text-center'>
                                <button className='btn btn-light' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            motionController.motions.map((motion) => (
                                <tr key={motion.id} className='align-middle'>
                                    <td>{DateUtil.toLocaleString(motion.creation)}</td>
                                    <td>{motion.name}</td>
                                    <td>{motion.owner?.firstname + ' ' + motion.owner?.lastname}</td>
                                    <td className='text-center'>
                                        <div className='btn-group'>
                                            <button className='btn btn-light' onClick={() => handleDetail(motion)}><img src={edit_img} alt='edit' /></button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default Motions
