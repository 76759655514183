
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';

const SERVER_URL = `${AppLogic.serverUrl}/pub/login`;

const AuthenticationService = () => {

    const login = async (credentials) => {
        return (await axios.post(`${SERVER_URL}`, credentials)).data.payload;
    }

    const autologin = async (token) => {
        return (await axios.post(`${SERVER_URL}/token`, { token })).data.payload;
    }

    const register = async (user) => {
        return (await axios.post(`${SERVER_URL}/register`, user )).data.payload;
    }

    const reset = async (email) => {
        return (await axios.post(`${SERVER_URL}/reset`, { email })).data.payload;
    }

    const password = async (code, password) => {
        return (await axios.post(`${SERVER_URL}/password`, { code, password })).data.payload;
    }
    
    const check = async (code) => {
        return (await axios.post(`${SERVER_URL}/check`, { code })).data.payload;
    }

    return {
        login,
        autologin,
        register,
        reset,
        password,
        check,
    }
}

export default AuthenticationService();