
import { useTranslation } from 'react-i18next';

const DropDown = ({ items, className, disabled = false, onToggleAll, onToggleOne, ...props }) => {

    /**s
     * Component state
     */

    const { t } = useTranslation();
    
    /**
     * Component lifecycle
     */
   
    const allSelected  = items && !items.find(item => !item.selected);
    const noneSelected = items && !items.find(item => item.selected);
    
    /**
     * Event handlers
     */

    const handleToggleAll = (e) => {
        e.allSelected = !allSelected;
        onToggleAll && onToggleAll(e);
    }

    const handleToggleOne = (e, item) => {
        e.item = item;
        onToggleOne && onToggleOne(e);
    }

    return (

        <div {...props} className={className ?? ''}>

            <div className={'btn btn-light dropdown-toggle border' + (disabled ? ' disabled' : '')} data-bs-toggle='dropdown'>
                {allSelected ? t('filter.all') : (noneSelected ? t('filter.none') : t('filter.some'))}
            </div>

            <ul className='dropdown-menu'>

                <li className='dropdown-item'>
                    <input type='checkbox' className='form-check-input' checked={allSelected ?? false} onChange={handleToggleAll} />
                    <label className='form-check-label ps-2' onClick={handleToggleAll}>{t('filter.all')}</label>
                </li>

                <hr className='dropdown-divider' />

                {
                    items?.map((item, i) => {
                        return (
                            <li key={i} className='dropdown-item'>
                                <input type='checkbox' className='form-check-input' checked={item.selected ?? false} onChange={(e) => handleToggleOne(e, item)} />
                                <label className='form-check-label ps-2' onClick={(e) => handleToggleOne(e, item)}>{item.label}</label>
                            </li>
                        )
                    })
                }

            </ul>

        </div>
    )
}

export default DropDown;