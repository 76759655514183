
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../controllers/useAuthContext.js';
import useAuthenticationController from '../../controllers/useAuthenticationController.js';

const Logout = () => {

    const { auth } = useAuthContext();
    const navigate = useNavigate();
    const controller = useAuthenticationController();

    // Page loaded, redirect to login page
    useEffect(() => { 

        controller.logout();
        navigate('/login', { replace: true });

    }, [auth, controller, navigate]);
}

export default Logout
