
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useModalEffect from '../../../hooks/useModalEffect.js';
import useWishController from '../../../controllers/useWishController.js';

const WishDetail = ({ id, wish, categories, onSave }) => {

    /**
     * Page state
     */

    const modalRef = useRef();
    const { t } = useTranslation();
    const [tmpWish, setTmpWish] = useState({});
    const [wishTypes, setWishTypes] = useState([]);
    const [deletable, setDeletable] = useState(false);

    const wishController = useWishController();

    /**
     * Page lifecycle
     */

    useModalEffect(modalRef, async () => {

        setTmpWish(wish);

        const wishTypes = await wishController.getWishTypes();
        setWishTypes(wishTypes);

        const deletable = await wishController.deletable(wish);
        setDeletable(deletable);
    })

    /**
     * Event handlers
     */

    const handleChange = (attributes) => {
        setTmpWish({ ...tmpWish, ...attributes });
    }

    return (

        <div id={id} className='modal fade' ref={modalRef}>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('glossary.wish')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className='modal-body'>

                        <div className='row'>

                            <div className='col-3 form-group'>
                                <label htmlFor='code'>{t('wishes.code')}</label>
                                <input id='code' type='text' className='form-control' value={tmpWish.code ?? ''} placeholder={t('wishes.code-placeholder')} onChange={(e) => handleChange({ code: e.target.value })} />
                            </div>

                            <div className='col form-group'>
                                <label htmlFor='name'>{t('wishes.name')}</label>
                                <input id='name' type='text' className='form-control' value={tmpWish.name ?? ''} placeholder={t('wishes.name-placeholder')} onChange={(e) => handleChange({ name: e.target.value })} />
                            </div>

                        </div>

                        <div className='row pt-3'>

                            <div className='col-3 form-group'>
                                <label htmlFor='order'>{t('wishes.order')}</label>
                                <input id='order' type='text' className='form-control' value={tmpWish.order ?? ''} placeholder={t('wishes.order-placeholder')} onChange={(e) => handleChange({ order: e.target.value })} />
                            </div>

                            <div className='col form-group'>
                                <label htmlFor='category'>{t('wishes.category')}</label>
                                <select id='category' className='form-select' value={tmpWish.parent ?? ''} onChange={(e) => handleChange({ parent: e.target.value })}>
                                    <option value=''>{t('glossary.none')}</option>
                                    {categories?.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                </select>
                            </div>

                        </div>

                        <div className='row pt-3'>

                            <fieldset className='col form-group border rounded mx-3'>

                                <legend className={'float-none w-auto m-3 px-2 fs-6'}>{t('wishes.type')}</legend>

                                <div className='d-flex flex-column align-items-center'>
                                    <span className='mb-4'>
                                        {
                                            wishTypes.map(wishType => (
                                                <div key={wishType.id} className='form-check'>
                                                    <label htmlFor={wishType.name} className='form-check-label'>{wishType.name}</label>
                                                    <input id={wishType.name} className='form-check-input' type='radio' checked={tmpWish.type?.id === wishType.id} onChange={(e) => handleChange({ type: wishType.id })} />
                                                </div>
                                            ))
                                        }
                                    </span>
                                </div>

                            </fieldset>

                        </div>
                    </div>

                    <div className='modal-footer'>
                        <div className='d-flex justify-content-between w-100'>

                            <div>
                                <button type='button' className='btn btn-light' data-bs-dismiss='modal' data-bs-toggle='modal' data-bs-target='#delete' disabled={!deletable}>{t('button.delete')}</button>
                            </div>

                            <div className='btn-group'>
                                <button className='btn btn-light' data-bs-dismiss='modal' onClick={() => onSave(tmpWish)}>{t('button.save')}</button>
                                <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default WishDetail;
