
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import useUserRoleController from '../controllers/useUserRoleController.js';
import useCompanyController from '../controllers/useCompanyController.js';
import useCountryController from '../controllers/useCountryController.js';
import useUserTypeController from '../controllers/useUserTypeController.js';
import usePositionTypeController from '../controllers/usePositionTypeController.js';
import useWorkplaceController from '../controllers/useWorkplaceController.js';
import UserService from '../api/UserService.js';

const useUserController = (company, filter) => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();
    
    const userRoleController = useUserRoleController();
    const companyController = useCompanyController();
    const countryController = useCountryController();
    const positionTypeController = usePositionTypeController();
    const userTypeController = useUserTypeController();
    const workplaceController = useWorkplaceController();

    const [users, setUsers] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    /**
     * Functionalities
     */

    const reload = async () => {

        try {
            setLoaded(false);
            const users = await UserService.getUsers(company, filter);
            users.forEach(c => translate(c, auth.lang));
            setUsers(users);

            const totalCount = await UserService.getTotalCount(company);
            setTotalCount(totalCount);
        }
        finally {
            setLoaded(true);
        }
    }

    const getUser = async (id) => {
        return id != null && await UserService.getUser(id);
    }

    const getMatchings  = async (userId) => {
        return  await UserService.getMatchings(userId);
    }

    const deletable = async (user) => {
        return user.id != null && await UserService.isDeletable(user);
    }

    const create = async () => {
        const user = await UserService.create(auth.company);
        translate(user, auth.lang);
        return user;
    }

    const save = async (user) => {

        try {
            untranslate(user);
            await UserService.save(user);
        }
        finally {
            translate(user, auth.lang);
        }

        reload();
    }

    const remove = async (user) => {
        await UserService.remove(user);
        reload();
    }

    const translate = (user, lang) => {
        user.roles && user.roles.forEach(role => userRoleController.translate(role, lang));
        user.company && companyController.translate(user.company, lang);
        user.country && countryController.translate(user.country, lang);
        user.userType && userTypeController.translate(user.userType, lang);
        user.positionType && positionTypeController.translate(user.positionType, lang);
        user.workplace && workplaceController.translate(user.workplace, lang);
    }

    const untranslate = (user) => {
        user.roles && user.roles.forEach(role => userRoleController.untranslate(role));
        user.company && companyController.untranslate(user.company);
        user.country && countryController.untranslate(user.country);
        user.userType && userTypeController.untranslate(user.userType);
        user.positionType && positionTypeController.untranslate(user.positionType);
        user.workplace && workplaceController.untranslate(user.workplace);
    }

    const retranslate = () => {

        users.forEach(user => {
            untranslate(user);
            translate(user, auth.lang);
        });

        setUsers([...users]);
    }

    return {
        users,
        totalCount,
        loaded,
        reload,
        getUser,
        getMatchings,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate
    }
}

export default useUserController;