
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useFormationController from '../../../controllers/useFormationController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import SearchBar from '../../../components/filter/SearchBar.js';
import SearchCount from '../../../components/filter/SearchCount.js';
import SortableHeader from '../../../components/sortable/SortableHeader.js';
import FormationDetail from './FormationDetail.js';
import FormationDelete from './FormationDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const Formations = () => {

    const { t } = useTranslation();

    const defaultFilter = {
        attributes: [
            { name: 'year', display: 'Year' },
            { name: 'school', display: 'School' },
            { name: 'diploma', display: 'Diploma' },
            { name: 'diplomaType', display: 'Type' },
        ]
    }

    /**
     * Page state
    */

    const { auth } = useAuthContext();
    const [filter, setFilter] = useState(defaultFilter);
    const formationController = useFormationController(auth.user, filter);
    const [formation, setFormation] = useState({});

    /**
     * Page lifecycle
     */

    // eslint-disable-next-line 
    useEffect(() => { formationController.reload() }, [filter, auth.user]);

    // eslint-disable-next-line 
    useEffect(() => { formationController.retranslate() }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const formation = await formationController.create(auth.user);
        setFormation(formation);
    }

    const handleDetail = (formation) => {
        setFormation(formation);
    }

    const handleSave = async (formation) => {
        await formationController.save(formation);
    }

    const handleDelete = async (formation) => {
        await formationController.remove(formation);
    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!formationController.loaded} />
            <FormationDetail id='detail' formation={formation} onSave={handleSave} />
            <FormationDelete id='delete' formation={formation} onDelete={handleDelete} />

            <div className='row p-4'>

                <div className='d-flex align-items-center'>
                    <h5 className='text-muted mb-1'>{t('profile.formations')}</h5>
                    <SearchCount className='text-muted mb-1 ms-2' count={formationController.formations.length} total={formationController.totalCount} />
                    <SearchBar className='ms-4' items={formationController.formations} filter={filter} onSearch={handleFilter} />
                </div>

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>
                                <SortableHeader attrName='year' filter={filter} onFilter={handleFilter}>
                                    {t('formations.year')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='school' filter={filter} onFilter={handleFilter}>
                                    {t('formations.school')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='diploma' filter={filter} onFilter={handleFilter}>
                                    {t('formations.diploma')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='diplomaType' filter={filter} onFilter={handleFilter}>
                                    {t('formations.diplomaType')}
                                </SortableHeader>
                            </th>
                            <th className='text-center'>
                                <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            formationController.formations.map((formation) => (
                                <tr key={formation.id} className='align-middle'>
                                    <td>{formation.year ?? '-'}</td>
                                    <td>{formation.school ?? '-'}</td>
                                    <td>{formation.diploma ?? '-'}</td>
                                    <td>{formation.diplomaType?.name ?? '-'}</td>
                                    <td className='text-center'><button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={() => handleDetail(formation)}><img src={edit_img} alt='edit' /></button></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default Formations
