
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useInvitationController from '../../../controllers/useInvitationController.js';

import DateUtil from '../../../utils/DateUtil.js';
import SurveyLogic from '../../../logic/SurveyLogic.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import SearchBar from '../../../components/filter/SearchBar.js';
import SearchCount from '../../../components/filter/SearchCount.js';
import SortableHeader from '../../../components/sortable/SortableHeader.js';

import edit_img from '../../../assets/images/pen.svg';

const Invitations = () => {

    const { t } = useTranslation();

    const defaultFilter = {
        attributes: [
            { name: 'creation', display: t('surveys.date') },
            { name: 'dueDate', display: t('surveys.duedate') },
            { name: 'name', display: t('surveys.name') },
            { name: 'ownerLastname', display: t('surveys.owner') },
            { name: 'status', display: t('surveys.status') },
        ]
    }

    /**
     * Page state
     */

    const { auth } = useAuthContext();
    const navigate = useNavigate();
    const [filter, setFilter] = useState(defaultFilter);

    const invitationController = useInvitationController(filter);

    /**
     * Page lifecycle
     */

    // eslint-disable-next-line 
    useEffect(() => { invitationController.reload() }, [filter]);

    // eslint-disable-next-line 
    useEffect(() => { invitationController.retranslate() }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleDetail = (invitation) => {
        navigate('/user/invitations/' + invitation.id);
    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    return (

        <div className='container'>
            <div className='row p-4'>

                <LoadingIcon loading={!invitationController.loaded} />

                <div className='d-flex align-items-center'>
                    <h5 className='text-muted mb-1'>{t('glossary.surveys')}</h5>
                    <SearchCount className='text-muted mb-1 ms-2' count={invitationController.invitations.length} total={invitationController.totalCount} />
                    <SearchBar className='ms-4' items={invitationController.invitations} filter={filter} onSearch={handleFilter} />
                </div>

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>
                                <SortableHeader attrName='creation' filter={filter} onFilter={handleFilter}>
                                    {t('surveys.date')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='name' filter={filter} onFilter={handleFilter}>
                                    {t('surveys.name')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='ownerLastname' filter={filter} onFilter={handleFilter}>
                                    {t('surveys.guest')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='companyName' filter={filter} onFilter={handleFilter}>
                                    {t('glossary.company')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='invitationStatus' filter={filter} onFilter={handleFilter}>
                                    {t('surveys.status')}
                                </SortableHeader>
                            </th>
                            <th className='text-center'>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            invitationController.invitations.map((invitation) => (
                                <tr key={invitation.id} className='align-middle'>
                                    <td>{DateUtil.toLocaleString(invitation.creation)}</td>
                                    <td>{invitation.survey.name}</td>
                                    <td>{invitation.survey.owner.email}</td>
                                    <td>{invitation.survey.company.name}</td>
                                    <td>{SurveyLogic.toStringStatus(invitation.status)}</td>
                                    <td className='text-center'><button className='btn btn-light' onClick={() => handleDetail(invitation)}><img src={edit_img} alt='edit' /></button></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default Invitations
