
const FileUtil = () => {

    const readFile = (filename) => {

        return new Promise((resolve, reject) => {

            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsText(filename);
        })        
    }

    const readJson = async (filename) => {
        const text = await readFile(filename);
        return JSON.parse(text);
    }

    return {
        readFile,
        readJson
    }
}

export default FileUtil();