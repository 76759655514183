
const ImageUtil = () => {

    const loadImage = (filepath, done) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => done && done(reader.result) );
        reader.readAsDataURL(filepath);
    }

    const cropImage = (image, crop) => {

        // Create the target of the cropped image (canvas)
        const canvas = document.createElement('canvas');
        canvas.width  = crop.width;
        canvas.height = crop.height;

        const ctx = canvas.getContext('2d');
        const scaleX = image.naturalWidth  / image.width;
        const scaleY = image.naturalHeight / image.height;

        ctx.drawImage(image, 
            crop.x * scaleX, 
            crop.y * scaleY, 
            crop.width * scaleX, 
            crop.height * scaleY, 
            0, 0, crop.width, crop.height);
        
        // Convert the image into a png/base64
        return canvas.toDataURL();
    }

    return {
        loadImage,
        cropImage
    }
}

export default ImageUtil();