
import { useTranslation } from 'react-i18next';

const TabRights = ({ user, userRoles, showCompany, onChange }) => {

    const { t } = useTranslation();

    /**
     * Event handlers
     */

    const hasUserRole = (role) => {
        return user.roles?.find(r => r.id === role.id);
    }

    const handleUserRoleChange = (e) => {

        let newRoles = null;

        if (e.target.checked) {
            const activatedRole = userRoles.find(r => r.id === e.target.id);
            newRoles = [...user.roles, activatedRole];
        }
        else {
            newRoles = user.roles.filter(r => r.id !== e.target.id);
        }

        onChange({ ...user, roles: newRoles });
    }


    return (

        <div className='container'>

            <div className='row border rounded p-4 mb-3'>

                <label>{t('glossary.userroles')}</label>

                <div className='col form-group mx-4 my-2'>
                    {
                        userRoles.map((role) => {

                            // Forbid managers to use administrator roles
                            if (!showCompany && role.code === 'ADMIN') return;

                            return (
                                <div className='form-check' key={role.id}>
                                    <input id={role.id} type='checkbox' className='form-check-input' checked={hasUserRole(role) ?? ''} onChange={handleUserRoleChange} />
                                    <label htmlFor={role.id} className='form-check-label'>{role.name}</label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className='row border rounded p-4 mb-3'>

                <label className=''>{t('users.security')}</label>

                <div className='col form-group mx-4 my-2'>

                    <div className='form-check'>
                        <input id='verified' type='checkbox' className='form-check-input' checked={user.verified || false} onChange={(e) => onChange({ verified: !user.verified })} />
                        <label htmlFor='verified' className='form-check-label'>{t('users.verified')}</label>
                    </div>

                    <div className='form-check'>
                        <input id='disabled' type='checkbox' className='form-check-input' checked={user.disabled || false} onChange={(e) => onChange({ disabled: !user.disabled })} />
                        <label htmlFor='disabled' className='form-check-label'>{t('users.disabled')}</label>
                    </div>

                </div>

            </div>

        </div>

    )
}

export default TabRights;
