
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';

const SERVER_PUBLIC_URL = `${AppLogic.serverUrl}/pub/cv`;
const SERVER_PRIVATE_URL = `${AppLogic.serverUrl}/api/curriculum`;

const CurriculumService = () => {

    const getPrivateCurriculum = async (userId) => {
        return (await axios.get(`${SERVER_PRIVATE_URL}/${userId}`)).data.payload;
    }

    const getPublicCurriculum = async (userId) => {
        return (await axios.get(`${SERVER_PUBLIC_URL}/${userId}`)).data.payload;
    }

    const getMotionCurriculum = async (userId, motionId) => {
        const url = `${SERVER_PRIVATE_URL}/${userId}/${motionId}`;
        return (await axios.get(url)).data.payload;
    }

    return {
        getPrivateCurriculum,
        getPublicCurriculum,
        getMotionCurriculum
    }
}

export default CurriculumService();