
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useUserTypeController from '../../../controllers/useUserTypeController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import SearchBar from '../../../components/filter/SearchBar.js';
import SearchCount from '../../../components/filter/SearchCount.js';
import SortableHeader from '../../../components/sortable/SortableHeader.js';
import UserTypeDetail from './UserTypeDetail.js';
import UserTypeDelete from './UserTypeDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const UserTypes = () => {

    const { t } = useTranslation();

    const defaultFilter = {
        attributes: [
            { name: 'code', display: t('usertypes.code') },
            { name: 'name', display: t('usertypes.name') },
            { name: 'description', display: t('usertypes.description') }
        ]
    }

    /**
     * Page state
     */

    const { auth } = useAuthContext();

    const [filter, setFilter] = useState(defaultFilter);
    const [userType, setUserType] = useState({});
    
    const userTypeController = useUserTypeController(true, filter);

    /**
     * Page lifecycle
     */

    // eslint-disable-next-line 
    useEffect(() => { userTypeController.reload() }, [filter]);

    // eslint-disable-next-line 
    useEffect(() => { userTypeController.retranslate() }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const usertype = await userTypeController.create();
        setUserType(usertype);
    }

    const handleDetail = (usertype) => {
        setUserType(usertype);
    }

    const handleSave = async (usertype) => {
        await userTypeController.save(usertype);
    }

    const handleDelete = async (usertype) => {
        await userTypeController.remove(userType);
    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!userTypeController.loaded} />
            <UserTypeDetail id='detail' usertype={userType} onSave={handleSave} />
            <UserTypeDelete id='delete' usertype={userType} onDelete={handleDelete} />

            <div className='row p-4'>

                <div className='d-flex align-items-center'>
                    <h5 className='text-muted mb-1'>{t('glossary.usertypes')}</h5>
                    <SearchCount className='text-muted mb-1 ms-2' count={userTypeController.userTypes.length} total={userTypeController.totalCount} />
                    <SearchBar className='ms-4' items={userTypeController.userTypes} filter={filter} onSearch={handleFilter} />
                </div>

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>
                                <SortableHeader attrName='code' filter={filter} onFilter={handleFilter}>
                                    {t('usertypes.code')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='name' filter={filter} onFilter={handleFilter}>
                                    {t('usertypes.name')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='description' filter={filter} onFilter={handleFilter}>
                                    {t('usertypes.description')}
                                </SortableHeader>
                            </th>
                            <th className='text-center'><button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={handleNew}><img src={new_img} alt='new' /></button></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            userTypeController.userTypes.map((usertype) => (
                                <tr key={usertype.id} className='align-middle'>
                                    <td>{usertype.code}</td>
                                    <td>{usertype.name}</td>
                                    <td>{usertype.description}</td>
                                    <td className='text-center'><button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={() => handleDetail(usertype)}><img src={edit_img} alt='edit' /></button></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default UserTypes
