
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InventoryLogic from '../../logic/InventoryLogic.js';

import eyeon_img from '../../assets/images/eye-enabled.svg';
import eyeoff_img from '../../assets/images/eye-disabled.svg';

import './UserListMultiSelect.css';

const UserListMultiSelect = ({ users, onReset, onToggle }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const [sortedUsers, setSortedUsers] = useState([]);

    /**
     * Page lifecycle
     */

    useEffect(() => {

        const visibles = InventoryLogic.getVisibles(users);
        InventoryLogic.sortUsers(visibles, 'alpha-asc');
        
        setSortedUsers(visibles);

    }, [users])
    
    /**
     * Event handlers
     */

    const handleReset = () => {
        onReset();
    }    

    const handleToggle = (user) => {
        onToggle(user);
    }    

    return (
        <div className='userlist border rounded mt-2 p-2'>

            <div className='btn btn-secondary mb-3' onClick={handleReset}>
                {t('button.reset')}
            </div>

            <table className='table table-sm table-hover'>
                <tbody>
                    {
                        sortedUsers.map(user => (

                            <tr key={user.id} onClick={() => handleToggle(user)}>

                                <td className={user.selected ? '' : ' text-muted'}>
                                    {user.firstname} {user.lastname}
                                </td>

                                <td> 
                                    <img src={user.selected ? eyeon_img : eyeoff_img} alt='enabled' />
                                </td>
                                
                            </tr>
                        ))
                    }
                </tbody>
            </table>

        
        </div>
    )
}

export default UserListMultiSelect;