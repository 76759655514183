
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthenticationController from '../../controllers/useAuthenticationController.js';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import LoginLogic from '../../logic/LoginLogic.js';


import './Login.css';

const Register = () => {

    const { t, i18n } = useTranslation();
    const [ queryParams ] = useSearchParams();
    const navigate = useNavigate();
    const genders = t('common.genders');
    const languages = t('common.languages');

    const controller = useAuthenticationController();

    /**
     * Page state
     */

    const [step, setStep] = useState('start');

    const [username, setUsername] = useState('');
    const [companyId, setCompanyId] = useState();
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [gender, setGender] = useState('');
    const [language, setLanguage] = useState('');

    const [usernameInvalid, setUsernameInvalid] = useState(false);
    const [firstnameInvalid, setFirstnameInvalid] = useState(false);
    const [lastnameInvalid, setLastnameInvalid] = useState(false);

    /**
     * Page lifecycle
     */

    useEffect(() => {

        // Detect lang
        if (queryParams.has('lang')) {
            const lang = queryParams.get('lang');
            i18n.changeLanguage(lang);
        }

        // Detect company register
        if (queryParams.has('company')) {
            const companyId = queryParams.get('company');
            setCompanyId(companyId);
        }

        // eslint-disable-next-line 
    }, [queryParams]);

    /**
     * Event handlers
     */

    const handleGenderChange = (e) => {
        const gender = genders.find(g => g.id === e.target.value);
        setGender(gender.id);
    }

    const handleLanguageChange = (e) => {
        const language = languages.find(c => c.id === e.target.value);
        setLanguage(language.id);
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        e.stopPropagation();

        if (step === 'start') {

            try {
                const emailValid = LoginLogic.isEmailValid(username);
                const firstnameValid = LoginLogic.isNameValid(firstname);
                const lastnameValid = LoginLogic.isNameValid(lastname);
    
                setUsernameInvalid(!emailValid);
                setFirstnameInvalid(!firstnameValid);
                setLastnameInvalid(!lastnameValid);
    
                if (emailValid && firstnameValid && lastnameValid) {
                    await controller.registerUser({ username, firstname, lastname, gender, language, company: companyId });
                    setStep('done');
                }
            }
            catch (e) {
                console.error(e);
                navigate('/login', { replace: true });
            }
        }
        else if (step === 'done') {
            navigate('/login', { replace: true });
        }

    }

    return (
        <div className='container d-flex justify-content-center my-5'>
            <form className='login-form' onSubmit={handleSubmit} noValidate>

                <div className='text-center mb-5'>
                    <h3>{t('register.title')}</h3>
                </div>
                
                {
                    step === 'start' &&
                    <fieldset>

                        <div className='form-group mb-3'>
                            <label>{t('users.email')}</label>
                            <input type='email' className={'form-control' + (usernameInvalid ? ' is-invalid' : '')} placeholder={t('register.username-placeholder') + ' *'} value={username} autoComplete='off' autoFocus onChange={(e) => setUsername(e.target.value)} required />
                            <div className='invalid-feedback'>{t('register.username-invalid')}</div>
                        </div>

                        <div className='row pt-2'>

                            <div className='col form-group'>
                                <label>{t('users.firstname')}</label>
                                <input type='text' className={'form-control' + (firstnameInvalid ? ' is-invalid' : '')} placeholder={t('register.firstname-placeholder') + ' *'} value={firstname} onChange={(e) => setFirstname(e.target.value)} required />
                                <div className='invalid-feedback'>{t('register.firstname-invalid')}</div>
                            </div>

                            <div className='col form-group'>
                                <label>{t('users.lastname')}</label>
                                <input type='text' className={'form-control' + (lastnameInvalid ? ' is-invalid' : '')} placeholder={t('register.lastname-placeholder') + ' *'} value={lastname} onChange={(e) => setLastname(e.target.value)} required />
                                <div className='invalid-feedback'>{t('register.lastname-invalid')}</div>
                            </div>

                        </div>

                        <div className='row pt-2'>

                            <div className='col form-group'>
                                <label htmlFor='gender'>{t('users.gender')}</label>
                                <select id='gender' className='form-select' value={gender} onChange={handleGenderChange}>
                                    <option value={null}>{t('glossary.none')}</option>
                                    {genders.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                </select>
                            </div>

                            <div className='col form-group'>
                                <label htmlFor='language'>{t('users.language')}</label>
                                <select id='language' className='form-select' value={language} onChange={handleLanguageChange}>
                                    <option value={null}>{t('glossary.none')}</option>
                                    {languages?.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                </select>
                            </div>

                        </div>

                        <div className='form-group pt-4 mb-5 d-flex justify-content-center'>
                            <button type='submit' className='btn btn-primary btn-login'>{t('button.create')}</button>
                        </div>

                        {
                            !companyId &&
                            <div className='form-group'>
                                <Link to='/login' className='link'>&larr; {t('button.back')}</Link>
                            </div>
                        }

                    </fieldset>
                }
                {
                    step === 'done' &&
                    <fieldset>

                        <div className='form-group mb-3'>
                            {t('register.message')}
                        </div>

                        <div className='form-group pt-4 mb-5 d-flex justify-content-center'>
                            <button type='submit' className='btn btn-primary btn-login'>{t('button.login')}</button>
                        </div>

                    </fieldset>
                }

            </form>

        </div>
    )
}

export default Register
