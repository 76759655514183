
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useUserController from '../../../controllers/useUserController.js';
import TabLogin from './TabLogin.js';
import TabPersonal from './TabPersonal.js';
import TabAddress from './TabAddress.js';

const Account = () => {

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const navigate = useNavigate();

    /**
     * Page state
     */

    const [user, setUser] = useState({});
    const userController = useUserController();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {
            const user = await userController.getUser(auth.user.id);
            setUser(user);
        })()

        // eslint-disable-next-line 
    }, [auth.user]);

    /**
     * Event handlers
     */

    const handleChange = (attributes) => {
        setUser({ ...user, ...attributes });
    }

    const handleSave = async (user) => {
        await userController.save(user);
        navigate('/home');
    }

    const handleDelete = async (user) => {
        navigate('/home');
    }

    const handleCancel = () => {
        navigate('/home');
    }

    return (

        <div className='container'>
            <div className='row p-4'>

                <h5 className='table-title text-muted'>{t('profile.account')}</h5>

                <hr />

                <ul className='nav nav-tabs'>
                    <li>
                        <button className='nav-link active' data-bs-toggle='tab' data-bs-target='#tab1' type='button'>{t('profile.tablogin')}</button>
                    </li>
                    <li>
                        <button className='nav-link' data-bs-toggle='tab' data-bs-target='#tab2' type='button'>{t('profile.tabpersonal')}</button>
                    </li>
                    <li>
                        <button className='nav-link' data-bs-toggle='tab' data-bs-target='#tab3' type='button'>{t('profile.tabaddress')}</button>
                    </li>
                </ul>

                <div className='tab-content py-4'>

                    <div id='tab1' className='tab-pane fade show active'>
                        <TabLogin user={user} onChange={handleChange} />
                    </div>
                    <div id='tab2' className='tab-pane fade'>
                        <TabPersonal user={user} onChange={handleChange} />
                    </div>
                    <div id='tab3' className='tab-pane fade'>
                        <TabAddress user={user} onChange={handleChange} />
                    </div>

                </div>

                <div className='row pt-4'>

                    <hr />

                    <div className='d-flex justify-content-between'>
                        <div className='btn-group'>
                            <button className='btn btn-light' disabled={true} onClick={() => handleDelete(user)}>{t('button.delete')}</button>
                        </div>
                        <div className='btn-group'>
                            <button className='btn btn-light' onClick={() => handleSave(user)}>{t('button.save')}</button>
                            <button className='btn btn-light' onClick={handleCancel}>{t('button.cancel')}</button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Account
