
import i18next from 'i18next';

const SurveyLogic = () => {

    const markAsVisible = (users) => {
        users.forEach(i => { i.visible = true });
    }

    const markAsSelected = (users) => {
        users.forEach(i => { i.selected = true });
    }

    const getSelected = (users) => {
        return users.filter(i => i.selected);
    }

    const getVisibles = (users) => {
        return users.filter(i => i.visible && !i.deleted);
    }

    const toStringStatus = (status) => {
        const translations = i18next.t('common.status');
        const obj = translations.find(s => s.id === status);
        return obj ? obj.name.toUpperCase() : '';
    }

    return {
        markAsVisible,
        markAsSelected,
        getSelected,
        getVisibles,
        toStringStatus
    }
}

export default SurveyLogic();