
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';
import FilterUtil from '../utils/FilterUtil.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/companies`;

const CompanyService = () => {

    const getCompanies = async (filter) => {
        const companies = (await axios.get(`${SERVER_URL}`)).data.payload;
        return FilterUtil.filter(companies, filter);
    }

    const getCompany = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const getTotalCount = async () => {
        return (await axios.get(`${SERVER_URL}/count`)).data.payload;
    }

    const create = async () => {
        return (await axios.get(`${SERVER_URL}/create`)).data.payload;
    }

    const isDeletable = async (company) => {
        return (await axios.get(`${SERVER_URL}/${company.id}/deletable`)).data.payload;
    }

    const save = async (company) => {

        return company.id
            ? (await axios.put(`${SERVER_URL}/${company.id}`, company)).data.payload
            : (await axios.post(`${SERVER_URL}`, company)).data.payload;
    }

    const remove = async (company) => {
        await axios.delete(`${SERVER_URL}/${company.id}`);
    }

    return {
        getCompanies,
        getCompany,
        getTotalCount,
        create,
        isDeletable,
        save,
        remove
    }
}

export default CompanyService();