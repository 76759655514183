
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useModalEffect from "../../../hooks/useModalEffect.js";
import { useAuthContext } from '../../../controllers/useAuthContext.js';

const InvitationSend = ({ id, survey, onSend }) => {

    /**
     * Page state
     */

    const { auth } = useAuthContext();
    const { t, i18n } = useTranslation();
    const modalRef = useRef();

    const languages = i18n.supportedLanguages ?? [];
    const [mailfields, setMailFields] = useState({});
    const [translatedFields, setTranslatedFields] = useState({});
    const [lang, setLang] = useState();

    /**
     * Page lifecycle
     */

    useModalEffect(modalRef, () => {

        const lang = auth.lang;
        const mailfields = { ...survey.mailfields } ?? {};
        const translatedFields = mailfields.hasOwnProperty(auth.lang) ? mailfields[lang] : {};

        setLang(lang);
        setMailFields(mailfields);
        setTranslatedFields(translatedFields);
    })

    useEffect(() => {
        
        const translatedFields = mailfields.hasOwnProperty(lang) ? mailfields[lang] : {};
        setTranslatedFields(translatedFields);

        // eslint-disable-next-line
    }, [lang])

    /**
     * Event handlers
     */

    const handleLangChange = (e) => {
        setLang(e.target.value);
    }

    const handleChange = (attributes) => {
        mailfields[lang] = { ...mailfields[lang], ...attributes };
        setMailFields(mailfields);
        setTranslatedFields(mailfields[lang]);
    }

    const handleSend = () => {
        onSend(mailfields);
    }

    return (

        <div id={id} className='modal fade' ref={modalRef}>
            <div className='modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable'>
                <div className='modal-content'>
    
                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('invitations.send')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className='modal-body'>
                        <div className='container'>
    
                            <div className='row col-3 ms-auto'>
                                <div className='d-flex align-items-center'>
                                    <label>{t('glossary.language')}</label>
                                    <select id='language' className='form-select ms-2' value={lang ?? ''} onChange={handleLangChange}>
                                        {languages?.map((lang, i) => <option key={i} value={lang}>{lang}</option>)}
                                    </select>
                                </div>
                            </div>

                            <div className='row'>

                                <div className='form-group col'>
                                    <label>{t('invitations.mailsubject')}</label>
                                    <input type='text' className='form-control' value={translatedFields.subject ?? ''} placeholder={t('invitations.mailsubject-placeholder')} onChange={(e) => handleChange({ 'subject': e.target.value })} />
                                </div>

                                <div className='form-group col'>
                                    <label>{t('invitations.mailtitle')}</label>
                                    <input type='text' className='form-control' value={translatedFields.title ?? ''} placeholder={t('invitations.mailtitle-placeholder')} onChange={(e) => handleChange({ 'title': e.target.value })} />
                                </div>

                            </div>

                            <div className='row form-group pt-2'>
                                <label>{t('invitations.mailheader')}</label>
                                <textarea className='form-control' rows='1' value={translatedFields.header ?? ''} placeholder={t('invitations.mailheader-placeholder')} onChange={(e) => handleChange({ 'header': e.target.value })} />
                            </div>

                            <div className='row form-group pt-2'>
                                <label>{t('invitations.mailcontent')}</label>
                                <textarea className='form-control' rows='6' value={translatedFields.content ?? ''} placeholder={t('invitations.mailcontent-placeholder')} onChange={(e) => handleChange({ 'content': e.target.value })} />
                            </div>

                        </div>
                    </div>

                    <div className='modal-footer'>

                        <div className='btn-group'>
                            <button className='btn btn-light' data-bs-dismiss='modal' onClick={handleSend}>{t('button.send')}</button>
                            <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default InvitationSend;
