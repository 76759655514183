
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../controllers/useAuthContext.js';

import useWorkplaceController from '../../controllers/useWorkplaceController.js';
import usePositionTypeController from '../../controllers/usePositionTypeController.js';
import useUserTypeController from '../../controllers/useUserTypeController.js';

import DropDown from '../filter/DropDown.js';
import InventoryLogic from '../../logic/InventoryLogic.js';

const FiltersCharts = ({ onChange, onReset }) => {

    const { auth } = useAuthContext();
    const { t } = useTranslation();
    const defaultFilters = InventoryLogic.createDefaultFilters();

    /**
     * Page state
     */

    const [filters, setFilters] = useState(defaultFilters);
    const workplaceController = useWorkplaceController();
    const positionTypeController = usePositionTypeController();
    const userTypeController = useUserTypeController();

    /**
     * Page lifecycle
     */

    // Reload lists if company change
    useEffect(() => {

        workplaceController.reload();
        positionTypeController.reload();
        userTypeController.reload();

        // eslint-disable-next-line 
    }, [auth.company])

    // Refresh filter, if lists are all loaded
    useEffect(() => {

        const filters = InventoryLogic.createFilters(
            workplaceController.workplaces,
            positionTypeController.positionTypes,
            userTypeController.userTypes);

        setFilters(filters);

        // eslint-disable-next-line 
    }, [workplaceController.loaded, positionTypeController.loaded, userTypeController.loaded])

    // Retranslate if language change
    useEffect(() => {

        workplaceController.retranslate();
        positionTypeController.retranslate();
        userTypeController.retranslate();

        // eslint-disable-next-line 
    }, [auth.lang])

    /**
     * Event handlers
     */

    const handleReset = () => {

        Object.values(filters).forEach(filter => {
            filter.allSelected = true;
            filter.items.forEach(item => item.selected = true);
        })

        onReset({ ...filters });
    }

    const handleToggleAll = (e, filter) => {
        e.stopPropagation();
        filters[filter.name].items.forEach(item => item.selected = e.allSelected);
        onChange({ ...filters });
    }

    const handleToggleOne = (e) => {
        e.stopPropagation();
        const item = e.item;
        item.selected = !item.selected;
        onChange({ ...filters });
    }

    return (
        <div className='d-flex border rounded mb-2 p-2'>
            <div className='d-flex justify-content-around flex-wrap flex-grow-1'>

                <div className='form-group text-center m-2'>
                    <label>{t('charts.ages-ranges')}</label>
                    <DropDown items={filters.ageRange.items} onToggleAll={(e) => handleToggleAll(e, filters.ageRange)} onToggleOne={handleToggleOne} />
                </div>

                <div className='form-group text-center m-2'>
                    <label>{t('glossary.workplaces')}</label>
                    <DropDown items={filters.workplace.items} onToggleAll={(e) => handleToggleAll(e, filters.workplace)} onToggleOne={handleToggleOne} />
                </div>

                <div className='form-group text-center m-2'>
                    <label>{t('glossary.userroles')}</label>
                    <DropDown items={filters.positionType.items} onToggleAll={(e) => handleToggleAll(e, filters.positionType)} onToggleOne={handleToggleOne} />
                </div>

                <div className='form-group text-center m-2'>
                    <label>{t('glossary.usertypes')}</label>
                    <DropDown items={filters.userType.items} onToggleAll={(e) => handleToggleAll(e, filters.userType)} onToggleOne={handleToggleOne} />
                </div>

                <div className='d-flex align-items-end mb-2'>
                    <button className='btn btn-warning mx-2' onClick={handleReset}>{t('button.reset')}</button>
                </div>

            </div>
        </div>
    )
}

export default FiltersCharts;