
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../controllers/useAuthContext.js';

import useCountryController from '../../../controllers/useCountryController.js';
import useCompanyController from '../../../controllers/useCompanyController.js';
import useImportExportController from '../../../controllers/useImportExportController.js';
import useUserTypeController from '../../../controllers/useUserTypeController.js';

import AvatarEdit from '../../../components/editor/ImageEditor.js';
import ExportationAlert from '../../../components/alert/ExportationAlert.js';
import ImportationAlert from '../../../components/alert/ImportationAlert.js';

import avatar_img from '../../../assets/images/avatar.svg';

const CompanyDetail = () => {

    const { auth } = useAuthContext();

    const navigate = useNavigate();
    const { t } = useTranslation();

    /**
     * Page state
     */

    const hiddenImportRef = useRef();
    const [company, setCompany] = useState({});
    const [exportation, setExportation] = useState({ show: false, error: false });
    const [importation, setImportation] = useState({ show: false, error: false });

    const countryController = useCountryController();
    const companyController = useCompanyController();
    const importExportController = useImportExportController();
    const userTypeController = useUserTypeController();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {

            countryController.reload();
            userTypeController.reload();

            const company = await companyController.getCompany(auth.company.id);
            setCompany(company);


        })()

        // eslint-disable-next-line 
    }, [auth.company]);

    /**
     * Event handlers
     */

    const handleChange = (attributes) => {
        setCompany({ ...company, ...attributes });
    }

    const handleUserTypeChange = (e) => {
        const userType = userTypeController.userTypes.find(t => t.id === e.target.value);
        setCompany({ ...company, userType });
    }

    const handleCountryChange = (e) => {
        const country = countryController.countries.find(c => c.id === e.target.value);
        setCompany({ ...company, country });
    }

    const handleImport = async (e) => {
        e.preventDefault();
        hiddenImportRef.current?.click();
    }

    const handleExport = async () => {

        try {
            await importExportController.exportCompany();
            setTimeout(() => setExportation({ show: true }), 800);
        }
        catch(e) {
            setExportation({ error: true })
        }
    }

    const handleHiddenImport = async (e) => {

        e.preventDefault();

        if (e.target.files.length > 0) {
            
            try {
                await importExportController.importCompany(e.target.files[0]);
                e.target.value = null;
                setImportation({ show: true });
            }
            catch(e) {
                setImportation({ error: true });
            }
        }
    }

    const handleSave = async () => {
        await companyController.save(company);
        navigate('/home');
    }

    const handleCancel = () => {
        navigate('/home');
    }

    return (

        <div className='container'>
            <div className='row p-4'>

                <h5 className='table-title text-muted'>{t('glossary.company')}</h5>

                <AvatarEdit id='edit' picture={company.picture} onChange={(e) => handleChange({ picture: e })} />

                <input type='file' ref={hiddenImportRef} accept='.xls,.xlsx,application/vnd.ms-excel' style={{ display: 'none' }} onInput={handleHiddenImport} />
                <ExportationAlert show={exportation.show} error={exportation.error} onClose={() => setExportation({ show: false })} />
                <ImportationAlert show={importation.show} error={importation.error} onClose={() => setImportation({ show: false })} />

                <hr />

                <div className='container'>
                    <div className='row pt-3'>

                        <div className='col'>

                            <div className='form-group'>
                                <label htmlFor='name'>{t('companies.name')}</label>
                                <input id='name' type='text' className='form-control' value={company.name ?? ''} placeholder={t('companies.name-placeholder')} onChange={(e) => handleChange({ name: e.target.value })} />
                            </div>

                            <div className='row pt-2'>

                                <div className='col form-group'>
                                    <label htmlFor='director'>{t('companies.director')}</label>
                                    <input id='director' type='text' className='form-control' value={company.director ?? ''} placeholder={t('companies.director-placeholder')} onChange={(e) => handleChange({ director: e.target.value })} />
                                </div>

                                <div className='col form-group'>
                                    <label htmlFor='phone'>{t('companies.phone')}</label>
                                    <input id='phone' type='text' className='form-control' value={company.phone ?? ''} placeholder={t('companies.phone-placeholder')} onChange={(e) => handleChange({ phone: e.target.value })} />
                                </div>

                            </div>

                            <div className='row pt-2'>

                                <div className='col-9 form-group'>
                                    <label htmlFor='street'>{t('companies.street')}</label>
                                    <input id='street' type='text' className='form-control' value={company.street ?? ''} placeholder={t('companies.street-placeholder')} onChange={(e) => handleChange({ street: e.target.value })} />
                                </div>

                                <div className='col form-group'>
                                    <label htmlFor='streetNumber'>{t('companies.number')}</label>
                                    <input id='streetNumber' type='string' className='form-control' value={company.streetNumber ?? ''} placeholder={t('companies.number-placeholder')} onChange={(e) => handleChange({ streetNumber: e.target.value })} />
                                </div>

                            </div>

                            <div className='row pt-2'>
                                <div className='col form-group'>
                                    <label htmlFor='complement'>{t('companies.complement')}</label>
                                    <input id='complement' type='text' className='form-control' value={company.streetComplement ?? ''} placeholder={t('companies.complement-placeholder')} onChange={(e) => handleChange({ streetComplement: e.target.value })} />
                                </div>
                            </div>

                            <div className='row pt-2'>

                                <div className='col-9 form-group'>
                                    <label htmlFor='city'>{t('companies.city')}</label>
                                    <input id='city' type='text' className='form-control' value={company.city ?? ''} placeholder={t('companies.city-placeholder')} onChange={(e) => handleChange({ city: e.target.value })} />
                                </div>

                                <div className='col form-group'>
                                    <label htmlFor='cityCode'>{t('companies.citycode')}</label>
                                    <input id='cityCode' type='string' className='form-control' value={company.cityCode ?? ''} placeholder={t('companies.citycode-placeholder')} onChange={(e) => handleChange({ cityCode: e.target.value })} />
                                </div>

                            </div>

                            <div className='row pt-2'>

                                <div className='col form-group'>
                                    <label htmlFor='country'>{t('glossary.country')}</label>
                                    <select id='country' className='form-select' value={company.country?.id ?? ''} onChange={handleCountryChange}>
                                        <option value={null}>{t('glossary.none')}</option>
                                        {countryController.countries?.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                    </select>
                                </div>

                            </div>

                            <div className='row pt-2'>

                                <div className='col form-group'>
                                    <label htmlFor='usertype'>{t('companies.default-usertype')}</label>
                                    <select id='usertype' className='form-select' value={company.userType?.id ?? ''} onChange={handleUserTypeChange}>
                                        {userTypeController.userTypes?.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                    </select>
                                </div>

                            </div>

                            <div className='form-group pt-3'>
                                <label>{t('companies.link')}</label>
                                <a href={`https://skillspot.app/register?company=${company.id}`} className='form-control link'>{`https://skillspot.app/register?company=${company.id}`}</a>
                            </div>

                        </div>

                        <div className='col form-group d-flex flex-column align-items-center justify-content-center ps-5'>
                            <label>{t('companies.logo')}</label>
                            <div><img className='avatar avatar-editable border' src={company.picture ?? avatar_img} alt='avatar' data-bs-toggle='modal' data-bs-target='#edit'></img></div>
                        </div>

                    </div>
                </div>

                <div className='row pt-4'>

                    <hr />

                    <div className='d-flex justify-content-between'>
                        <div className='btn-group'>
                            <button className='btn btn-light' onClick={handleExport}>{t('button.export')}</button>
                            <button className='btn btn-light' onClick={handleImport}>{t('button.import')}</button>
                        </div>
                        <div className='btn-group'>
                            <button className='btn btn-light' onClick={handleSave}>{t('button.save')}</button>
                            <button className='btn btn-light' onClick={handleCancel}>{t('button.cancel')}</button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default CompanyDetail
