
import { useTranslation } from 'react-i18next';
import TreeTable from '../../../components/treetable/TreeTable.js';
import InventoryLogic from '../../../logic/InventoryLogic.js';

import './InventoryView.css';

const InventoryView = ({ skilltree, users, onTreeChange, onSelectionChange }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const selectedUsers = InventoryLogic.getSelected(users);

    return (
        <TreeTable className='skilltree-inventory table table-sm' data={skilltree} onChange={(skilltree) => onTreeChange(skilltree)}>
            <thead>
                <tr>
                    <th></th>
                    <th className='text-center'>
                        <div>
                            <span className='legend-autonomous'><small>&nbsp;{t('charts.autonomous')}</small></span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    (skill) => {

                        // Extract userId with actualLevel > expectedLevel
                        const validUserIds    = InventoryLogic.getMatchingUserIds(skill);
                        const matchingUsers   = selectedUsers.filter(user => validUserIds.includes(user.id));
                        const unmatchingUsers = selectedUsers.filter(user => !validUserIds.includes(user.id));

                        const widthLeft  = matchingUsers.length / selectedUsers.length * 100.0; 
                        const widthRight = unmatchingUsers.length / selectedUsers.length * 100.0;
                        const isBarchartVisible = selectedUsers.length > 0;

                        return (
                            <tr>
                                <td>
                                    {skill.label ?? '-'}
                                </td>
                                <td>
                                    <div className='barchart'>

                                        {
                                            isBarchartVisible && widthLeft > 0 &&
                                            <div className='leftbar' style={{ width: widthLeft + '%' }} onClick={() => onSelectionChange && onSelectionChange(validUserIds)}>
                                                { matchingUsers.length }
                                            </div>
                                        }

                                        {
                                            isBarchartVisible && widthRight > 0 &&
                                            <div className='rightbar' style={{ width: widthRight + '%' }}>
                                            </div>
                                        }

                                    </div>
                                </td>
                            </tr>
                        )
                    }
                }
            </tbody>
        </TreeTable>
    )
}

export default InventoryView;