
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import UserRoleService from '../api/UserRoleService.js';
import LangUtil from "../utils/LangUtil.js";

const useUserRoleController = (filter) => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();

    const [userRoles, setUserRoles] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    /**
     * Functionalities
     */

    const reload = async () => {

        try {
            setLoaded(false);
            const userRoles = await UserRoleService.getUserRoles(filter);
            userRoles.forEach(c => translate(c, auth.lang));
            setUserRoles(userRoles);

            const totalCount = await UserRoleService.getTotalCount();
            setTotalCount(totalCount);
        }
        finally {
            setLoaded(true);
        }
    }

    const save = async (userrole) => {

        try {
            untranslate(userrole);
            await UserRoleService.save(userrole);
        }
        finally {
            translate(userrole, auth.lang);
        }

        reload();
    }

    const translate = (userRole) => {
        LangUtil.translate(userRole, 'name', auth.lang);
        LangUtil.translate(userRole, 'description', auth.lang);
    }

    const untranslate = (userRole) => {
        LangUtil.untranslate(userRole, 'name');
        LangUtil.untranslate(userRole, 'description');
    }

    const retranslate = () => {

        userRoles.forEach(userRoles => {
            untranslate(userRoles);
            translate(userRoles);
        });

        setUserRoles([...userRoles]);
    }

    return {
        userRoles,
        totalCount,
        loaded,
        reload,
        save,
        translate,
        untranslate,
        retranslate
    }
}

export default useUserRoleController;