
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';
import FilterUtil from '../utils/FilterUtil.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/diplomatypes`;

const DiplomaTypeService = () => {

    const getDiplomaTypes = async (filter) => {
        const diplomaTypes = (await axios.get(`${SERVER_URL}`)).data.payload;
        return FilterUtil.filter(diplomaTypes, filter);
    }

    const getDiplomaType = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const getTotalCount = async () => {
        return (await axios.get(`${SERVER_URL}/count`)).data.payload;
    }

    const create = async () => {
        return (await axios.get(`${SERVER_URL}/create`)).data.payload;
    }

    const isDeletable = async (diplomaType) => {
        return (await axios.get(`${SERVER_URL}/${diplomaType.id}/deletable`)).data.payload;
    }

    const save = async (diplomaType) => {

        return diplomaType.id
            ? (await axios.put(`${SERVER_URL}/${diplomaType.id}`, diplomaType)).data.payload
            : (await axios.post(`${SERVER_URL}`, diplomaType)).data.payload;
    }

    const remove = async (diplomaType) => {
        await axios.delete(`${SERVER_URL}/${diplomaType.id}`);
    }

    return {
        getDiplomaTypes,
        getDiplomaType,
        getTotalCount,
        create,
        isDeletable,
        save,
        remove
    }
}

export default DiplomaTypeService();