
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useCountryController from '../../../controllers/useCountryController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import SearchBar from '../../../components/filter/SearchBar.js';
import SearchCount from '../../../components/filter/SearchCount.js';
import SortableHeader from '../../../components/sortable/SortableHeader.js';
import CountryDetail from './CountryDetail.js';
import CountryDelete from './CountryDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const Countries = () => {

    const { t } = useTranslation();

    const defaultFilter = {
        attributes: [
            { name: 'code', display: t('countries.code') },
            { name: 'name', display: t('countries.name') }
        ]
    }

    /**
     * Page state
     */

    const { auth } = useAuthContext();

    const [filter, setFilter] = useState(defaultFilter);
    const [country, setCountry] = useState({});
    
    const countryController = useCountryController(filter);
    
    /**
     * Page lifecycle
     */

    // eslint-disable-next-line 
    useEffect(() => { countryController.reload() }, [filter]);

    // eslint-disable-next-line 
    useEffect(() => { countryController.retranslate() }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const country = await countryController.create();
        setCountry(country);
    }

    const handleDetail = (country) => {
        setCountry(country);
    }

    const handleSave = async (country) => {
        await countryController.save(country);
    }

    const handleDelete = async (country) => {
        await countryController.remove(country);
    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!countryController.loaded} />
            <CountryDetail id='detail' country={country} onSave={handleSave} />
            <CountryDelete id='delete' country={country} onDelete={handleDelete} />

            <div className='row p-4'>

                <div className='d-flex align-items-end'>
                    <h5 className='text-muted mb-1'>{t('glossary.countries')}</h5>
                    <SearchCount className='text-muted mb-1 ms-2' count={countryController.countries.length} total={countryController.totalCount} />
                    <SearchBar className='ms-4' items={countryController.countries} filter={filter} onSearch={handleFilter} />
                </div>

                <hr />


                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>
                                <SortableHeader attrName='code' filter={filter} onFilter={handleFilter}>
                                    {t('countries.code')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='name' filter={filter} onFilter={handleFilter}>
                                    {t('countries.name')}
                                </SortableHeader>
                            </th>
                            <th className='text-center'>
                                <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            countryController.countries.map((country) => (
                                <tr key={country.id} className='align-middle'>
                                    <td>{country.code}</td>
                                    <td>{country.name}</td>
                                    <td className='text-center'><button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={() => handleDetail(country)}><img src={edit_img} alt='edit' /></button></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default Countries
