
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from '../slider/Slider.js';
import CatalogLogic from '../../logic/CatalogLogic.js';

import './Views.css';

/**
 * Note: this view is a copy of the NormalizedViewEditable
 * with a checkbox in front of each category
 * 
 */
const LanguageViewEditable = ({ category, disabled = false, onChange }) => {

    /**
     * Page state
     */
    const { t } = useTranslation();
    const labels = t('common.language-view-labels');
    const [activeSkill, setActiveSkill] = useState();
    const padding = 100.0 / labels.length / 2;

    /**
     * Event handlers
     */

    const handleLevelChange = (skill, level) => {

        // Change the skill's level
        CatalogLogic.setSkillLevel(category, skill, level);

        // And propagates the category change to the owner
        onChange({ ...category });
    }

    const handleHeaderClick = (skill, i) => {

        if (!disabled) {

            // Change all children level
            CatalogLogic.setChildrenLevel(category, skill, i+1);

            // And propagates the category change to the owner
            onChange({ ...category });
        }
    }

    // Note: the table has 15 columns
    //   - 1 col for the label (first col)
    //   - 7 columns doubled for each slider values
    // => slider has 14 column span and header has 2 column span
    return category && category.children?.map(skill => {

        return (
            <div key={skill.id} className='container border mb-3'>

                <table className='table'>
                    <thead>
                        <tr className='table-header'>
                            <th className='text-start'>
                                {skill.label}
                            </th>
                            <th></th>
                            <th>
                                <div className='table-slider-cell'>
                                    { labels.map((label, i) => (<div key={i} onClick={() => handleHeaderClick(skill, i)}>{label}</div>)) }
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            skill.children?.map((skill, i) => {

                                const level = CatalogLogic.getSkillLevel(category, skill);

                                return (
                                    <tr key={i} className='table-row' onMouseEnter={() => setActiveSkill(skill, true)} onMouseLeave={() => setActiveSkill(skill, false)}>
                                        <td className='text-start ps-4'>
                                            {skill.label}
                                        </td>
                                        <td width='1%'>
                                            {
                                                !disabled &&
                                                <button type='button' className='btn btn-xs btn-danger' style={{ opacity: skill.level && skill.id === activeSkill?.id ? 1 : 0}} onClick={() => handleLevelChange(skill, null)}>{t('button.delete')}</button>
                                            }
                                        </td>
                                        <td>
                                            <div className='table-slider-cell' style={{ paddingLeft: `${padding}%`, paddingRight: `${padding}%` }}>
                                                <Slider min='1' max='6' value={level} disabled={disabled} onChange={(e) => handleLevelChange(skill, e.target.value)} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>
        )
    })
}

export default LanguageViewEditable;
