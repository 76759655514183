
import { useState, useContext, useRef, createContext } from "react";

const Context = createContext();

// Global shared state
let initialDragId = null;

export const useDragContext = () => {
    return useContext(Context);
}

const DragContext = ({ children }) => {
    
    const dragImageRef = useRef();
    const [ dragId, setDragId ] = useState();
    const [ dragText, setDragText ] = useState();
    const [ dropId, setDropId ] = useState();
    const [ dropMode, setDropMode ] = useState();

    const state = {
        dragId,
        initialDragId,
        dragText,
        dropId,
        dropMode,
        dragImageRef,
        setDragId: (id) => { setDragId(id); initialDragId = id; },
        setDragText,
        setDropId,
        setDropMode
    }

    return (
        <Context.Provider value={state}>
            {children}
        </Context.Provider>
    )
}

export default DragContext;