
import { useEffect } from 'react';

/**
 * Bootstrap event listener, to fix bootstrap menu bug.
 * 
 * Inside react, a click on a menu item doesn't close the other opened menus.
 * So we are listening to clicks on all nav-item and dropdown-item 
 * and close all dropdowns currently opened when a click is detected.
 */

const useDropdownFix = () => {

    useEffect(() => {

        const handleClick = (e) => {

            // Detect only clicks on nav-item or dropdown-item
            if (e.target.classList?.contains('nav-item') || e.target.classList?.contains('dropdown-item')) {

                // Select all navbar subitems currently shown
                const navbar = document.querySelector('.navbar');
                const dropdowns = navbar.querySelectorAll('.show');

                // Remove their 'show' class
                dropdowns.forEach(dropdown => dropdown.classList.remove('show'));
            }
        }

        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    })

}

export default useDropdownFix;