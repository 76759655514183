
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useModalEffect from '../../../hooks/useModalEffect.js';

const UserRoleDetail = ({ id, userrole, onSave }) => {

    /**
     * Page state
     */

    const modalRef = useRef();
    const { t } = useTranslation();
    const [tmpUserRole, setTmpUserRole] = useState({});

    /**
     * Page lifecycle
     */

    useModalEffect(modalRef, () => {
        setTmpUserRole(userrole);
    })

    /**
     * Event handlers
     */

    const handleChange = (attributes) => {
        setTmpUserRole({ ...tmpUserRole, ...attributes });
    }

    const handleSave = (userRole) => {
        onSave && onSave(userRole);
    }

    return (

        <div id={id} className='modal fade' ref={modalRef}>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('glossary.userrole')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className='modal-body'>

                        <div className='col-6 form-group'>
                            <label htmlFor='code'>{t('userroles.code')}</label>
                            <input id='code' type='text' className='form-control' value={tmpUserRole.code ?? ''} placeholder={t('userroles.code-placeholder')} disabled readOnly onChange={(e) => handleChange({ code: e.target.value })} />
                        </div>

                        <div className='col-6 form-group'>
                            <label htmlFor='name'>{t('userroles.name')}</label>
                            <input id='name' type='text' className='form-control' value={tmpUserRole.name ?? ''} placeholder={t('userroles.name-placeholder')} onChange={(e) => handleChange({ name: e.target.value })} />
                        </div>

                        <div className='col form-group'>
                            <label htmlFor='description'>{t('userroles.description')}</label>
                            <input id='description' type='text' className='form-control' value={tmpUserRole.description ?? ''} placeholder={t('userroles.description-placeholder')} onChange={(e) => handleChange({ description: e.target.value })} />
                        </div>

                    </div>

                    <div className='modal-footer'>
                        <div className='btn-group'>
                            <button type='button' className='btn btn-light' data-bs-dismiss='modal' onClick={() => handleSave(tmpUserRole)}>{t('button.save')}</button>
                            <button type='button' className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default UserRoleDetail;
