
import InfoBox from '../../../components/charts/InfoBox.js';
import UserListMultiSelect from '../../../components/charts/UserListMultiSelect.js';
import MindsetChart from '../../../components/charts/MindsetChart.js';
import GenderChart from '../../../components/charts/GenderChart.js';
import AgeChart from '../../../components/charts/AgeChart.js';
import GapAnalysisView from '../../../components/charts/GapAnalysisView.js';
import InventoryLogic from '../../../logic/InventoryLogic.js';

const TabGapAnalysis = ({ skilltree, users, onTreeChange, onSelectionChange }) => {

    /**
     * Event handlers
     */

    const handleTreeChange = (skilltree) => {
        onTreeChange(skilltree);
    }

    const handleSelectionToggle = (user) => {
        InventoryLogic.toggleSelected(user);
        onSelectionChange([...users]);
    }

    const handleSelectionReset = () => {
        InventoryLogic.resetSelection(users);
        onSelectionChange([...users]);
    } 

    const handleSelectionChange = (userIds) => {
        InventoryLogic.markAsSelected(users, userIds);
        onSelectionChange([...users]);
    } 

    return (

        <div className='d-flex'>

            <div>
                <InfoBox users={users} />
                <UserListMultiSelect users={users} onToggle={handleSelectionToggle} onReset={handleSelectionReset} />
            </div>

            <div className='flex-grow-1 mx-3 p-3 border rounded'>
                <GapAnalysisView skilltree={skilltree} users={users} onTreeChange={handleTreeChange} onSelectionChange={handleSelectionChange}/>
            </div>

            <div>
                <MindsetChart users={users} />
                <GenderChart users={users} onSelectionChange={handleSelectionChange} />
                <AgeChart users={users} onSelectionChange={handleSelectionChange} />
            </div>

        </div>
    )
}

export default TabGapAnalysis;