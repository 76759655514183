
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';
import FilterUtil from '../utils/FilterUtil.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/userroles`;

const UserRoleService = () => {

    const getUserRoles = async (filter) => {
        const userRoles = (await axios.get(`${SERVER_URL}`)).data.payload;
        return FilterUtil.filter(userRoles, filter);
    }

    const getUserRole = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const getTotalCount = async () => {
        return (await axios.get(`${SERVER_URL}/count`)).data.payload;
    }

    const save = async (userRole) => {

        return userRole.id
            ? (await axios.put(`${SERVER_URL}/${userRole.id}`, userRole)).data.payload
            : (await axios.post(`${SERVER_URL}`, userRole)).data.payload;
    }

    return {
        getUserRoles,
        getUserRole,
        getTotalCount,
        save
    }
}

export default UserRoleService();