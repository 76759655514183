
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useCompanyController from '../../../controllers/useCompanyController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import SearchBar from '../../../components/filter/SearchBar.js';
import SearchCount from '../../../components/filter/SearchCount.js';
import SortableHeader from '../../../components/sortable/SortableHeader.js';
import CompanyDetail from './CompanyDetail.js';
import CompanyDelete from './CompanyDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const Companies = () => {

    const { t } = useTranslation();

    const defaultFilter = {
        attributes: [
            { name: 'name', display: t('companies.name') },
            { name: 'street', display: t('companies.street') },
            { name: 'city', display: t('companies.city') },
            { name: 'cityCode', display: t('companies.citycode') },
            { name: 'countryName', display: t('glossary.country') }
        ]
    }

    /**
     * Page state
     */

    const { auth } = useAuthContext();

    const [filter, setFilter] = useState(defaultFilter);
    const [company, setCompany] = useState({});
    
    const companyController = useCompanyController(filter);

    /**
     * Page lifecycle
     */

    // eslint-disable-next-line 
    useEffect(() => { companyController.reload() }, [filter]);
    
    // eslint-disable-next-line
    useEffect(() => { companyController.retranslate() }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const company = await companyController.create();
        setCompany(company);
    }

    const handleDetail = (company) => {
        setCompany(company);
    }

    const handleSave = async (company) => {
        await companyController.save(company);
    }

    const handleDelete = async (company) => {
        await companyController.remove(company);
    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!companyController.loaded} />
            <CompanyDetail id='detail' company={company} onSave={handleSave} />
            <CompanyDelete id='delete' company={company} onDelete={handleDelete} />

            <div className='row p-4'>

                <div className='d-flex align-items-center'>
                    <h5 className='text-muted mb-1'>{t('glossary.companies')}</h5>
                    <SearchCount className='text-muted mb-1 ms-2' count={companyController.companies.length} total={companyController.totalCount} />
                    <SearchBar className='ms-4' items={companyController.companies} filter={filter} onSearch={handleFilter} />
                </div>

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>
                                <SortableHeader attrName='name' filter={filter} onFilter={handleFilter}>
                                    {t('companies.name')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='street' filter={filter} onFilter={handleFilter}>
                                    {t('companies.street')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='city' filter={filter} onFilter={handleFilter}>
                                    {t('companies.city')}
                                </SortableHeader>
                            </th>
                            <th className='text-center'>
                                <SortableHeader attrName='cityCode' filter={filter} onFilter={handleFilter}>
                                    {t('companies.citycode')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='countryName' filter={filter} onFilter={handleFilter}>
                                    {t('glossary.country')}
                                </SortableHeader>
                            </th>
                            <th className='text-center'>
                                <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            companyController.companies.map((company) => {
                              
                                return (

                                <tr key={company.id} className={'align-middle' + (company.disabled ? ' text-muted' : '')}>
                                    <td>{company.name} {company.owner ? ' *' : ''}</td>
                                    <td>{company.street}</td>
                                    <td>{company.city}</td>
                                    <td>{company.cityCode}</td>
                                    <td>{company.country ? company.country.name : '.'}</td>
                                    <td className='text-center'><button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={() => handleDetail(company)}><img src={edit_img} alt='edit' /></button></td>
                                </tr>
                            )})
                            // ))
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default Companies
