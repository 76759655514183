
import { useTranslation } from 'react-i18next';

const ExportationAlert = ({ show, error, onClose }) => {

    const { t } = useTranslation();
    const visible = show || error;
    const className = 'modal fade ' + (visible ? 'show' : '');
    const style = { display: visible ? 'block' : 'none' };

    return (
        <div className={className} style={style}>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content modal-alert'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>
                            { error ? t('exportation.error-title') : t('exportation.title')}
                        </h2>
                        <button type='button' className='btn-close' onClick={() => onClose()}></button>
                    </div>

                    <div className='modal-body'>
                        { error ? t('exportation.error-message') : t('exportation.message')}
                    </div>

                    <div className='modal-footer d-flex justify-content-center'>
                        <button className='btn btn-light d-block' onClick={() => onClose()}>{t('button.ok')}</button>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default ExportationAlert;
