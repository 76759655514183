
import { useEffect } from 'react';

/**
 * Element resize listener.
 */

const usePreventDefaultMouseWheel = (ref) => {

    useEffect(() => {

        if (ref.current) {

            const domElement = ref.current;

            const mouseWheelListener = (e) => { 
                e.preventDefault() 
            }

            domElement.addEventListener('wheel', mouseWheelListener);
            return () => { domElement?.removeEventListener('wheel', mouseWheelListener); }
        }

    }, [ref]);

}

export default usePreventDefaultMouseWheel;