
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useUserRoleController from '../../../controllers/useUserRoleController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import SearchBar from '../../../components/filter/SearchBar.js';
import SearchCount from '../../../components/filter/SearchCount.js';
import SortableHeader from '../../../components/sortable/SortableHeader.js';
import UserRoleDetail from './UserRoleDetail.js';

import edit_img from '../../../assets/images/pen.svg';

const UserRoles = () => {

    const { t } = useTranslation();

    const defaultFilter = {
        attributes: [
            { name: 'code', display: t('userroles.code') },
            { name: 'name', display: t('userroles.name') },
            { name: 'description', display: t('userroles.description') }
        ]
    }

    /**
     * Page state
     */

    const { auth } = useAuthContext();

    const [filter, setFilter] = useState(defaultFilter);
    const [userrole, setUserRole] = useState({});
    
    const userRoleController = useUserRoleController(true, filter);

    /**
     * Page lifecycle
     */

    // eslint-disable-next-line 
    useEffect(() => { userRoleController.reload() }, [filter]);

    // eslint-disable-next-line 
    useEffect(() => { userRoleController.retranslate() }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleDetail = (userRole) => {
        setUserRole(userRole);
    }

    const handleSave = async (userRole) => {
        await userRoleController.save(userRole);
    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!userRoleController.loaded} />
            <UserRoleDetail id='detail' userrole={userrole} onSave={handleSave} />

            <div className='row p-4'>

                <div className='d-flex align-items-center'>
                    <h5 className='text-muted mb-1'>{t('glossary.userroles')}</h5>
                    <SearchCount className='text-muted mb-1 ms-2' count={userRoleController.userRoles.length} total={userRoleController.totalCount} />
                    <SearchBar className='ms-4' items={userRoleController.userRoles} filter={filter} onSearch={handleFilter} />
                </div>

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>
                                <SortableHeader attrName='code' filter={filter} onFilter={handleFilter}>
                                    {t('userroles.code')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='name' filter={filter} onFilter={handleFilter}>
                                    {t('userroles.name')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='description' filter={filter} onFilter={handleFilter}>
                                    {t('userroles.description')}
                                </SortableHeader>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            userRoleController.userRoles.map((userRole) => (
                                <tr key={userRole.id} className='align-middle'>
                                    <td>{userRole.code}</td>
                                    <td>{userRole.name}</td>
                                    <td>{userRole.description}</td>
                                    <td className='text-center'><button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={() => handleDetail(userRole)}><img src={edit_img} alt='edit' /></button></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default UserRoles
