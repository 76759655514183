
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../controllers/useAuthContext.js';

import useAuthenticationController from '../../controllers/useAuthenticationController.js';

import { Link } from 'react-router-dom';
import AppLogic from '../../logic/AppLogic.js';

import './Login.css';

const Login = () => {

    /**
     * Page state
     * 
     */

    const { t, i18n } = useTranslation();
    const [ queryParams ] = useSearchParams();
    const { auth } = useAuthContext();
    const navigate = useNavigate();
    const location = useLocation();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const controller = useAuthenticationController();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {

            // Detect lang
            if (queryParams.has('lang')) {
                const lang = queryParams.get('lang');
                i18n.changeLanguage(lang);
            }

            // Detect reset code
            if (queryParams.has('code')) {
                const code = queryParams.get('code');
                const lang = queryParams.get('lang') ?? 'en';
                const codevalid = await controller.isResetCodeValid(code);
                codevalid && navigate(`/reset?code=${code}&lang=${lang}`, { replace: true })
            }

        })();

        // eslint-disable-next-line 
    }, [queryParams, auth, navigate]);

    /**
     * Event handlers
     */

    const handleSubmit = async (e) => {

        e.preventDefault();
        e.stopPropagation();

        try {
            // Disable form
            setDisabled(true);

            // Authenticate the user
            const newAuth = await controller.login({ email: username, password: password });

            // If authenticated, redirect to the home page
            if (newAuth.authenticated) {

                // Change the current language
                i18n.changeLanguage(newAuth.lang);


                // If we are comming from the public url, go to home (first time)
                if (location.pathname === '/' || location.pathname === '/login') {
                    navigate('/home', { replace: true });
                }
                else {
                    // Othwerwise return to the original requested page
                    navigate(-1, { replace: true });
                }
            }
            else {
                setError(true);
            }
        }
        catch (e) {
            console.error(e);
            setError(true);
        }
        finally {
            setDisabled(false);
        }
    }

    return (
        <div className='container d-flex justify-content-center my-5'>
            <form className='login-form' onSubmit={handleSubmit} noValidate>

                <div className='text-center mb-5'>
                    <h3>SkillSpot</h3>
                    <small>{'v' + AppLogic.version}</small>
                </div>

                {error && <div className='alert alert-danger text-center'>{t('login.error')}</div>}

                <fieldset disabled={disabled}>

                    <div className='form-group mb-3'>
                        <input type='text' className='form-control' placeholder={t('login.username') + ' *'} value={username} autoComplete='off' autoFocus onChange={(e) => setUsername(e.target.value)} required />
                        <div className="invalid-feedback">{t('login.username-invalid')}</div>
                    </div>

                    <div className='form-group mb-3'>
                        <input type='password' className='form-control' placeholder={t('login.password') + ' *'} value={password} autoComplete='off' onChange={(e) => setPassword(e.target.value)} required />
                        <div className="invalid-feedback">{t('login.password-invalid')}</div>
                    </div>

                    <div className='form-group pt-4 mb-5 d-flex justify-content-center'>
                        <button type='submit' className='btn btn-primary btn-login'>{t('button.login')}</button>
                    </div>

                </fieldset>

                <div className='form-group'>
                    <Link to='/register' className='link'>{t('login.registerLink')}</Link>
                </div>

                <div className='form-group'>
                    <Link to='/forgot' className='link'>{t('login.forgotLink')}</Link>
                </div>

            </form>
        </div>
    )
}

export default Login
