
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';
import FilterUtil from '../utils/FilterUtil.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/formations`;

const FormationService = () => {

    const getFormations = async (user, filter) => {
        const formations = (await axios.get(`${SERVER_URL}?user=${user.id}`)).data.payload;
        return FilterUtil.filter(formations, filter);
    }

    const getFormation = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const getTotalCount = async (user) => {
        return (await axios.get(`${SERVER_URL}/count?user=${user.id}`)).data.payload;
    }

    const create = async (user) => {
        return (await axios.get(`${SERVER_URL}/create?user=${user.id}`)).data.payload;
    }

    const isDeletable = async (formation) => {
        return (await axios.get(`${SERVER_URL}/${formation.id}/deletable`)).data.payload;
    }

    const save = async (formation) => {

        return formation.id
            ? (await axios.put(`${SERVER_URL}/${formation.id}`, formation)).data.payload
            : (await axios.post(`${SERVER_URL}`, formation)).data.payload;
    }

    const remove = async (formation) => {
        await axios.delete(`${SERVER_URL}/${formation.id}`);
    }

    return {
        getFormations,
        getFormation,
        getTotalCount,
        create,
        isDeletable,
        save,
        remove
    }
}

export default FormationService();