
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useUserController from '../../../controllers/useUserController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import SearchBar from '../../../components/filter/SearchBar.js';
import SearchCount from '../../../components/filter/SearchCount.js';
import SortableHeader from '../../../components/sortable/SortableHeader.js';
import DateUtil from '../../../utils/DateUtil.js';
import UserDetail from '../../admin/users/UserDetail.js';
import UserDelete from '../../admin/users/UserDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';
import cv_img from '../../../assets/images/cv.svg';

const Users = () => {

    const { t } = useTranslation();

    const defaultFilter = {
        attributes: [
            { name: 'email', display: t('users.email') },
            { name: 'firstname', display: t('users.firstname') },
            { name: 'lastname', display: t('users.lastname') },
            { name: 'roles', display: t('glossary.userrole') },
            { name: 'userTypeName', display: t('glossary.usertype') },
            { name: 'team', display: t('users.team') },
        ]
    }

    /**
     * Page state
     */

    const { auth } = useAuthContext();
    const navigate = useNavigate();
    const [filter, setFilter] = useState(defaultFilter);
    const [user, setUser] = useState({});
    
    const userController = useUserController(auth.company, filter); // all user of current company

    /**
     * Page lifecycle
     */

    // eslint-disable-next-line 
    useEffect(() => { userController.reload() }, [filter, auth.company]);

    // eslint-disable-next-line 
    useEffect(() => { userController.retranslate() }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const user = await userController.create();
        setUser(user);
    }

    const handleDetail = (user) => {
        setUser(user);
    }

    const handleSave = async (user) => {
        await userController.save(user);
    }

    const handleDelete = async (user) => {
        await userController.remove(user);
    }

    const handleCurriculum = (user) => {
        navigate(`/user/cv/${user.id}`);
    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!userController.loaded} />
            <UserDetail id='detail' user={user} showCompany={false} onSave={handleSave} />
            <UserDelete id='delete' user={user} onDelete={handleDelete} />

            <div className='row p-4'>

                <div className='d-flex align-items-center'>
                    <h5 className='text-muted mb-1'>{t('glossary.users')}</h5>
                    <SearchCount className='text-muted mb-1 ms-2' count={userController.users.length} total={userController.totalCount} />
                    <SearchBar className='ms-4' items={userController.users} filter={filter} onSearch={handleFilter} />
                </div>

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>
                                <SortableHeader attrName='email' filter={filter} onFilter={handleFilter}>
                                    {t('users.email')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='firstname' filter={filter} onFilter={handleFilter}>
                                    {t('users.firstname')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='lastname' filter={filter} onFilter={handleFilter}>
                                    {t('users.lastname')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='roles' filter={filter} onFilter={handleFilter}>
                                    {t('glossary.userrole')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='userTypeName' filter={filter} onFilter={handleFilter}>
                                    {t('glossary.usertype')}
                                </SortableHeader>
                            </th>
                            <th>
                                {t('users.lastlogin')}
                            </th>
                            <th className='text-center'>
                                <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            userController.users.map((user) => (
                                <tr key={user.id} className={'align-middle' + (user.disabled ? ' text-muted' : '')}>
                                    <td>{user.email}</td>
                                    <td>{user.firstname}</td>
                                    <td>{user.lastname}</td>
                                    <td>{user.roles?.map(role => role.name).join(', ')}</td>
                                    <td>{user.userType?.name}</td>
                                    <td className='text-center'>{DateUtil.toLocaleString(user.lastlogin)}</td>
                                    <td className='text-center'>
                                        <div className='btn-group'>
                                            <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={() => handleDetail(user)}><img src={edit_img} alt='edit' /></button>
                                            <button className='btn btn-light' onClick={() => handleCurriculum(user)}><img src={cv_img} alt='curriculum' /></button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default Users
