
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import WishService from '../api/WishService.js';
import LangUtil from "../utils/LangUtil.js";

const useWishController = (filter) => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();

    const [wishes, setWishes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    /**
     * Functionalities
     */

    const reload = async () => {

        try {
            setLoaded(false);
            
            const wishes = await getWishes(filter);
            setWishes(wishes);
            
            const totalCount = await WishService.getTotalCount();
            setTotalCount(totalCount);

            const categories = wishes.filter(w => w.parent == null);
            setCategories(categories);
        }
        finally {
            setLoaded(true);
        }
    }

    const deletable = async (wish) => {
        return wish.id != null && await WishService.isDeletable(wish);
    }

    const create = async () => {
        const wish = await WishService.create();
        translate(wish, auth.lang);
        return wish;
    }

    const save = async (wish) => {

        try {
            untranslate(wish);
            await WishService.save(wish);
        }
        finally {
            translate(wish, auth.lang);
        }

        reload();
    }

    const remove = async (wish) => {
        await WishService.remove(wish);
        reload();
    }

    const translate = (wish) => {
        LangUtil.translate(wish, 'name', auth.lang);
        LangUtil.translate(wish.type, 'name', auth.lang);
        wish.children?.forEach(w => translate(w));
    }

    const untranslate = (wish) => {
        LangUtil.untranslate(wish, 'name');
        LangUtil.untranslate(wish.type, 'name');
        wish.children?.forEach(w => untranslate(w));
    }

    const retranslate = () => {

        wishes.forEach(wish => {
            untranslate(wish);
            translate(wish);
        });

        setWishes([...wishes]);
    }
    
    const getWishes = async (filter) => {

        const wishes = await WishService.getWishes(filter);
        wishes.sort((a, b) => a.order - b.order);
        wishes.forEach(w => translate(w));

        return wishes;
    }

    const getWishTypes = async () => {
        const wishtypes = await WishService.getWishTypes();
        wishtypes.forEach(wt => translate(wt));
        return wishtypes;
    }

    return {
        wishes,
        categories,
        totalCount,
        loaded,
        reload,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate,
        getWishes,
        getWishTypes
    }
}

export default useWishController;