
import { useTranslation } from 'react-i18next';
import DateSelector from '../../../components/editor/DateSelector.js';

const TabMain = ({ user, userTypes, onChange }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const genders = t('common.genders');
    const languages = t('common.languages');

    /**
     * Event handlers
     */

    // console.log(user.userType?.name);

    const handleAttributeChange = (attributes) => {
        onChange({ ...user, ...attributes });
    }

    const handleLanguageChange = (e) => {
        const language = languages.find(c => c.id === e.target.value);
        handleAttributeChange({ language: language.id });
    }

    const handleUserTypeChange = (e) => {
        const userType = userTypes.find(c => c.id === e.target.value);
        handleAttributeChange({ userType });
    }

    return (

        <div className='container'>

            <div className='row'>
                <div className='col form-group'>
                    <label htmlFor='email'>{t('users.email')}</label>
                    <input id='email' type='text' className='form-control' value={user.email ?? ''} placeholder={t('users.email-placeholder')} onChange={(e) => handleAttributeChange({ email: e.target.value })} />
                </div>
            </div>

            <div className='row pt-2'>
                <div className='col form-group'>
                    <label htmlFor='password'>{t('users.password')}</label>
                    <input id='password' type='password' className='form-control' value={user.password ?? ''} placeholder={t('users.password-placeholder')} onChange={(e) => handleAttributeChange({ password: e.target.value })} />
                </div>
            </div>

            <div className='row pt-2'>

                <div className='col form-group'>
                    <label htmlFor='firstName'>{t('users.firstname')}</label>
                    <input id='firstName' type='text' className='form-control' value={user.firstname ?? ''} placeholder={t('users.firstname-placeholder')} onChange={(e) => handleAttributeChange({ firstname: e.target.value })} />
                </div>

                <div className='col form-group'>
                    <label htmlFor='lastName'>{t('users.lastname')}</label>
                    <input id='lastName' type='text' className='form-control' value={user.lastname ?? ''} placeholder={t('users.lastname-placeholder')} onChange={(e) => handleAttributeChange({ lastname: e.target.value })} />
                </div>

            </div>

            <div className='row pt-2'>

                <div className='col form-group'>
                    <label htmlFor='phone'>{t('users.phone')}</label>
                    <input id='phone' type='text' className='form-control' value={user.phone ?? ''} placeholder={t('users.phone-placeholder')} onChange={(e) => handleAttributeChange({ phone: e.target.value })} />
                </div>

                <div className='col form-group'>
                    <label htmlFor='birthdate'>{t('users.birthdate')}</label>
                    <DateSelector id='birthdate' className='form-control border' value={user.birthdate} onChange={(date) => handleAttributeChange({ birthdate: date })} /> 
                </div>

            </div>

            <div className='row pt-2'>

                <div className='col form-group'>
                    <label htmlFor='gender'>{t('users.gender')}</label>
                    <select id='gender' className='form-select' value={user.gender ?? ''} onChange={(e) => handleAttributeChange({ gender: e.target.value })}>
                        <option value={null}>{t('glossary.none')}</option>
                        {genders.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                    </select>
                </div>

                <div className='col form-group'>
                    <label htmlFor='language'>{t('users.language')}</label>
                    <select id='language' className='form-select' value={user.language ?? ''} onChange={handleLanguageChange}>
                        <option value={null}>{t('glossary.none')}</option>
                        {languages?.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                    </select>
                </div>

            </div>

            <div className='row pt-2'>

                <div className='col form-group'>
                    <label htmlFor='type'>{t('glossary.usertype')}</label>
                    <select id='type' className='form-select' value={user.userType?.id ?? '1'} onChange={handleUserTypeChange}>
                        <option value={null}>{t('glossary.none')}</option>
                        {userTypes.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                    </select>
                </div>

            </div>
        </div>

    )
}

export default TabMain;
