
import { useTranslation } from 'react-i18next';

const TabPersonal = ({ user, onChange }) => {

    const { t } = useTranslation();

    /**
     * Page state
     */

    const genders = t('common.genders');
    const languages = t('common.languages');

    return (

        <div className='container'>

            <div className='row'>

                <div className='col-6'>
                    <label htmlFor='firstName'>{t('users.firstname')}</label>
                    <input id='firstName' type='text' className='form-control' value={user.firstname ?? ''} placeholder={t('users.firstname-placeholder')} onChange={(e) => onChange({ firstname: e.target.value })} />
                </div>

                <div className='col-6'>
                    <label htmlFor='lastName'>{t('users.lastname')}</label>
                    <input id='lastName' type='text' className='form-control' value={user.lastname ?? ''} placeholder={t('users.lastname-placeholder')} onChange={(e) => onChange({ lastname: e.target.value })} />
                </div>

            </div>

            <div className='row pt-2'>

                <div className='col-6'>
                    <div className='form-group'>
                        <label htmlFor='gender'>{t('users.gender')}</label>
                        <select id='gender' className='form-select' value={user.gender ?? ''} onChange={(e) => onChange({ gender: e.target.value })}>
                            <option value={null}>{t('glossary.none')}</option>
                            {genders.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                        </select>
                    </div>
                </div>

                <div className='col-6'>
                    <div className='form-group'>
                        <label htmlFor='gender'>{t('users.language')}</label>
                        <select id='lang' className='form-select' value={user.language} onChange={(e) => onChange({ language: e.target.value })}>
                            <option value={null}>{t('glossary.none')}</option>
                            {languages?.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                        </select>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default TabPersonal;
