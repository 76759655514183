
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useAssessmentController, { AssessmentStatus } from '../../../controllers/useAssessmentController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import DateUtil from '../../../utils/DateUtil.js';
import SearchBar from '../../../components/filter/SearchBar.js';
import SearchCount from '../../../components/filter/SearchCount.js';
import SortableHeader from '../../../components/sortable/SortableHeader.js';
import AssessmentDetail from './AssessmentDetail.js';
import AssessmentDelete from './AssessmentDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';
import report_img from '../../../assets/images/report.svg';

const Assessments = () => {

    const { t } = useTranslation();

    const defaultFilter = {
        attributes: [
            { name: 'type', display: 'Type' },
            { name: 'start', display: 'Start' },
            { name: 'end', display: 'End' }
        ]
    }

    /**
     * Page state
     */

    const { auth } = useAuthContext();
    const [filter, setFilter] = useState(defaultFilter);
    const assessmentController = useAssessmentController(auth.user, filter);
    const [assessment, setAssessment] = useState({});
    const assessmentTypes = t('assessments.types');
    const assessmentStatuses = t('assessments.statuses');

    /**
     * Page lifecycle
     */

    // eslint-disable-next-line 
    useEffect(() => { assessmentController.reload() }, [filter, auth.user]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const assessment = await assessmentController.create(auth.user);
        setAssessment(assessment);
    }

    const handleDetail = (assessment) => {
        setAssessment(assessment);
    }

    const handleDelete = async (assessment) => {
        assessmentController.remove(assessment);
    }

    const handleFilter = (filter) => {
        setFilter(filter);
    }

    const handleStart = () => {
        assessmentController.start(assessment);
    }
    
    const handleResume = () => {
        assessmentController.resume(assessment);
    }
    
    const handleReport = (assessment) => {
        assessmentController.report(assessment);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!assessmentController.loaded} />
            <AssessmentDetail id='detail' assessment={assessment} onStart={handleStart} onResume={handleResume} onReport={handleReport} />
            <AssessmentDelete id='delete' assessment={assessment} onDelete={handleDelete} />

            <div className='row p-4'>

                <div className='d-flex align-items-center'>
                    <h5 className='text-muted mb-1'>{t('assessments.title')}</h5>
                    <SearchCount className='text-muted mb-1 ms-2' count={assessmentController.assessments.length} total={assessmentController.totalCount} />
                    <SearchBar className='ms-4' items={assessmentController.assessments} filter={filter} onSearch={handleFilter} />
                </div>

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>
                                <SortableHeader attrName='start' filter={filter} onFilter={handleFilter}>
                                    {t('assessments.date')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='start' filter={filter} onFilter={handleFilter}>
                                    {t('assessments.code')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='assessmentType' filter={filter} onFilter={handleFilter}>
                                    {t('assessments.type')}
                                </SortableHeader>
                            </th>
                            <th>
                                <SortableHeader attrName='end' filter={filter} onFilter={handleFilter}>
                                    {t('assessments.status-label')}
                                </SortableHeader>
                            </th>
                            <th className='text-center'>
                                <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            assessmentController.assessments.map((assessment) => {

                                const assessmentType = assessmentTypes.find(type => type.id === assessment.type);
                                const assessmentStatus = assessmentStatuses.find(status => status.id === assessment.status);

                                return (
                                    <tr key={assessment.id} className='align-middle'>
                                        <td>{DateUtil.toLocaleString(assessment.start) ?? '-'}</td>
                                        <td>{assessment?.externalId ?? '-'}</td>
                                        <td>{assessmentType?.name ?? '-'}</td>
                                        <td>{assessmentStatus?.name ?? '-'}</td>
                                        <td className='text-center'>
                                            <div className='btn-group'>
                                                <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={() => handleDetail(assessment)}><img src={edit_img} alt='edit' /></button>
                                                { assessmentStatus.id === AssessmentStatus.COMPLETED && <button className='btn btn-light' onClick={() => handleReport(assessment)}><img src={report_img} alt='report' /></button> }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default Assessments
