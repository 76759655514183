
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../controllers/useAuthContext.js';

import cv_img from '../../assets/images/cv.svg';
import heart_img from '../../assets/images/heart.svg';

const MatchingTable = ({ motion, axes, matchings }) => {

    const { t } = useTranslation();

    /**
     * Page state
     */

    const { auth } = useAuthContext();
    const navigate = useNavigate();

    const [xlabel, setXLabel] = useState('');
    const [ylabel, setYLabel] = useState('');
    const [zlabel, setZLabel] = useState('');

    /**
     * Page lifecycle
     */

    useEffect(() => {

        const xaxis = motion.categories?.find(w => w.id === axes.x);
        const yaxis = motion.categories?.find(w => w.id === axes.y);
        const zaxis = motion.categories?.find(w => w.id === axes.z);

        setXLabel(xaxis?.label ?? 'n/a');
        setYLabel(yaxis?.label ?? 'n/a');
        setZLabel(zaxis?.label ?? 'n/a');

        // eslint-disable-next-line
    }, [axes]);

    /**
     * Event handlers
     */

    const handleCurriculum = (user) => {
        navigate(`/cv/${user.id}?lang=${auth.lang}`);
    }

    return (

        <div className='container'>
            <div className='row mt-4 p-4'>

                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th className='text-center'>{t('motions.code')}</th>
                            <th>{t('users.email')}</th>
                            <th>{t('glossary.company')}</th>
                            <th className='text-center'>{t('motions.score')}</th>
                            <th className='text-center'>{t('motions.like')}</th>
                            <th className='text-center'>{xlabel}</th>
                            <th className='text-center'>{ylabel}</th>
                            <th className='text-center'>{zlabel}</th>
                            <th className='text-center'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            matchings.map((user) => {

                                const score = user.distances.get('absolute') ?? 0;
                                const xaxis = user.distances.get(axes.x) ?? 0;
                                const yaxis = user.distances.get(axes.y) ?? 0;
                                const zaxis = user.distances.get(axes.z) ?? 0;

                                return (
                                    <tr key={user.id} className='align-middle'>
                                        <td className='text-center'>{user.label}</td>
                                        <td>{user.email}</td>
                                        <td>{user.company.name}</td>
                                        <td className='text-center'>{(1 - score).toFixed(1)}</td>
                                        <td className='text-center'>{user.like ? <img src={heart_img} className='skill-icon' alt='like' /> : '-'}</td>
                                        <td className='text-center'>{xaxis.toFixed(1)}</td>
                                        <td className='text-center'>{yaxis.toFixed(1)}</td>
                                        <td className='text-center'>{zaxis.toFixed(1)}</td>
                                        <td className='text-center'><button className='btn btn-light' onClick={() => handleCurriculum(user)}><img src={cv_img} alt='curriculum' /></button></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default MatchingTable
