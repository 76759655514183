
import { useTranslation } from 'react-i18next';
import InventoryLogic from '../../logic/InventoryLogic.js';
import './MindsetChart.css';

// import ChartsLogic from '../../logic/ChartsLogic.js';

const MindsetChart = ({ users }) => {

    const { t } = useTranslation();

    // Retrieve the selected users classified by the mindset score
    const data = InventoryLogic.getMindsetData(users);

    return (
        <div className='mb-3 p-3 border rounded'>

            <h5>{t('charts.mindsetscores')}</h5>

            <div className='mindchart'>
                { 
                    data.map(category => {

                        const heightInPercent = category.percent !== 0 ? (category.percent * 100).toFixed() + '%' : null;

                        return (
                            <div className='category px-1' key={category.id}>
                                {
                                    heightInPercent 
                                        ? <div className='value' style={{ height: heightInPercent }}>{ heightInPercent }</div>
                                        : <div className='value' style={{ height: 0, padding: 0 }}></div>
                                }

                                <div className='label'>
                                    { category.display }
                                </div>

                            </div>
                        )
                    })
                }
            </div>

        </div>
    )
}

export default MindsetChart;