
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';
import FilterUtil from '../utils/FilterUtil.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/experiences`;

const ExperienceService = () => {

    const getExperiences = async (user, filter) => {
        const experiences = (await axios.get(`${SERVER_URL}?user=${user.id}`)).data.payload;
        return FilterUtil.filter(experiences, filter);
    }

    const getExperience = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const getTotalCount = async (user) => {
        return (await axios.get(`${SERVER_URL}/count?user=${user.id}`)).data.payload;
    }

    const create = async (user) => {
        return (await axios.get(`${SERVER_URL}/create?user=${user.id}`)).data.payload;
    }

    const isDeletable = async (experience) => {
        return (await axios.get(`${SERVER_URL}/${experience.id}/deletable`)).data.payload;
    }

    const save = async (experience) => {

        return experience.id
            ? (await axios.put(`${SERVER_URL}/${experience.id}`, experience)).data.payload
            : (await axios.post(`${SERVER_URL}`, experience)).data.payload;
    }

    const remove = async (experience) => {
        await axios.delete(`${SERVER_URL}/${experience.id}`);
    }

    return {
        getExperiences,
        getExperience,
        getTotalCount,
        create,
        isDeletable,
        save,
        remove
    }
}

export default ExperienceService();