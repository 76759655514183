
const SearchCount = ({ count, total, ...props}) => {

    return (
        <div className='mb-1 text-muted' {...props} >
            
            { 
                count < total 
                    ? <small>&nbsp;({count}/{total})</small> 
                    : <small>&nbsp;({count})</small> 

            }

        </div>
    )
}

export default SearchCount;