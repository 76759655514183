
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Unauthorized = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    
    return (
        
        <div className='container m-5 p-5 text-center border'>

            <h1>{t('unauthorized.title')}</h1>
            <p>{t('unauthorized.message')}</p>

            <button className='btn btn-primary px-4' onClick={() => navigate(-1)}>{t('button.back')}</button>
            
        </div>
    )
}

export default Unauthorized
