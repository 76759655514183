
const StringUtil = () => {

    /**
     * Lookup for a word within a text and return true if found
     */
    const containsText = (text, word) => {

        // If missing arguments
        if (!text || !word) return false;

        // Remove diacritic letters
        const normalizedText = String(text).trim().normalize('NFD').replace(/[\u0300-\u036f]/gu, '').toLowerCase();
        const normalizedWord = String(word).trim().normalize('NFD').replace(/[\u0300-\u036f]/gu, '').toLowerCase();

        // Check if word is present
        return normalizedText.indexOf(normalizedWord) > -1;
    }

    /**
     * Return true if the text passed is null or empty
     */
    const isEmpty = (text) => {
        return text == null || text.trim().length === 0;
    }

    const toFirstLetters = (text) => {

        let result = '';
        
        for(const part of text.split(' ')) {
            result += part.length > 0 ? part[0].toUpperCase() : '';
        }

        return result.length > 0 ? result : 'n/a';
    }

    return {
        containsText,
        toFirstLetters,
        isEmpty
    }
}

export default StringUtil();