
import React from 'react';
import { useTreeContext } from './TreeContext.js';
import DraggableRow from './DraggableRow.js';

import chevron_img from '../../assets/images/chevron.svg'; 
import new_img from '../../assets/images/plus.svg'; 
import rename_img from '../../assets/images/pen.svg'; 
import props_img from '../../assets/images/props.svg'; 
import delete_img from '../../assets/images/trash.svg'; 
import save_img from '../../assets/images/check.svg'; 
import cancel_img from '../../assets/images/cancel.svg'; 

const TableRows = ({ node, data, level, tbody, onToggle, onNew, onEdit: onRename, onSave, onDelete, onDrop }) => {

    const treeContext = useTreeContext();

    const TRtable  = tbody && node.id ? tbody.props.children(node) : <tr><td>{node.label}</td></tr>;
    const TDlist   = React.Children.toArray(TRtable.props.children);
    const TDfirst  = TDlist.shift();
    const TDothers = TDlist.length > 0 ? TDlist : [ <td></td> ];

    const isRoot      = node.id === data.id;
    const isEditMode  = treeContext.editable && treeContext.editMode;
    const isSelected  = treeContext.selectedId === node.id;
    const isLeaf      = !node.children || node.children.length === 0;
    const indentWidth = level * 20 + (isLeaf ? 10 : 0);

    const TRclassname = 'align-middle' + (TRtable.props.className ? ' ' + TRtable.props.className : '');

    const handleSelected = (e) => {

        if (treeContext.editable && !isEditMode) {
            let newSelectedId = treeContext.selectedId === node.id ? null : node.id;
            treeContext.setSelectedId(newSelectedId);
        }    
    }    

    const handleToggle = (e) => {
        e.stopPropagation();
        onToggle && onToggle(node.id);
    }    

    const handleCancel = (e) => {
        treeContext.setSelectedId();
        treeContext.setEditMode(false);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') onSave(e);
        if (e.key === 'Escape') handleCancel(e);
    }

    return (
        <>
            <DraggableRow {...TRtable.props} className={TRclassname} node={node} data={data} onDrop={onDrop}>

                <td {...TDfirst.props}>

                    {/* fix flex bug in td (align problem) */}
                    <div className='first-column' onClick={handleSelected}>
                       
                        {/* indentation */}
                        <span className='node-indent' style={{ paddingLeft: indentWidth }} />

                        {/* arrow icon */}
                        <span className='node-arrow' onClick={handleToggle}>
                            {!isLeaf && <img className={(node.state?.opened ? 'node-open' : 'node-close')} src={chevron_img} alt='chevron' />}
                        </span>

                        {/* show the node text, if not editing  */}
                        {
                            (!isEditMode || (isEditMode && !isSelected)) && 
                            <div className='node-label'>
                                {TDfirst.props.children}
                            </div>
                        }

                        {/* in edit mode, display the edit field */}
                        {
                            isEditMode && isSelected &&
                            <input className='form-control' type='text' value={treeContext.editText} autoComplete='off' onChange={(e) => treeContext.setEditText(e.target.value)} onKeyDown={handleKeyDown} />
                        }

                    </div>
                </td>

                {/* toolbars */}
                {
                    isSelected &&
                    <td colSpan={TDothers.length}>
                        {
                            isEditMode 
                                ? (
                                    <div className='btn-group'>
                                        <span className='btn btn-sm btn-light' onClick={onSave}><img src={save_img} alt='save' /></span>
                                        <span className='btn btn-sm btn-light' onClick={handleCancel}><img src={cancel_img} alt='cancel' /></span>
                                    </div>

                                )
                                : (
                                    <div className='btn-group'>
                                        <button className='btn btn-sm btn-light' onClick={onRename}><img src={rename_img} alt='rename' /></button>
                                        <button className='btn btn-sm btn-light' disabled={isRoot} data-bs-toggle='modal' data-bs-target={'#delete-'+treeContext.treeId}><img src={delete_img} alt='delete' /></button>
                                        <button className='btn btn-sm btn-light' onClick={onNew}><img src={new_img} alt='new' /></button>
                                        <button className='btn btn-sm btn-light' disabled={isRoot} data-bs-toggle='modal' data-bs-target={'#edit-'+treeContext.treeId}><img src={props_img} alt='edit' /></button>
                                    </div>
                                )
                        }
                    </td>
                }

                {/* add the other remaining tds from cutom body */}
                { 
                    !isSelected && TDothers.map((item,i) => <td {...item.props} key={i}>{item.props.children}</td>)
                } 

            </DraggableRow>

            {
                // Recursively display each node's children as rows
                node.state?.opened && node.children?.map((child) => (
                    <TableRows key={child.id} node={child} data={data} level={level + 1} tbody={tbody} onToggle={onToggle} onNew={onNew} onEdit={onRename} onSave={onSave} onDelete={onDelete} onDrop={onDrop} />
                ))
            }
        </>
    )
}

export default TableRows;

