
import { useTranslation } from 'react-i18next';
import DateUtil from '../../utils/DateUtil.js';
import cv_img from '../../assets/images/cv.svg';

const MotionView = ({ show, user, onCurriculum, onClose }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const gender = t('common.genders').find(g => g.id === user.gender)?.name;
    const language = t('common.languages').find(l => l.id === user.language)?.name;

    /**
     * Event handlers
     */

    const handleCurriculum = () => {
        onCurriculum && onCurriculum(user);
    }

    const handleClose = () => {
        onClose && onClose();
    }

    return (
        <div className={'modal fade ' + (show ? 'show' : '')} style={{ display: show ? 'block' : 'none' }}>

            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('glossary.profile')}</h2>
                        <button type='button' className='btn-close' onClick={handleClose}></button>
                    </div>

                    <div className='modal-body'>
                        <div className='container'>

                            <div className='row'>
                                <div className='col form-group'>
                                    <label htmlFor='email'>{t('users.email')}</label>
                                    <input id='email' type='text' className='form-control' value={user.email ?? ''} onChange={() => {}}/>
                                </div>
                            </div>

                            <div className='row pt-2'>

                                <div className='col form-group'>
                                    <label htmlFor='firstName'>{t('users.firstname')}</label>
                                    <input id='firstName' type='text' className='form-control' value={user.firstname ?? ''} onChange={() => {}}/>
                                </div>

                                <div className='col form-group'>
                                    <label htmlFor='lastName'>{t('users.lastname')}</label>
                                    <input id='lastName' type='text' className='form-control' value={user.lastname ?? ''} onChange={() => {}}/>
                                </div>

                            </div>

                            <div className='row pt-2'>

                                <div className='col form-group'>
                                    <label htmlFor='phone'>{t('users.phone')}</label>
                                    <input id='phone' type='text' className='form-control' value={user.phone ?? ''} onChange={() => {}}/>
                                </div>

                                <div className='col form-group'>
                                    <label htmlFor='birthdate'>{t('users.birthdate')}</label>
                                    <input id='birthdate' type='text' className='form-control' value={DateUtil.toLocaleString(user.birthdate) ?? ''} onChange={() => {}}/>
                                </div>

                            </div>

                            <div className='row pt-2 pb-3'>

                                <div className='col form-group'>
                                    <label htmlFor='gender'>{t('users.gender')}</label>
                                    <input id='gender' type='text' className='form-control' value={gender ?? ''} onChange={() => {}}/>
                                </div>

                                <div className='col form-group'>
                                    <label htmlFor='language'>{t('users.language')}</label>
                                    <input id='language' type='text' className='form-control' value={language ?? ''} onChange={() => {}}/>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className='modal-footer'>
                        <div className='d-flex justify-content-between w-100'>
                            <button className='btn btn-light' onClick={handleCurriculum}>{t('button.curriculum')} &nbsp; <img src={cv_img} alt='reset' /></button>
                            <button className='btn btn-light' onClick={handleClose}>{t('button.close')}</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default MotionView;
